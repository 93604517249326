import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CorrectiveMaintenanceGatewayDTO } from '../../models/gateway/corrective-maintenance/corrective-maintenance-gateway.model';

@Component({
  selector: 'app-dialog-cancel-corrective-maintenance-gateway',
  templateUrl: './dialog-cancel-corrective-maintenance-gateway.component.html',
  styleUrls: ['./dialog-cancel-corrective-maintenance-gateway.component.scss']
})
export class DialogCancelCorrectiveMaintenanceGatewayComponent implements OnInit {


  form: FormGroup = this.createForm({});
  correctiveMaintenance: CorrectiveMaintenanceGatewayDTO;

  @Output()
  public onSubmit = new EventEmitter<{}>();

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogCancelCorrectiveMaintenanceGatewayComponent>,
    @Inject(MAT_DIALOG_DATA) public allData: any, private translate: TranslateService
  ) {
    this.form = this.createForm(allData);
  }

  ngOnInit(): void {
    try {
    } catch (error) {}
  }

  createForm(data) {
    if (JSON.stringify(data) === '{}') {
      return this.fb.group({
        comment: [{disabled: false, value: null}, [Validators.maxLength(5000)]],
      });
    } else {
      return this.fb.group({
        comment: [{disabled: false, value: data.comment}, [Validators.maxLength(5000)]],
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    let data = {
      id: this.allData.id,
      body: this.form.value,
    }
    this.onSubmit.emit(data);
  }
}
