import { Component, Inject } from '@angular/core';
import { DeviceViewDTO } from '../../models/device/device-view.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { UiService } from '../../services/ui.service';
import { UtilService } from '../../services/util.service';
import { LoggerService } from '../../services/logger.service';
import { NotifierService } from 'angular-notifier';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-dialog-view-device-by-section',
  templateUrl: './dialog-view-device-by-section.component.html',
  styleUrls: ['./dialog-view-device-by-section.component.scss']
})
export class DialogViewDeviceBySectionComponent {

  public isLoading = false;

  devices: DeviceViewDTO[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogViewDeviceBySectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private authService: AuthService,
    private uiService: UiService,
    private utilService: UtilService,
    private logger: LoggerService,
    private notifierService: NotifierService,
    private apiService: ApiService
  ) {
    try {
      this.isLoading = true;
      setTimeout(async () => {
        await this.findAllDeviceBySection(data);
      });
    } catch (error) {
      this.logger.error(error);
      this.utilService.getErrorMessage(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
      this.dialogRef.close();
    } finally {
      this.isLoading = false;
    }
  }

  public async findAllDeviceBySection(id: number) {
    try {
      this.devices = await this.apiService.findAllDeviceBySection(id, this.translate.currentLang);
    } catch (error) {
      this.logger.error(error);
      throw error;
    }
  }
}
