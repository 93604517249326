import { Pipe, PipeTransform } from '@angular/core';
import { DeviceImageDTO } from '../models/device/image/device-image.model';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'deviceImage'
})
export class DeviceImagePipe implements PipeTransform {

  transform(deviceImage: DeviceImageDTO, data?: any): string {
    if (!deviceImage || !deviceImage.name) {
      return 'assets/img/default-avatar.svg';
    }
    return `${environment.apiUrl}/device/${deviceImage.deviceId}/image/${deviceImage.id}`;
  }

}
