
<div mat-dialog-content>
  <div fxLayoutAlign="space-around center" fxLayoutGap="5px" fxLayout="row wrap" class="general">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{
          'NOTIFICATION.MODULE_CLIENT.Title' | translate
        }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="form" autocomplete="off">
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>NOTIFICATION.MODULE_CLIENT.Client</mat-label>
              <mat-select formControlName="client" required>
                <mat-option *ngFor="let client of clients" [value]="client">{{
                  client.name
                  }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label translate>NOTIFICATION.MODULE_CLIENT.Module</mat-label>
              <mat-select formControlName="module" required>
                <mat-option *ngFor="let module of modules" [value]="module">{{
                  'MODULE.' + module.name | translate
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>NOTIFICATION.MODULE_CLIENT.Name</mat-label>
              <input matInput formControlName="name" required />
            </mat-form-field>
            <mat-form-field *ngIf="moduleSS">
              <mat-label translate>NOTIFICATION.MODULE_CLIENT.Type</mat-label>
              <mat-select formControlName="type">
                <mat-option *ngFor="let type of types" [value]="type">{{
                  'NOTIFICATION.MODULE_CLIENT.' + type | translate
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>NOTIFICATION.MODULE_CLIENT.Status</mat-label>
              <mat-select formControlName="status" required>
                <mat-option *ngFor="let status of statuses" [value]="status">{{
                  'NOTIFICATION.MODULE_CLIENT.' + status | translate
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>NOTIFICATION.MODULE_CLIENT.Skeleton</mat-label>
              <mat-select formControlName="notificationSkeleton" required>
                <mat-option *ngFor="let notificationSkeleton of notificationSkeletons" [value]="notificationSkeleton">{{
                  notificationSkeleton.name
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>NOTIFICATION.MODULE_CLIENT.Job</mat-label>
              <mat-select formControlName="job">
                <mat-option [value]="null"></mat-option>
                <mat-option *ngFor="let job of jobs" [value]="job">{{
                  job.name
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>NOTIFICATION.MODULE_CLIENT.Description</mat-label>
              <textarea matInput formControlName="description"></textarea>
              <mat-hint align="end">{{
                form.get("description").value?.length || 0
                }}/500</mat-hint>
            </mat-form-field>
          </div>
        </form>
        <div fxLayoutAlign="center center" fxLayoutGap="5px" fxLayout="row wrap">
          <button mat-raised-button (click)="onNoClick()" color="accent">{{'NOTIFICATION.MODULE_CLIENT.Close' | translate}}</button>
          <button mat-raised-button (click)="onYesClick()" color="primary" [disabled]="form.invalid">{{'NOTIFICATION.MODULE_CLIENT.Edit' | translate}}</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

</div>

