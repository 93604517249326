import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dialog-edit-location',
  templateUrl: './dialog-edit-location.component.html',
  styleUrls: ['./dialog-edit-location.component.scss']
})
export class DialogEditLocationComponent implements OnInit {

  form: FormGroup = this.createForm({});

  statuses: string[] = ['active', 'inactive'];

  @Output()
  public onSubmit = new EventEmitter<{}>();

  constructor(public dialogRef: MatDialogRef<DialogEditLocationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private translate: TranslateService, private fb: FormBuilder) {

    this.form = this.createForm(data.location);
    this.selectedStatus();
  }

  ngOnInit(): void {
  }

  private createForm(data) {
    if (JSON.stringify(data) === '{}') {
      return this.fb.group({
        name: [{ disabled: false, value: '' }, [Validators.required]],
        description: [{ disabled: false, value: '' }, [Validators.maxLength(500)]],
        status: [{ disabled: false, value: '' }, [Validators.required]],
      });
    } else {
      return this.fb.group({
        name: [{ disabled: false, value: data.name }, [Validators.required]],
        description: [{ disabled: false, value: data.description }, [Validators.maxLength(500)]],
        status: [{ disabled: false, value: data.status }, [Validators.required]],
      });
    }
  }

  private selectedStatus() {
    if (this.data.location.status !== null && this.data.location.status !== undefined) {
      const selectedStatus = this.statuses.find(c => c.toUpperCase() === this.data.location.status.toUpperCase());
      this.form.get('status').setValue(selectedStatus);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    let data = {
      ...this.form.value,
    }
    this.onSubmit.emit(data);
  }
}
