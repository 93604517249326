import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { mergeMap, tap } from 'rxjs/operators';
import { AuthService } from '../shared/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,
    private router: Router) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.indexOf('/auth/refresh') > -1 || req.url.indexOf('/auth/logout') > -1 || req.url.indexOf('/auth/login') > -1) {
      return next.handle(req);
    }
    return this.authService.getToken().pipe(
      mergeMap((token) => {
        if (token) {
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
        }
        if (!token) {
          this.authService.loggedInUser = null;
          this.router.navigateByUrl('login');
        }
        return next.handle(req).pipe(
          tap({
            next: (event) => {
              if (event instanceof HttpResponse) {
                return event.body;
              }
            },
          }),
        );
      }),
    );
  }
}
