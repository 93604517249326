import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirm-haccp',
  templateUrl: './dialog-confirm-haccp.component.html',
  styleUrls: ['./dialog-confirm-haccp.component.scss']
})
export class DialogConfirmHaccpComponent {

  form: FormGroup = this.createForm({});

  @Output()
  public onYes = new EventEmitter();

  @Output()
  public onNo = new EventEmitter();

  constructor(
    private dialogRef: MatDialogRef<DialogConfirmHaccpComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { question: string },
    private fb: FormBuilder
  ) {}

  get question() {
    return (this.data && this.data.question) || 'Are you sure?';
  }

  public emitNo() {
    this.onNo.emit();
    this.dialogRef.close();
  }

  public emitYes() {
    this.onYes.emit(this.form.value);
  }

  private createForm(data) {
    if (JSON.stringify(data) === '{}') {
      return this.fb.group({
        comment: [{ disabled: false, value: null }],
      });
    } else {
      return this.fb.group({
        comment: [{ disabled: false, value: null }],
      });
    }
  }
}
