import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './auth/guard/auth.guard';
import { LoginGuard } from './auth/guard/login.guard';
import { LoginPageComponent } from './auth/login-page/login-page.component';
import { ForbiddenPageComponent } from './shared/forbidden-page/forbidden-page.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [LoginGuard],
    data: {
      title: 'Login'
    }
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'client',
    loadChildren: () => import('./client/client.module').then(um => um.ClientModule),
  },
  {
    path: 'device',
    loadChildren: () => import('./device/device.module').then(um => um.DeviceModule),
  },
  {
    path: 'gateway',
    loadChildren: () => import('./gateway/gateway.module').then(um => um.GatewayModule),
  },
  {
    path: 'restriction',
    loadChildren: () => import('./restriction/restriction.module').then(um => um.RestrictionModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'report',
    loadChildren: () => import('./report/report.module').then((m) => m.ReportModule),
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'module',
    loadChildren: () => import('./module/module.module').then((m) => m.ModuleModule),
  },
  {
    path: 'control',
    loadChildren: () => import('./control/control.module').then((m) => m.ControlModule),
  },
  {
    path: 'location',
    loadChildren: () => import('./location/location.module').then((m) => m.LocationModule),
  },
  {
    path: 'device-response-time',
    loadChildren: () => import('./device-response-time/device-response-time.module').then((m) => m.DeviceResponseTimeModule),
  },
  {
    path: 'section',
    loadChildren: () => import('./section/section.module').then((m) => m.SectionModule),
  },
  {
    path: 'notification-skeleton',
    loadChildren: () => import('./notification-skeleton/notification-skeleton.module').then((m) => m.NotificationSkeletonModule),
  },
  {
    path: 'module-client-notification',
    loadChildren: () => import('./module-client-notification/module-client-notification.module').then((m) => m.ModuleClientNotificationModule),
  },
  {
    path: 'job',
    loadChildren: () => import('./job/job.module').then((m) => m.JobModule),
  },
  {
    path: 'device-command-number-period-time',
    loadChildren: () => import('./device-command-number-period-time/device-command-number-period-time.module').then((m) => m.DeviceCommandNumberPeriodTimeModule),
  },
  {
    path: 'info',
    loadChildren: () => import('./info/info.module').then((m) => m.InfoModule),
  },
  /*{
    path: 'map',
    loadChildren: () => import('./map/map.module').then((m) => m.MapModule),
  }, */
  {
    path: 'forbidden',
    component: ForbiddenPageComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Forbidden'
    }
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Not Found',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: !environment.production })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
