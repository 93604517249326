<div mat-dialog-content>
  <div
    fxLayoutAlign="space-around center"
    fxLayoutGap="5px"
    fxLayout="row wrap"
    class="general"
  >
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{ "CLIENT.Title" | translate }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="form" autocomplete="off">
          <div
            fxLayoutAlign="space-around center"
            fxLayoutGap="5px"
            fxLayout="row wrap"
          >
            <mat-form-field>
              <mat-label translate>CLIENT.Id</mat-label>
              <input
                matInput
                type="text"
                formControlName="originalId"
                required
              />
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>CLIENT.Name</mat-label>
              <input matInput formControlName="name" required />
            </mat-form-field>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>CLIENT.Latitude</mat-label>
              <input matInput type="number" formControlName="latitude" />
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>CLIENT.Longitude</mat-label>
              <input matInput type="number" formControlName="longitude" />
            </mat-form-field>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>CLIENT.Status</mat-label>
              <mat-select formControlName="status" required>
                <mat-option *ngFor="let status of statuses" [value]="status">{{
                  "CLIENT." + status | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
        <div
          fxLayoutAlign="center center"
          fxLayoutGap="5px"
          fxLayout="row wrap"
        >
          <button mat-raised-button (click)="onNoClick()" color="accent">
            {{ "CLIENT.Close" | translate }}
          </button>
          <button
            mat-raised-button
            (click)="onYesClick()"
            color="primary"
            [disabled]="form.invalid"
          >
            {{ "CLIENT.Edit" | translate }}
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
