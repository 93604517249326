<div class="wrapper">
  <div fxLayout="row wrap" class="display-div">
    <div class="header cancel">
      <button mat-icon-button (click)="onNoClick()">
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </div>
  <div mat-dialog-content fxLayout="row wrap">
    <img
    [src]="data | gatewayImage"
    default="assets/img/default-avatar.svg"
    class="user-avatar"/>
  </div>
</div>
