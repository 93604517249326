import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ClientDTO } from '../../models/client/client.model';
import { ModuleDTO } from '../../models/module/module.model';
import { NotificationSkeletonDTO } from '../../models/notification-skeleton/notification-skeleton.model';
import { ModuleEnum } from '../../models/user/user.model';
import { AuthService } from '../../services/auth.service';
import { UiService } from '../../services/ui.service';
import { UtilService } from '../../services/util.service';
import { LoggerService } from '../../services/logger.service';
import { NotifierService } from 'angular-notifier';
import { ApiService } from '../../services/api.service';
import { JobDTO } from '../../models/job/job.model';

@Component({
  selector: 'app-dialog-edit-module-client-notification',
  templateUrl: './dialog-edit-module-client-notification.component.html',
  styleUrls: ['./dialog-edit-module-client-notification.component.scss']
})
export class DialogEditModuleClientNotificationComponent {

  form: FormGroup = this.createForm({});

  statuses: string[] = ['active', 'inactive'];
  types: string[] = [];

  clients: ClientDTO[] = [];

  modules: ModuleDTO[] = [];

  notificationSkeletons: NotificationSkeletonDTO[] = [];

  jobs: JobDTO[] = [];

  @Output()
  public onSubmit = new EventEmitter<{}>();

  constructor(public dialogRef: MatDialogRef<DialogEditModuleClientNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private translate: TranslateService, private fb: FormBuilder,
    private authService: AuthService,
    private uiService: UiService,
    private utilService: UtilService,
    private logger: LoggerService,
    private notifierService: NotifierService,
    private apiService: ApiService) {
    this.clients.push(this.data.moduleClient.client);
    this.modules.push(this.data.moduleClient.module);
    this.notificationSkeletons.push(this.data.notificationSkeleton);
    if (this.data.type?.toLowerCase()) {
      this.types.push(this.data.type?.toLowerCase());
    }
    this.form = this.createForm(data);
    this.selectedStatus();
    this.selectedType();
    this.selectedData();
  }

  private async selectedData() {
    try {
      this.uiService.setGlobalLoaderVisible(true);
      this.form.get('client').setValue(this.clients[0]);
      this.form.get('module').setValue(this.modules[0]);
      this.form.get('notificationSkeleton').setValue(this.notificationSkeletons[0]);
      this.jobs = await this.apiService.findAllJob(
        this.form.get('client').value.id,
        this.form.get('module').value.id,
        this.translate.currentLang
      );
      if (this.data.job !== null && this.data.job !== undefined) {
        const selected = this.jobs.find(c => c.id === this.data.job.id);
        this.form.get('job').setValue(selected);
      }
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.uiService.setGlobalLoaderVisible(false);
    }
  }

  private selectedStatus() {
    if (this.data.status !== null && this.data.status !== undefined) {
      const selectedStatus = this.statuses.find(c => c.toUpperCase() === this.data.status.toUpperCase());
      this.form.get('status').setValue(selectedStatus);
    }
  }

  private selectedType() {
    if (this.data.type !== null && this.data.type !== undefined) {
      const selectedType = this.types.find(c => c.toUpperCase() === this.data.type.toUpperCase());
      this.form.get('type').setValue(selectedType);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private createForm(data) {
    return this.fb.group({
      client: [{ disabled: false, value: data.client || null }, [Validators.required]],
      module: [{ disabled: false, value: data.module || null }, [Validators.required]],
      name: [{ disabled: false, value: data.name || null }, [Validators.required]],
      type: [{ disabled: false, value: data.type || null }],
      description: [{ disabled: false, value: data.description || null }, [Validators.maxLength(500)]],
      status: [{ disabled: false, value: data.status || null }, [Validators.required]],
      notificationSkeleton: [{ disabled: false, value: data.notificationSkeleton || null }, [Validators.required]],
      job: [{ disabled: false, value: data.type || null }],
    });
  }

  onYesClick(): void {
    let data = {
      ...this.form.value,
    }
    this.onSubmit.emit(data);
  }

  get moduleSS() {
    if (this.form.get('module') !== null && this.form.get('module') !== undefined && this.form.get('module').value !== null && this.form.get('module').value !== undefined && this.form.get('module').value?.name === ModuleEnum.SS) {
      return true;
    }
    return false;
  }
}
