import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { SidebarComponent } from './sidebar/sidebar.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatTabsModule} from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { TableItemComponent } from './table/table-item/table-item.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ForbiddenPageComponent } from './forbidden-page/forbidden-page.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { DialogConfirmComponent } from './modals/dialog-confirm/dialog-confirm.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import {MatRadioModule} from '@angular/material/radio';
import { HeaderComponent } from './header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SidenavListComponent } from './sidenav-list/sidenav-list.component';
import {MatBadgeModule} from '@angular/material/badge';
import { DialogEditGatewayComponent } from './modals/dialog-edit-gateway/dialog-edit-gateway.component';
import { DialogEditDeviceComponent } from './modals/dialog-edit-device/dialog-edit-device.component';
import { DialogEditRestrictionComponent } from './modals/dialog-edit-restriction/dialog-edit-restriction.component';
import { DialogViewRestrictionComponent } from './modals/dialog-view-restriction/dialog-view-restriction.component';
import { DialogViewRestrictionForDeviceComponent } from './modals/dialog-view-restriction-for-device/dialog-view-restriction-for-device.component';
import {ChartModule} from 'primeng/chart';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DialogEditUserParamForLoginComponent } from './modals/dialog-edit-user-param-for-login/dialog-edit-user-param-for-login.component';
import { DialogSetLocationForUserComponent } from './modals/dialog-set-location-for-user/dialog-set-location-for-user.component';
import { ModuleImagePipe } from './pipes/module-image.pipe';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { DialogEditClientComponent } from './modals/dialog-edit-client/dialog-edit-client.component';
import {MatSliderModule} from '@angular/material/slider';
import { DialogEditLocationComponent } from './modals/dialog-edit-location/dialog-edit-location.component';
import { DialogEditDeviceResponseTimeComponent } from './modals/dialog-edit-device-response-time/dialog-edit-device-response-time.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { DialogSetLocationForManagerComponent } from './modals/dialog-set-location-for-manager/dialog-set-location-for-manager.component';
import { DialogSetModuleForClientComponent } from './modals/dialog-set-module-for-client/dialog-set-module-for-client.component';
import {MatChipsModule} from '@angular/material/chips';
import { NgxMaskModule } from 'ngx-mask';
import { TableItem2Component } from './table2/table-item2/table-item2.component';
import { DialogEditSectionComponent } from './modals/dialog-edit-section/dialog-edit-section.component';
import { DialogEditNotificationSkeletonComponent } from './modals/dialog-edit-notification-skeleton/dialog-edit-notification-skeleton.component';
import { DialogEditModuleClientNotificationComponent } from './modals/dialog-edit-module-client-notification/dialog-edit-module-client-notification.component';
import { DialogEditJobComponent } from './modals/dialog-edit-job/dialog-edit-job.component';
import { DialogEditDeviceCommandNumberPeriodTimeComponent } from './modals/dialog-edit-device-command-number-period-time/dialog-edit-device-command-number-period-time.component';
import { DialogConfirmHaccpComponent } from './modals/dialog-confirm-haccp/dialog-confirm-haccp.component';
import { DeviceImagePipe } from './pipes/device-image.pipe';
import { GatewayImagePipe } from './pipes/gateway-image.pipe';
import { DialogDeviceImageComponent } from './modals/dialog-device-image/dialog-device-image.component';
import { DialogGatewayImageComponent } from './modals/dialog-gateway-image/dialog-gateway-image.component';
import { DialogViewDeviceBySectionComponent } from './modals/dialog-view-device-by-section/dialog-view-device-by-section.component';
import { DialogCloseCorrectiveMaintenanceDeviceComponent } from './modals/dialog-close-corrective-maintenance-device/dialog-close-corrective-maintenance-device.component';
import { DialogCancelCorrectiveMaintenanceDeviceComponent } from './modals/dialog-cancel-corrective-maintenance-device/dialog-cancel-corrective-maintenance-device.component';
import { DialogCloseCorrectiveMaintenanceGatewayComponent } from './modals/dialog-close-corrective-maintenance-gateway/dialog-close-corrective-maintenance-gateway.component';
import { DialogCancelCorrectiveMaintenanceGatewayComponent } from './modals/dialog-cancel-corrective-maintenance-gateway/dialog-cancel-corrective-maintenance-gateway.component';
import { DialogRestrictionExceptionComponent } from './modals/dialog-restriction-exception/dialog-restriction-exception.component';
import { ExceptionColumnComponent } from './table/table-item/exception-column/exception-column.component';
/*import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);*/

@NgModule({
  declarations: [SidebarComponent, PageNotFoundComponent, TableItemComponent, ForbiddenPageComponent, DialogConfirmComponent, HeaderComponent, SidenavListComponent, DialogEditGatewayComponent, DialogEditDeviceComponent, DialogEditRestrictionComponent, DialogViewRestrictionComponent, DialogViewRestrictionForDeviceComponent, DialogEditUserParamForLoginComponent, DialogSetLocationForUserComponent, ModuleImagePipe, DialogEditClientComponent, DialogEditLocationComponent, DialogEditDeviceResponseTimeComponent, DialogSetLocationForManagerComponent, DialogSetModuleForClientComponent, TableItem2Component, DialogEditSectionComponent, DialogEditNotificationSkeletonComponent, DialogEditModuleClientNotificationComponent, DialogEditJobComponent, DialogEditDeviceCommandNumberPeriodTimeComponent, DialogConfirmHaccpComponent, DeviceImagePipe, GatewayImagePipe, DialogDeviceImageComponent, DialogGatewayImageComponent, DialogViewDeviceBySectionComponent, DialogCloseCorrectiveMaintenanceDeviceComponent, DialogCancelCorrectiveMaintenanceDeviceComponent, DialogCloseCorrectiveMaintenanceGatewayComponent, DialogCancelCorrectiveMaintenanceGatewayComponent, DialogRestrictionExceptionComponent, ExceptionColumnComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatListModule,
    MatGridListModule,
    MatDividerModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSlideToggleModule,
    TranslateModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatRadioModule,
    MatToolbarModule,
    FlexLayoutModule,
    MatBadgeModule,
    ChartModule,
    NgxMatSelectSearchModule,
    LeafletModule,
    MatSliderModule,
    MatProgressBarModule,
    MatChipsModule,
    NgxMaskModule.forRoot(),
  ],
  exports:[
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    MatDialogModule,
    MatInputModule,
    SidebarComponent,
    PageNotFoundComponent,
    SidenavListComponent,
    MatSelectModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TableItemComponent,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MatListModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatExpansionModule,
    MatSidenavModule,
    ForbiddenPageComponent,
    MatSlideToggleModule,
    TranslateModule,
    MatTooltipModule,
    DialogConfirmComponent,
    HttpClientModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatRadioModule,
    HeaderComponent,
    MatToolbarModule,
    FlexLayoutModule,
    MatBadgeModule,
    ChartModule,
    NgxMatSelectSearchModule,
    DialogEditUserParamForLoginComponent,
    DialogSetLocationForUserComponent,
    ModuleImagePipe,
    LeafletModule,
    MatSliderModule,
    MatProgressBarModule,
    DialogSetLocationForManagerComponent,
    MatChipsModule,
    NgxMaskModule,
    TableItem2Component,
    DialogEditSectionComponent,
    DialogConfirmHaccpComponent,
    DeviceImagePipe,
    GatewayImagePipe,
    DialogDeviceImageComponent,
    DialogGatewayImageComponent,
    DialogViewDeviceBySectionComponent,
    DialogRestrictionExceptionComponent,
    ExceptionColumnComponent
  ],
  providers:[
   // { provide: MatPaginatorIntl, useValue: SerbianPaginatorIntl() }
  ]
})
export class SharedModule { }
