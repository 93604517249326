<div mat-dialog-content>
  <h1>{{userData.firstName}} {{userData.lastName}}</h1>
  <div fxLayoutAlign="space-around center" fxLayout="row wrap">
    <form [formGroup]="form" autocomplete="off">
      <mat-form-field>
        <mat-label translate>USER.DIALOG.Username</mat-label>
        <input matInput type="text" formControlName="username"/>
      </mat-form-field>

      <mat-divider [vertical]="true"></mat-divider>

      <mat-form-field>
        <mat-label translate>USER.DIALOG.Password</mat-label>
        <input cdkFocusInitial matInput type="text" formControlName="password" [type]="hide ? 'password' : 'text'"/>
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="form.get('password').hasError('minlength')" translate>
          USER.DIALOG.Password minlength
        </mat-error>
        <mat-error *ngIf="form.get('password').hasError('maxlength')" translate>
          USER.DIALOG.Password maxlength
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <div fxLayoutAlign="center center" fxLayoutGap="5px" fxLayout="row wrap">
    <button mat-raised-button (click)="onNoClick()" color="accent">{{ "USER.DIALOG.Close" | translate }}</button>
    <button mat-raised-button (click)="onYesClick()" color="primary" [disabled]="form.invalid">{{ "USER.DIALOG.Edit" | translate }}</button>
  </div>
</div>

