import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-edit-user-param-for-login',
  templateUrl: './dialog-edit-user-param-for-login.component.html',
  styleUrls: ['./dialog-edit-user-param-for-login.component.scss']
})
export class DialogEditUserParamForLoginComponent{

  public hide = true;

  form: FormGroup = this.createForm({});

  @Output()
  public onSubmit = new EventEmitter<{}>();

  constructor(
    public dialogRef: MatDialogRef<DialogEditUserParamForLoginComponent>,
    @Inject(MAT_DIALOG_DATA) public userData: any
  ) { }

  createForm(data) {
    if (JSON.stringify(data) === '{}') {
      return new FormGroup({
        username: new FormControl({ value: this.userData.username, disabled: false }, [Validators.required]),
        password: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.minLength(6), Validators.maxLength(60)]),
      });
    } else {
      return new FormGroup({
        username: new FormControl({ value: this.userData.username, disabled: false }, [Validators.required]),
        password: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.minLength(6), Validators.maxLength(60)]),
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    this.onSubmit.emit({
      id: this.userData.id,
      value: this.form.value
    });
  }
}
