import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map, take, tap } from 'rxjs/operators';
import { ModuleEnum, UserDTO, UserRole } from '../models/user/user.model';
import { FilterGateway } from '../models/gateway/filter-gateway.model';
import { GatewayDTO } from '../models/gateway/gateway.model';
import { GatewaysDTO } from '../models/gateway/gateways.model';
import { DeviceDTO } from '../models/device/device.model';
import { FilterDevice } from '../models/device/filter-device.model';
import { DevicesDTO } from '../models/device/devices.model';
import { RestrictionDTO } from '../models/restriction/restriction.model';
import { FilterRestriction } from '../models/restriction/filter-restriction.model';
import { RestrictionProfilesDTO } from '../models/restriction/restriction-profiles.model';
import { RestrictionProfileDTO } from '../models/restriction/restriction-profile.model';
import { RestrictionProfileDeviceEditDTO } from '../models/restriction/restriction-profile-device-edit.model';
import { LocationViewDTO } from '../models/location/location.model';
import { FilterDataViewAlert } from '../models/data/filter-data-view-alert.model';
import { DataAlertViewsDTO } from '../models/data/data-alert-views.model';
import { FilterDeviceDetailData } from '../models/device/filter-device-detail-data.model';
import { DeviceDashboardDTO } from '../models/device/device-dashboard.model';
import { FilterUser } from '../models/user/view/user-view-filter-data.model';
import { UserViewsDTO } from '../models/user/view/user-views.model';
import { FilterManager } from '../models/user/view/manager-view-filter-data.model';
import { RoleDTO } from '../models/role/role.model';
import { UserDetailDTO } from '../models/user/user-detail.model';
import { ModuleDTO } from '../models/module/module.model';
import { ClientDTO } from '../models/client/client.model';
import { FilterClient } from '../models/client/filter-client.model';
import { ClientsDTO } from '../models/client/clients.model';
import { SmartSensoricsDetailsDTO } from '../models/smart-sensorics/smart-sensorics-details.model';
import { FilterSmartSensoricsDetail } from '../models/smart-sensorics/filter-smart-sensorics-detail.model';
import { FilterSmartSensorics } from '../models/smart-sensorics/filter-smart-sensorics.model';
import { SmartSensoricssDTO } from '../models/smart-sensorics/smart-sensoricss.model';
import { FilterLocation } from '../models/location/filter-location.model';
import { LocationViewsDTO } from '../models/location/locations.model';
import { DeviceMapDTO } from '../models/device/device-map.model';
import { UserModuleClientLocationsDTO } from '../models/user-module-client-location/user-module-client-locations.model';
import { FilterUserModuleClientLocation } from '../models/user-module-client-location/filter-user-module-client-location.model';
import { ProducerDTO } from '../models/producer/producer.model';
import { DeviceReadTypeDTO } from '../models/device/read-type/device-read-type.model';
import { FilterSmartLighting } from '../models/smart-lighting/filter-smart-lighting.model';
import { SmartLightingsDTO } from '../models/smart-lighting/smart-lightings.model';
import { FilterSmartLightingDetail } from '../models/smart-lighting/filter-smart-lighting-detail.model';
import { SmartLightingDetailsDTO } from '../models/smart-lighting/smart-lighting-details.model';
import { DeviceResponseTimeDTO } from '../models/device/response-time/device-response-time.model';
import { FilterDeviceResponseTime } from '../models/device/response-time/filter-device-response-time.model';
import { DeviceResponseTimesDTO } from '../models/device/response-time/device-response-times.model';
import { DeviceAvailabilityDTO } from '../models/device/device-availability.model';
import { DataAlertSumDTO } from '../models/data/data-alert-sum.model';
import { SmartLightingSumEnergyCO2DTO } from '../models/smart-lighting/smart-lighting-sum-energy-co2.model';
import { FilterModuleClient } from '../models/module-client/filter-module-client.model';
import { ModuleClientsDTO } from '../models/module-client/module-clients.model';
import { FilterSectionByLocation } from '../models/section/filter-section-by-location.model';
import { SectionsDTO } from '../models/section/sections.model';
import { FilterSectionByDevice } from '../models/section/filter-section-by-device.model';
import { FilterDataViewDetailRssiSnr } from '../models/data/detail/filter-data-view-detail-rssi-snr.model';
import { DataViewDetailRssiSnrsDTO } from '../models/data/detail/data-view-detail-rssi-snrs.model';
import { FilterDeviceData } from '../models/device/data/filter-device-data.model';
import { DeviceDatasDTO } from '../models/device/data/device-datas.model';
import { GatewayMapDTO } from '../models/gateway/gateway-map.model';
import { FilterDeviceUnfunctional } from '../models/device/filter-device-unfunctional.model';
import { DeviceUnfunctionalsDTO } from '../models/device/device-unfunctionals.model';
import { SectionDTO } from '../models/section/section.model';
import { DeviceForCommandDTO } from '../models/device/device-for-command.model';
import { SectionForCommandDTO } from '../models/section/section-for-command.model';
import { LocationViewForCommandDTO } from '../models/location/location-for-command.model';
import { NotificationSkeletonDTO } from '../models/notification-skeleton/notification-skeleton.model';
import { FilterNotificationSkeleton } from '../models/notification-skeleton/filter-notification-skeleton.model';
import { NotificationSkeletonsDTO } from '../models/notification-skeleton/notification-skeletons.model';
import { FilterModuleClientNotificationSkeleton } from '../models/module-client-notification/filter-module-client-notification.model';
import { ModuleClientNotificationSkeletonsDTO } from '../models/module-client-notification/module-client-notifications.model';
import { ModuleClientNotificationSkeletonDTO } from '../models/module-client-notification/module-client-notification.model';
import { FilterJob } from '../models/job/filter-job.model';
import { JobsDTO } from '../models/job/jobs.model';
import { JobDTO } from '../models/job/job.model';
import { FilterAdmin } from '../models/user/view/admin-view-filter-data.model';
import { DeviceCommandNumberPeriodTimeDTO } from '../models/device/command-number-period-time/device-command-number-period-time.model';
import { FilterDeviceCommandNumberPeriodTime } from '../models/device/command-number-period-time/filter-device-command-number-period-time.model';
import { DeviceCommandNumberPeriodTimesDTO } from '../models/device/command-number-period-time/device-command-number-period-times.model';
import { SmartLightingEnergyConsumptionDTO } from '../models/energy-consumption/energy-consumption.model';
import { FilterDataAlertCount } from '../models/alert/filter-data-alert-count-view.model';
import { DataAlertCountsDTO } from '../models/alert/data.alert.counts.model';
import { FilterSmartSensoricsAverage } from '../models/smart-sensorics/filter-smart-sensorics-average.model';
import { SmartSensoricsAveragesDTO } from '../models/smart-sensorics/smart-sensorics-averages.model';
import { DataGatewaysDTO } from '../models/data/data-gateways.model';
import { FilterDataGateway } from '../models/data/filter-data-gateway.model';
import { FilterHaccpIndividual } from '../models/haccp/individual/filter-haccp-individual.model';
import { HaccpsIndividualDTO } from '../models/haccp/individual/haccps-individual.model';
import { FilterHaccpDaily } from '../models/haccp/daily/filter-haccp-daily.model';
import { HaccpsDailyDTO } from '../models/haccp/daily/haccps-daily.model';
import { FilterHaccpMonthly } from '../models/haccp/monthly/filter-haccp-monthly.model';
import { HaccpsMonthlyDTO } from '../models/haccp/monthly/haccps-monthly.model';
import { DeviceViewWithDataDTO } from '../models/device/device-view-with-data.model';
import { GatewayViewDTO } from '../models/gateway/gateway-view.model';
import { SectionViewDTO } from '../models/section/section-view.model';
import { FilterSectionAverageSS } from '../models/section/filter-section-average-ss.model';
import { DeviceViewDTO } from '../models/device/device-view.model';
import { ProblemTypeDeviceDTO } from '../models/device/problem-type/problem-type-device.model';
import { CorrectiveMaintenanceDeviceDTO } from '../models/device/corrective-maintenance/corrective-maintenance-device.model';
import { FilterCorrectiveMaintenanceDevice } from '../models/device/corrective-maintenance/filter-corrective-maintenance.model';
import { CorrectiveMaintenanceDevicesDTO } from '../models/device/corrective-maintenance/corrective-maintenance-devices.model';
import { DeviceCorrectiveMaintenanceDTO } from '../models/device/device-corrective-maintenance.model';
import { SectionAverageSSDTO } from '../models/section/section-average-ss.model';
import { CorrectiveMaintenanceDeviceLocationDTO } from '../models/device/corrective-maintenance/corrective-maintenance-device-location.model';
import { FilterCorrectiveMaintenanceGateway } from '../models/gateway/corrective-maintenance/filter-corrective-maintenance.model';
import { GatewayCorrectiveMaintenanceDTO } from '../models/gateway/gateway-corrective-maintenance.model';
import { CorrectiveMaintenanceGatewayLocationDTO } from '../models/gateway/corrective-maintenance/corrective-maintenance-gateway-location.model';
import { ProblemTypeGatewayDTO } from '../models/gateway/problem-type/problem-type-gateway.model';
import { CorrectiveMaintenanceGatewayDTO } from '../models/gateway/corrective-maintenance/corrective-maintenance-gateway.model';
import { CorrectiveMaintenanceGatewaysDTO } from '../models/gateway/corrective-maintenance/corrective-maintenance-gateways.model';
import { TypePowerSupplyDTO } from '../models/type-power-supply/type-power-supply.model';
import { ModelBatteryDTO } from '../models/battery/model-battery/model-battery.model';
import { TypeBatteryDTO } from '../models/battery/type-battery/type-battery.model';
import { BatteryDTO } from '../models/battery/battery.model';
import { DeviceTypePowerSupplyDTO } from '../models/device-type-power-supply/device-type-power-supply.model';
import { ProducerBatteryDTO } from '../models/battery/producer-battery/producer-battery.model';
import { FilterHaccpWeekly } from '../models/haccp/weekly/filter-haccp-weekly.model';
import { HaccpsWeeklyDTO } from '../models/haccp/weekly/haccps-weekly.model';
import { FilterHaccp10Daily } from '../models/haccp/10-daily/filter-haccp-10-daily.model';
import { Haccps10DailyDTO } from '../models/haccp/10-daily/haccps-10-daily.model';

interface HttpResponse<T = any> {
  isError: boolean;
  payload: T;
  status: {
    code: number;
    message: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private httpClient: HttpClient) {}

  // #region start AUTH

  public login(username: string, password: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .post<{ token: string }>(
        `${environment.apiUrl}/auth/login`,
        {
          username,
          password,
        },
        { headers: headers, withCredentials: true }
      )
      .pipe(take(1))
      .toPromise();
  }

  public refresh(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<{ token: string }>(`${environment.apiUrl}/auth/refresh`, {
        headers: headers,
        withCredentials: true,
      })
      .pipe(
        map((httpResponse) => {
          return httpResponse.token;
        }),
        take(1)
      )
      .toPromise();
  }

  public logout(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get(`${environment.apiUrl}/auth/logout`, {
        headers: headers,
        withCredentials: true,
      })
      .pipe(take(1))
      .toPromise();
  }

  public getMe(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<UserDTO>>(`${environment.apiUrl}/user/me`, {
        headers: headers,
      })
      .pipe(
        tap((e) => {
          if (e.isError) {
            throw new Error('Something went wrong');
          }
        }),
        map((httpResponse) => {
          const { payload } = httpResponse;
          const user: UserDTO = {
            id: payload.id,
            firstName: payload.firstName,
            lastName: payload.lastName,
            roles: [],
            rolesObject: payload.rolesObject,
            username: payload.username,
            status: payload.status,
            modules: [],
            email: payload.email,
          };
          user.roles = payload.roles.map(
            (el) => UserRole[el as keyof typeof UserRole]
          );
          if (payload.modules !== undefined && payload.modules !== null) {
            user.modules = payload.modules.map(
              (el) => ModuleEnum[el as keyof typeof ModuleEnum]
            );
          }
          return user;
        }),
        take(1)
      )
      .toPromise();
  }

  // #region end AUTH

  // #region start client

  public createClient(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .post<HttpResponse<number>>(`${environment.apiUrl}/client/create`, data, {
        headers: headers,
      })
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findClientById(id: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<ClientDTO>>(`${environment.apiUrl}/client/${id}`, {
        headers: headers,
      })
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public editClient(id: number, data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/client/${id}/edit`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllClientSearch(
    { originalId, name }: FilterClient = {
      originalId: null,
      name: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (originalId) {
      params = params.append('originalId', originalId);
    }
    if (name) {
      params = params.append('name', name);
    }
    return this.httpClient
      .get<HttpResponse<ClientsDTO>>(
        `${environment.apiUrl}/client/all-search`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllClient(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<ClientDTO[]>>(`${environment.apiUrl}/client/all`, {
        headers: headers,
      })
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public deleteClient(id: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .delete<HttpResponse<string>>(
        `${environment.apiUrl}/client/${id}/delete`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  // #region end client

  // #region start gateway

  public createGateway(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .post<HttpResponse<number>>(
        `${environment.apiUrl}/gateway/create`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findGatewayById(id: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<GatewayDTO>>(`${environment.apiUrl}/gateway/${id}`, {
        headers: headers,
      })
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public editGateway(id: number, data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/gateway/${id}/edit`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllGatewaySearch(
    { originalId, name, locationId }: FilterGateway = {
      originalId: null,
      name: null,
      locationId: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (originalId) {
      params = params.append('originalId', originalId);
    }
    if (name) {
      params = params.append('name', name);
    }
    if (locationId) {
      params = params.append('locationId', locationId);
    }
    return this.httpClient
      .get<HttpResponse<GatewaysDTO>>(
        `${environment.apiUrl}/gateway/all-search`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllGateway(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<GatewayDTO[]>>(`${environment.apiUrl}/gateway/all`, {
        headers: headers,
      })
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public deleteGateway(id: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .delete<HttpResponse<string>>(
        `${environment.apiUrl}/gateway/${id}/delete`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  // #region end gateway

  // #region start device

  public createDevice(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .post<HttpResponse<number>>(`${environment.apiUrl}/device/create`, data, {
        headers: headers,
      })
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findDeviceById(id: number, moduleName: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<DeviceDTO>>(`${environment.apiUrl}/device/${id}`, {
        params,
        headers: headers,
      })
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findDeviceForViewOnMapById(
    id: number,
    moduleName: string,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<DeviceDTO>>(
        `${environment.apiUrl}/device/${id}/view-on-map`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public editDevice(id: number, data: any, moduleName: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/device/${id}/edit`,
        data,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllDeviceSearch(
    {
      eui,
      name,
      originalName,
      locationId,
      clientId,
      moduleName,
    }: FilterDevice = {
      eui: null,
      name: null,
      originalName: null,
      locationId: null,
      clientId: null,
      moduleName: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (eui) {
      params = params.append('eui', eui);
    }
    if (name) {
      params = params.append('name', name);
    }
    if (originalName) {
      params = params.append('originalName', originalName);
    }
    if (locationId) {
      params = params.append('locationId', locationId);
    }
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<DevicesDTO>>(
        `${environment.apiUrl}/device/all-search`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllDevice(moduleName: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<DeviceDTO[]>>(`${environment.apiUrl}/device/all`, {
        params,
        headers: headers,
      })
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public deleteDevice(id: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .delete<HttpResponse<string>>(
        `${environment.apiUrl}/device/${id}/delete`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  // #region end device

  // #region start restriction

  public findAllRestriction(moduleName: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<RestrictionDTO[]>>(
        `${environment.apiUrl}/restriction/all`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllActiveRestriction(moduleName: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<RestrictionDTO[]>>(
        `${environment.apiUrl}/restriction/all-active`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public createRestrictionProfile(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/restriction/create-profile`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllSearchRestrictionProfile(
    { restrictionId, name, clientId }: FilterRestriction = {
      restrictionId: null,
      name: null,
      clientId: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (restrictionId) {
      params = params.append('restrictionId', restrictionId);
    }
    if (name) {
      params = params.append('name', name);
    }
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    return this.httpClient
      .get<HttpResponse<RestrictionProfilesDTO>>(
        `${environment.apiUrl}/restriction/all-search-profile`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public deleteRestrictionProfile(
    id: number,
    restrictionId: number,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .delete<HttpResponse<string>>(
        `${environment.apiUrl}/restriction/${restrictionId}/profile/${id}/delete`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public editRestrictionProfile(
    restrictionId: number,
    id: number,
    data: any,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/restriction/${restrictionId}/profile/${id}/edit`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllProfileForRestriction(
    id: number,
    clientId: number,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    return this.httpClient
      .get<HttpResponse<RestrictionProfileDTO[]>>(
        `${environment.apiUrl}/restriction/${id}/all-profile`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllActiveProfileForRestriction(
    id: number,
    clientId: number,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    return this.httpClient
      .get<HttpResponse<RestrictionProfileDTO[]>>(
        `${environment.apiUrl}/restriction/${id}/all-profile-active`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllProfileRestrictionForDevice(id: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<RestrictionProfileDTO[]>>(
        `${environment.apiUrl}/device/${id}/all-profile`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllProfileRestrictionForDeviceDetail(
    id: number,
    moduleName: string,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<RestrictionProfileDeviceEditDTO[]>>(
        `${environment.apiUrl}/device/${id}/all-profile-detail`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public editRestrictionProfileForDevice(id: number, data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .post<HttpResponse<string>>(
        `${environment.apiUrl}/device/${id}/create-and-edit-profile-detail`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllLocation(clientId: number, moduleId: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleId) {
      params = params.append('moduleId', moduleId);
    }
    return this.httpClient
      .get<HttpResponse<LocationViewDTO[]>>(
        `${environment.apiUrl}/location/all`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllLocationDetail(moduleName: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<LocationViewDTO[]>>(
        `${environment.apiUrl}/location/all-detail`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findSmartSensoricsDataDetail(
    {
      clientName,
      deviceName,
      dateFrom,
      dateTo,
      airMin,
      airMax,
      voltageMin,
      voltageMax,
      pressureMin,
      pressureMax,
      humidityMin,
      humidityMax,
      temperatureMin,
      temperatureMax,
      restrictionId,
      minOrMax,
    }: FilterSmartSensoricsDetail = {
      clientName: null,
      deviceName: null,
      dateFrom: null,
      dateTo: null,
      airMin: null,
      airMax: null,
      voltageMin: null,
      voltageMax: null,
      pressureMin: null,
      pressureMax: null,
      humidityMin: null,
      humidityMax: null,
      temperatureMin: null,
      temperatureMax: null,
      restrictionId: null,
      minOrMax: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (clientName) {
      params = params.append('clientName', clientName);
    }
    if (deviceName) {
      params = params.append('deviceName', deviceName);
    }
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom + '');
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo + '');
    }
    if (airMin) {
      params = params.append('airMin', airMin);
    }
    if (airMax) {
      params = params.append('airMax', airMax);
    }
    if (voltageMin) {
      params = params.append('voltageMin', voltageMin);
    }
    if (voltageMax) {
      params = params.append('voltageMax', voltageMax);
    }
    if (pressureMin) {
      params = params.append('pressureMin', pressureMin);
    }
    if (pressureMax) {
      params = params.append('pressureMax', pressureMax);
    }
    if (humidityMin) {
      params = params.append('humidityMin', humidityMin);
    }
    if (humidityMax) {
      params = params.append('humidityMax', humidityMax);
    }
    if (temperatureMin) {
      params = params.append('temperatureMin', temperatureMin);
    }
    if (temperatureMax) {
      params = params.append('temperatureMax', temperatureMax);
    }
    if (restrictionId) {
      params = params.append('restrictionId', restrictionId);
    }
    if (minOrMax) {
      params = params.append('minOrMax', minOrMax);
    }
    return this.httpClient
      .get<HttpResponse<SmartSensoricsDetailsDTO>>(
        `${environment.apiUrl}/smart-sensorics/all-search-detail`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findSmartSensoricsDataAlert(
    {
      clientName,
      deviceName,
      dateFrom,
      dateTo,
      restrictionId,
      minOrMax,
      minValue,
      maxValue,
    }: FilterDataViewAlert = {
      clientName: null,
      deviceName: null,
      dateFrom: null,
      dateTo: null,
      restrictionId: null,
      minOrMax: null,
      minValue: null,
      maxValue: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (clientName) {
      params = params.append('clientName', clientName);
    }
    if (deviceName) {
      params = params.append('deviceName', deviceName);
    }
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom + '');
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo + '');
    }
    if (restrictionId) {
      params = params.append('restrictionId', restrictionId);
    }
    if (minOrMax) {
      params = params.append('minOrMax', minOrMax);
    }
    if (minValue) {
      params = params.append('minValue', minValue);
    }
    if (maxValue) {
      params = params.append('maxValue', maxValue);
    }
    return this.httpClient
      .get<HttpResponse<DataAlertViewsDTO>>(
        `${environment.apiUrl}/smart-sensorics/all-search-alert`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllSmartSensoricsDataSearch(
    {
      clientId,
      deviceId,
      dateFrom,
      dateTo,
      airMin,
      airMax,
      voltageMin,
      voltageMax,
      pressureMin,
      pressureMax,
      humidityMin,
      humidityMax,
      temperatureMin,
      temperatureMax,
      gatewayId,
      locationId,
      deviceOriginalName,
      restrictions,
      sectionDevices,
      sectionLocations,
    }: FilterSmartSensorics = {
      clientId: null,
      deviceId: null,
      dateFrom: null,
      dateTo: null,
      airMin: null,
      airMax: null,
      voltageMin: null,
      voltageMax: null,
      pressureMin: null,
      pressureMax: null,
      humidityMin: null,
      humidityMax: null,
      temperatureMin: null,
      temperatureMax: null,
      gatewayId: null,
      locationId: null,
      deviceOriginalName: null,
      restrictions: null,
      sectionDevices: null,
      sectionLocations: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (deviceId) {
      params = params.append('deviceId', deviceId);
    }
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom + '');
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo + '');
    }
    if (airMin) {
      params = params.append('airMin', airMin);
    }
    if (airMax) {
      params = params.append('airMax', airMax);
    }
    if (voltageMin) {
      params = params.append('voltageMin', voltageMin);
    }
    if (voltageMax) {
      params = params.append('voltageMax', voltageMax);
    }
    if (pressureMin) {
      params = params.append('pressureMin', pressureMin);
    }
    if (pressureMax) {
      params = params.append('pressureMax', pressureMax);
    }
    if (humidityMin) {
      params = params.append('humidityMin', humidityMin);
    }
    if (humidityMax) {
      params = params.append('humidityMax', humidityMax);
    }
    if (temperatureMin) {
      params = params.append('temperatureMin', temperatureMin);
    }
    if (temperatureMax) {
      params = params.append('temperatureMax', temperatureMax);
    }
    if (gatewayId) {
      params = params.append('gatewayId', gatewayId);
    }
    if (locationId) {
      params = params.append('locationId', locationId);
    }
    if (deviceOriginalName) {
      params = params.append('deviceOriginalName', deviceOriginalName);
    }
    if (restrictions) {
      params = params.append('restrictions', restrictions.toString());
    }
    if (sectionDevices) {
      params = params.append('sectionDevices', sectionDevices.toString());
    }
    if (sectionLocations) {
      params = params.append('sectionLocations', sectionLocations.toString());
    }
    return this.httpClient
      .get<HttpResponse<SmartSensoricssDTO>>(
        `${environment.apiUrl}/smart-sensorics/all-search`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllDeviceDetailData(
    { deviceId, dateFrom, dateTo, restrictionId }: FilterDeviceDetailData = {
      deviceId: null,
      dateFrom: null,
      dateTo: null,
      restrictionId: null,
    },
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();

    if (deviceId) {
      params = params.append('deviceId', deviceId);
    }
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom + '');
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo + '');
    }
    if (restrictionId) {
      params = params.append('restrictionId', restrictionId);
    }
    return this.httpClient
      .get<HttpResponse<DeviceDashboardDTO>>(
        `${environment.apiUrl}/smart-sensorics/all-for-device`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllFirstName(value: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (value) {
      params = params.append('value', value);
    }
    return this.httpClient
      .get<HttpResponse<string[]>>(
        `${environment.apiUrl}/user/find-all-first-name`,
        {
          headers: headers,
          params,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllLastName(value: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (value) {
      params = params.append('value', value);
    }
    return this.httpClient
      .get<HttpResponse<string[]>>(
        `${environment.apiUrl}/user/find-all-last-name`,
        {
          headers: headers,
          params,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllUser(
    { id, firstName, lastName, username, status }: FilterUser = {
      id: null,
      firstName: null,
      lastName: null,
      username: null,
      status: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }
    if (id) {
      params = params.append('id', id + '');
    }
    if (firstName) {
      params = params.append('firstName', firstName);
    }
    if (lastName) {
      params = params.append('lastName', lastName.trim());
    }
    if (username) {
      params = params.append('username', username);
    }
    if (status) {
      params = params.append('status', status);
    }
    return this.httpClient
      .get<HttpResponse<UserViewsDTO>>(
        `${environment.apiUrl}/user/all-search`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public activateUser(id: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/user/${id}/activate`,
        null,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public deactivateUser(id: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/user/${id}/deactivate`,
        null,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public checkUserParamForLogin(id: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<string>>(
        `${environment.apiUrl}/user/${id}/check-credential`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public editUserParamForLogin(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/user/${data.id}/edit-credential`,
        data.value,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllLocationActive(
    clientId: number,
    moduleId: number,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleId) {
      params = params.append('moduleId', moduleId);
    }
    return this.httpClient
      .get<HttpResponse<LocationViewDTO[]>>(
        `${environment.apiUrl}/location/all-active`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllDeviceActive(clientId: number, moduleId: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleId) {
      params = params.append('moduleId', moduleId);
    }
    return this.httpClient
      .get<HttpResponse<DeviceDTO[]>>(
        `${environment.apiUrl}/device/all-active`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public setLocationForUser(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .post<HttpResponse<string>>(
        `${environment.apiUrl}/user/${data.id}/set-location`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllFirstNameManager(value: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (value) {
      params = params.append('value', value);
    }
    return this.httpClient
      .get<HttpResponse<string[]>>(
        `${environment.apiUrl}/user/find-all-first-name-manager`,
        {
          headers: headers,
          params,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllLastNameManager(value: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (value) {
      params = params.append('value', value);
    }
    return this.httpClient
      .get<HttpResponse<string[]>>(
        `${environment.apiUrl}/user/find-all-last-name-manager`,
        {
          headers: headers,
          params,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public activateManager(id: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/user/${id}/activate-manager`,
        null,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public deactivateManager(id: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/user/${id}/deactivate-manager`,
        null,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public checkUserParamForLoginManager(id: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<string>>(
        `${environment.apiUrl}/user/${id}/check-credential-manager`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public editUserParamForLoginManager(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/user/${data.id}/edit-credential-manager`,
        data.value,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllManager(
    { id, firstName, lastName, username, status }: FilterManager = {
      id: null,
      firstName: null,
      lastName: null,
      username: null,
      status: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }
    if (id) {
      params = params.append('id', id + '');
    }
    if (firstName) {
      params = params.append('firstName', firstName);
    }
    if (lastName) {
      params = params.append('lastName', lastName.trim());
    }
    if (username) {
      params = params.append('username', username);
    }
    if (status) {
      params = params.append('status', status);
    }
    return this.httpClient
      .get<HttpResponse<UserViewsDTO>>(
        `${environment.apiUrl}/user/all-search-manager`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllRoleForCreateAccount(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<RoleDTO[]>>(
        `${environment.apiUrl}/role/all-for-create-account`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public createAccountUser(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .post<HttpResponse<string>>(
        `${environment.apiUrl}/user/registration-user`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public createAccountManager(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .post<HttpResponse<string>>(
        `${environment.apiUrl}/user/registration-manager`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findUserById(id: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<UserDetailDTO>>(`${environment.apiUrl}/user/${id}`, {
        headers: headers,
      })
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public editUser(id: number, data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/user/${id}/edit-user`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public editManager(id: number, data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/user/${id}/edit-manager`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllModuleForCurrentUser(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<ModuleDTO[]>>(
        `${environment.apiUrl}/module/all-for-current-user`,
        { headers: headers }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public uploadModuleImage(id: number, image: File, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    const formData = new FormData();
    formData.append('image', image);

    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/module/${id}/upload-image`,
        formData,
        { headers: headers }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllModuleSearch(
    { moduleId, clientId }: FilterModuleClient = {
      moduleId: null,
      clientId: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (moduleId) {
      params = params.append('moduleId', moduleId);
    }
    if (clientId) {
      params = params.append('clientId', clientId);
    }

    return this.httpClient
      .get<HttpResponse<ModuleClientsDTO>>(
        `${environment.apiUrl}/module/all-search`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllModule(clientId: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    return this.httpClient
      .get<HttpResponse<ModuleDTO[]>>(`${environment.apiUrl}/module/all`, {
        params,
        headers: headers,
      })
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllActiveModule(clientId: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    return this.httpClient
      .get<HttpResponse<ModuleDTO[]>>(
        `${environment.apiUrl}/module/all-active`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findModuleForCurrentUser(id: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<ModuleDTO>>(`${environment.apiUrl}/module/${id}`, {
        headers: headers,
      })
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllDeviceForSendCommand(
    clientId: any,
    moduleName: string,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<DeviceForCommandDTO[]>>(
        `${environment.apiUrl}/device/all-for-send-command`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllSectionForSendCommand(
    clientId: any,
    moduleName: string,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<SectionForCommandDTO[]>>(
        `${environment.apiUrl}/section/all-for-send-command`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllLocationForSendCommand(
    clientId: any,
    moduleName: string,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<LocationViewForCommandDTO[]>>(
        `${environment.apiUrl}/location/all-for-send-command`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public sendCommand(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .post<HttpResponse<string>>(
        `${environment.apiUrl}/device/send-command`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public sendGeneralCommand(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .post<HttpResponse<string>>(
        `${environment.apiUrl}/device/send-general-command`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public sendIntervalCommand(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .post<HttpResponse<string>>(
        `${environment.apiUrl}/device/send-interval-command`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public createLocation(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .post<HttpResponse<number>>(
        `${environment.apiUrl}/location/create`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findLocationById(id: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<LocationViewDTO>>(
        `${environment.apiUrl}/location/${id}`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public editLocation(id: number, data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/location/${id}/edit`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllLocationSearch(
    { name }: FilterLocation = {
      name: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }
    if (name) {
      params = params.append('name', name);
    }
    return this.httpClient
      .get<HttpResponse<LocationViewsDTO>>(
        `${environment.apiUrl}/location/all-search`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllDeviceForMap(moduleName: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<DeviceMapDTO[]>>(
        `${environment.apiUrl}/device/all-for-map`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllLocationForCreateClient(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<LocationViewDTO[]>>(
        `${environment.apiUrl}/location/all-for-create-client`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllActiveClient(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<ClientDTO[]>>(
        `${environment.apiUrl}/client/all-active`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findClientForCurrentUser(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<ClientDTO>>(
        `${environment.apiUrl}/client/active-for-current-user`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllLocationForUser(
    { userId }: FilterUserModuleClientLocation = {
      userId: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (userId) {
      params = params.append('userId', userId);
    }

    return this.httpClient
      .get<HttpResponse<UserModuleClientLocationsDTO>>(
        `${environment.apiUrl}/user-module-client-location/all-search`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllActiveProducer(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<ProducerDTO[]>>(
        `${environment.apiUrl}/producer/all-active`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllActiveProducerBattery(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<ProducerBatteryDTO[]>>(
        `${environment.apiUrl}/producer/all-active-for-battery`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllProducerBattery(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<ProducerBatteryDTO[]>>(
        `${environment.apiUrl}/producer/all-for-battery`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllActiveBattery(modelId: string, typeId: string, producerId: number, lang: string) {

    let params = new HttpParams();
    if (modelId) {
      params = params.append('modelId', modelId);
    }
    if (typeId) {
      params = params.append('typeId', typeId);
    }
    if (producerId) {
      params = params.append('producerId', producerId);
    }
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<BatteryDTO[]>>(
        `${environment.apiUrl}/battery/all-active`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllBattery(modelId: string, typeId: string, producerId: number, lang: string) {

    let params = new HttpParams();
    if (modelId) {
      params = params.append('modelId', modelId);
    }
    if (typeId) {
      params = params.append('typeId', typeId);
    }
    if (producerId) {
      params = params.append('producerId', producerId);
    }
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<BatteryDTO[]>>(
        `${environment.apiUrl}/battery/all`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllActiveDeviceReadType(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<DeviceReadTypeDTO[]>>(
        `${environment.apiUrl}/device-read-type/all-active`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllActiveTypePowerSupply(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<TypePowerSupplyDTO[]>>(
        `${environment.apiUrl}/type-power-supply/all-active`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllTypePowerSupply(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<TypePowerSupplyDTO[]>>(
        `${environment.apiUrl}/type-power-supply/all`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllActiveModelBattery(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<ModelBatteryDTO[]>>(
        `${environment.apiUrl}/model-battery/all-active`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllModelBattery(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<ModelBatteryDTO[]>>(
        `${environment.apiUrl}/model-battery/all`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllActiveTypeBattery(
    modelId: string,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (modelId) {
      params = params.append('modelId', modelId);
    }
    return this.httpClient
      .get<HttpResponse<TypeBatteryDTO[]>>(
        `${environment.apiUrl}/type-battery/all-active`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllTypeBattery(
    modelId: string,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (modelId) {
      params = params.append('modelId', modelId);
    }
    return this.httpClient
      .get<HttpResponse<TypeBatteryDTO[]>>(
        `${environment.apiUrl}/type-battery/all`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllProducer(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<ProducerDTO[]>>(`${environment.apiUrl}/producer/all`, {
        headers: headers,
      })
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllReadType(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<DeviceReadTypeDTO[]>>(
        `${environment.apiUrl}/device-read-type/all`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllSmartLightingSearch(
    {
      clientId,
      deviceId,
      dateFrom,
      dateTo,
      gatewayId,
      locationId,
      deviceOriginalName,
      dimDevice,
      sectionDevices,
      sectionLocations,
    }: FilterSmartLighting = {
      clientId: null,
      deviceId: null,
      dateFrom: null,
      dateTo: null,
      gatewayId: null,
      locationId: null,
      deviceOriginalName: null,
      dimDevice: null,
      sectionDevices: null,
      sectionLocations: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (deviceId) {
      params = params.append('deviceId', deviceId);
    }
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom + '');
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo + '');
    }
    if (gatewayId) {
      params = params.append('gatewayId', gatewayId);
    }
    if (locationId) {
      params = params.append('locationId', locationId);
    }
    if (deviceOriginalName) {
      params = params.append('deviceOriginalName', deviceOriginalName);
    }
    if (dimDevice) {
      params = params.append('dimDevice', dimDevice);
    }
    if (sectionDevices) {
      params = params.append('sectionDevices', sectionDevices.toString());
    }
    if (sectionLocations) {
      params = params.append('sectionLocations', sectionLocations.toString());
    }
    return this.httpClient
      .get<HttpResponse<SmartLightingsDTO>>(
        `${environment.apiUrl}/smart-lighting/all-search-dali-light-state`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllSmartLightingDetailSearch(
    { clientName, deviceName, dateFrom, dateTo }: FilterSmartLightingDetail = {
      clientName: null,
      deviceName: null,
      dateFrom: null,
      dateTo: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (clientName) {
      params = params.append('clientName', clientName);
    }
    if (deviceName) {
      params = params.append('deviceName', deviceName);
    }
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom + '');
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo + '');
    }
    return this.httpClient
      .get<HttpResponse<SmartLightingDetailsDTO>>(
        `${environment.apiUrl}/smart-lighting/all-search-detail-dali-light-state`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllActiveDeviceResponseTime(clientId: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    return this.httpClient
      .get<HttpResponse<DeviceResponseTimeDTO[]>>(
        `${environment.apiUrl}/device-response-time/all-active`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllDeviceResponseTime(clientId: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    return this.httpClient
      .get<HttpResponse<DeviceResponseTimeDTO[]>>(
        `${environment.apiUrl}/device-response-time/all`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public createDeviceResponseTime(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .post<HttpResponse<string>>(
        `${environment.apiUrl}/device-response-time/create`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findDeviceResponseTimeById(id: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<DeviceResponseTimeDTO>>(
        `${environment.apiUrl}/device-response-time/${id}`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public editDeviceResponseTime(id: string, data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/device-response-time/${id}/edit`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllDeviceResponeTimeSearch(
    { timeInSecond, clientId }: FilterDeviceResponseTime = {
      timeInSecond: null,
      clientId: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }
    if (timeInSecond) {
      params = params.append('timeInSecond', timeInSecond);
    }
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    return this.httpClient
      .get<HttpResponse<DeviceResponseTimesDTO>>(
        `${environment.apiUrl}/device-response-time/all-search`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllDeviceAvailability(moduleName: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<DeviceAvailabilityDTO>>(
        `${environment.apiUrl}/device/all-for-availability`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllDataAlertSum(moduleName: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<DataAlertSumDTO>>(
        `${environment.apiUrl}/data/all-alert-sum`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findSumEnergyAndCO2ForAllDevice(moduleName: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<SmartLightingSumEnergyCO2DTO>>(
        `${environment.apiUrl}/smart-lighting/sum-energy-and-co2-for-all-device`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findModuleByName(name: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<ModuleDTO>>(
        `${environment.apiUrl}/module/${name}/view`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public setLocationForManager(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .post<HttpResponse<string>>(
        `${environment.apiUrl}/user/${data.id}/set-location-manager`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public setModuleForClient(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .post<HttpResponse<string>>(
        `${environment.apiUrl}/client/${data.id}/set-module`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public createSection(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .post<HttpResponse<number>>(
        `${environment.apiUrl}/section/create`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllSectionSearchByLocation(
    { name, clientId, moduleId, moduleName }: FilterSectionByLocation = {
      name: null,
      clientId: null,
      moduleId: null,
      moduleName: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (name) {
      params = params.append('name', name);
    }
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleId) {
      params = params.append('moduleId', moduleId);
    }
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<SectionsDTO>>(
        `${environment.apiUrl}/section/all-search-by-location`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllSectionSearchByDevice(
    { name, clientId, moduleId, moduleName }: FilterSectionByDevice = {
      name: null,
      clientId: null,
      moduleId: null,
      moduleName: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (name) {
      params = params.append('name', name);
    }
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleId) {
      params = params.append('moduleId', moduleId);
    }
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<SectionsDTO>>(
        `${environment.apiUrl}/section/all-search-by-device`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllDataSearchRssSnr(
    {
      clientId,
      deviceId,
      dateFrom,
      dateTo,
      gatewayName,
      moduleName,
      deviceOriginalName,
    }: FilterDataViewDetailRssiSnr = {
      clientId: null,
      deviceId: null,
      dateFrom: null,
      dateTo: null,
      gatewayName: null,
      moduleName: null,
      deviceOriginalName: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (deviceId) {
      params = params.append('deviceId', deviceId);
    }
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom + '');
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo + '');
    }
    if (gatewayName) {
      params = params.append('gatewayName', gatewayName);
    }
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    if (deviceOriginalName) {
      params = params.append('deviceOriginalName', deviceOriginalName);
    }
    return this.httpClient
      .get<HttpResponse<DataViewDetailRssiSnrsDTO>>(
        `${environment.apiUrl}/data/all-search-detail`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllDeviceData(
    {
      clientId,
      deviceId,
      dateFrom,
      dateTo,
      moduleName,
      deviceOriginalName,
    }: FilterDeviceData = {
      clientId: null,
      deviceId: null,
      dateFrom: null,
      dateTo: null,
      moduleName: null,
      deviceOriginalName: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (deviceId) {
      params = params.append('deviceId', deviceId);
    }
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom + '');
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo + '');
    }
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    if (deviceOriginalName) {
      params = params.append('deviceOriginalName', deviceOriginalName);
    }
    return this.httpClient
      .get<HttpResponse<DeviceDatasDTO>>(
        `${environment.apiUrl}/device-data/all-search`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllGatewayForMap(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<GatewayMapDTO[]>>(
        `${environment.apiUrl}/gateway/all-for-map`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllUnfunctionalDeviceSearch(
    {
      eui,
      name,
      originalName,
      locationId,
      clientId,
      moduleName,
    }: FilterDeviceUnfunctional = {
      eui: null,
      name: null,
      originalName: null,
      locationId: null,
      clientId: null,
      moduleName: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (eui) {
      params = params.append('eui', eui);
    }
    if (name) {
      params = params.append('name', name);
    }
    if (originalName) {
      params = params.append('originalName', originalName);
    }
    if (locationId) {
      params = params.append('locationId', locationId);
    }
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<DeviceUnfunctionalsDTO>>(
        `${environment.apiUrl}/device/all-search-unfunctional`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findSectionById(id: number, moduleName: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<SectionDTO>>(`${environment.apiUrl}/section/${id}`, {
        params,
        headers: headers,
      })
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public editSection(id: number, data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/section/${id}/edit`,
        data,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllDeviceForSection(
    clientId: number,
    moduleId: number,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleId) {
      params = params.append('moduleId', moduleId);
    }
    return this.httpClient
      .get<HttpResponse<DeviceDTO[]>>(
        `${environment.apiUrl}/device/all-for-section`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public createNotificationSkeleton(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .post<HttpResponse<string>>(
        `${environment.apiUrl}/notification-skeleton/create`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findNotificationSkeletonById(id: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<NotificationSkeletonDTO>>(
        `${environment.apiUrl}/notification-skeleton/${id}`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public editNotificationSkeleton(id: string, data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/notification-skeleton/${id}/edit`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllNotificationSkeletonSearch(
    { name }: FilterNotificationSkeleton = {
      name: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (name) {
      params = params.append('name', name);
    }
    return this.httpClient
      .get<HttpResponse<NotificationSkeletonsDTO>>(
        `${environment.apiUrl}/notification-skeleton/all-search`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllNotificationSkeleton(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<NotificationSkeletonDTO[]>>(
        `${environment.apiUrl}/notification-skeleton/all`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllNotificationSkeletonActive(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<NotificationSkeletonDTO[]>>(
        `${environment.apiUrl}/notification-skeleton/all-active`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public createModuleClientNotification(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .post<HttpResponse<string>>(
        `${environment.apiUrl}/module-client-notification-skeleton/create`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllModuleClientNotificationSkeletonSearch(
    {
      name,
      clientId,
      moduleId,
      status,
    }: FilterModuleClientNotificationSkeleton = {
      name: null,
      clientId: null,
      moduleId: null,
      status: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (name) {
      params = params.append('name', name);
    }
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleId) {
      params = params.append('moduleId', moduleId);
    }
    if (status) {
      params = params.append('status', status + '');
    }
    return this.httpClient
      .get<HttpResponse<ModuleClientNotificationSkeletonsDTO>>(
        `${environment.apiUrl}/module-client-notification-skeleton/all-search`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findModuleClientNotificationSkeletonById(
    notificationSkeletonId: string,
    clientId: number,
    moduleId: number,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (notificationSkeletonId) {
      params = params.append('notificationSkeletonId', notificationSkeletonId);
    }
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleId) {
      params = params.append('moduleId', moduleId);
    }
    return this.httpClient
      .get<HttpResponse<ModuleClientNotificationSkeletonDTO>>(
        `${environment.apiUrl}/module-client-notification-skeleton/view-one`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public editModuleClientNotificationSkeleton(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/module-client-notification-skeleton/edit`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllModuleClientNotificationSkeleton(
    clientId: number,
    moduleId: number,
    jobId: string,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleId) {
      params = params.append('moduleId', moduleId);
    }
    if (jobId) {
      params = params.append('jobId', jobId);
    }
    return this.httpClient
      .get<HttpResponse<ModuleClientNotificationSkeletonDTO[]>>(
        `${environment.apiUrl}/module-client-notification-skeleton/all`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllModuleClientNotificationSkeletonActive(
    clientId: number,
    moduleId: number,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleId) {
      params = params.append('moduleId', moduleId);
    }
    return this.httpClient
      .get<HttpResponse<ModuleClientNotificationSkeletonDTO[]>>(
        `${environment.apiUrl}/module-client-notification-skeleton/all-active`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public createJob(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .post<HttpResponse<string>>(`${environment.apiUrl}/job/create`, data, {
        headers: headers,
      })
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllUserActive(clientId: number, moduleId: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleId) {
      params = params.append('moduleId', moduleId);
    }
    return this.httpClient
      .get<HttpResponse<UserDetailDTO[]>>(
        `${environment.apiUrl}/user/all-active`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllUserJob(clientId: number, moduleId: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleId) {
      params = params.append('moduleId', moduleId);
    }
    return this.httpClient
      .get<HttpResponse<UserDetailDTO[]>>(`${environment.apiUrl}/user/all`, {
        params,
        headers: headers,
      })
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllJobSearch(
    { name }: FilterJob = {
      name: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (name) {
      params = params.append('name', name);
    }
    return this.httpClient
      .get<HttpResponse<JobsDTO>>(`${environment.apiUrl}/job/all-search`, {
        params,
        headers: headers,
      })
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findJobById(id: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<JobDTO>>(`${environment.apiUrl}/job/${id}`, {
        headers: headers,
      })
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public editJob(id: string, data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(`${environment.apiUrl}/job/${id}/edit`, data, {
        headers: headers,
      })
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllJobActive(clientId: number, moduleId: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleId) {
      params = params.append('moduleId', moduleId);
    }
    return this.httpClient
      .get<HttpResponse<JobDTO[]>>(`${environment.apiUrl}/job/all-active`, {
        params,
        headers: headers,
      })
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllJob(clientId: number, moduleId: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleId) {
      params = params.append('moduleId', moduleId);
    }
    return this.httpClient
      .get<HttpResponse<JobDTO[]>>(`${environment.apiUrl}/job/all`, {
        params,
        headers: headers,
      })
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllFirstNameAdmin(value: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (value) {
      params = params.append('value', value);
    }
    return this.httpClient
      .get<HttpResponse<string[]>>(
        `${environment.apiUrl}/user/find-all-first-name-admin`,
        {
          headers: headers,
          params,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllLastNameAdmin(value: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (value) {
      params = params.append('value', value);
    }
    return this.httpClient
      .get<HttpResponse<string[]>>(
        `${environment.apiUrl}/user/find-all-last-name-admin`,
        {
          headers: headers,
          params,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllAdmin(
    { id, firstName, lastName, username, status }: FilterAdmin = {
      id: null,
      firstName: null,
      lastName: null,
      username: null,
      status: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }
    if (id) {
      params = params.append('id', id + '');
    }
    if (firstName) {
      params = params.append('firstName', firstName);
    }
    if (lastName) {
      params = params.append('lastName', lastName.trim());
    }
    if (username) {
      params = params.append('username', username);
    }
    if (status) {
      params = params.append('status', status);
    }
    return this.httpClient
      .get<HttpResponse<UserViewsDTO>>(
        `${environment.apiUrl}/user/all-search-admin`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public activateAdmin(id: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/user/${id}/activate-admin`,
        null,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public deactivateAdmin(id: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/user/${id}/deactivate-admin`,
        null,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public checkUserParamForLoginAdmin(id: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<string>>(
        `${environment.apiUrl}/user/${id}/check-credential-admin`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public editUserParamForLoginAdmin(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/user/${data.id}/edit-credential-admin`,
        data.value,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public createAccountAdmin(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .post<HttpResponse<string>>(
        `${environment.apiUrl}/user/registration-admin`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public editAdmin(id: number, data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/user/${id}/edit-admin`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllActiveDeviceCommandNumberPeriodTime(
    clientId: number,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    return this.httpClient
      .get<HttpResponse<DeviceCommandNumberPeriodTimeDTO[]>>(
        `${environment.apiUrl}/device-command-number-period-time/all-active`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllDeviceCommandNumberPeriodTime(clientId: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    return this.httpClient
      .get<HttpResponse<DeviceCommandNumberPeriodTimeDTO[]>>(
        `${environment.apiUrl}/device-command-number-period-time/all`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public createDeviceCommandNumberPeriodTime(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .post<HttpResponse<string>>(
        `${environment.apiUrl}/device-command-number-period-time/create`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findDeviceCommandNumberPeriodTimeById(id: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<DeviceCommandNumberPeriodTimeDTO>>(
        `${environment.apiUrl}/device-command-number-period-time/${id}`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public editDeviceCommandNumberPeriodTime(
    id: string,
    data: any,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/device-command-number-period-time/${id}/edit`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllDeviceCommandNumberPeriodTimeSearch(
    {
      timeInSecond,
      clientId,
      numberDetail,
    }: FilterDeviceCommandNumberPeriodTime = {
      timeInSecond: null,
      clientId: null,
      numberDetail: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }
    if (timeInSecond) {
      params = params.append('timeInSecond', timeInSecond);
    }
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (numberDetail) {
      params = params.append('numberDetail', numberDetail);
    }
    return this.httpClient
      .get<HttpResponse<DeviceCommandNumberPeriodTimesDTO>>(
        `${environment.apiUrl}/device-command-number-period-time/all-search`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public energyConsumption(
    clientId: any,
    locationIds: any,
    dateFrom: any,
    dateTo: any,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (locationIds) {
      params = params.append('locationIds', locationIds);
    }
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom);
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo);
    }
    return this.httpClient
      .get<HttpResponse<SmartLightingEnergyConsumptionDTO>>(
        `${environment.apiUrl}/smart-lighting/energy-consumption`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllAlertSearch(
    {
      clientId,
      deviceId,
      dateFrom,
      dateTo,
      locationId,
      deviceOriginalName,
      moduleName,
    }: FilterDataAlertCount = {
      clientId: null,
      deviceId: null,
      dateFrom: null,
      dateTo: null,
      locationId: null,
      deviceOriginalName: null,
      moduleName: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (deviceId) {
      params = params.append('deviceId', deviceId);
    }
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom + '');
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo + '');
    }
    if (locationId) {
      params = params.append('locationId', locationId);
    }
    if (deviceOriginalName) {
      params = params.append('deviceOriginalName', deviceOriginalName);
    }
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<DataAlertCountsDTO>>(
        `${environment.apiUrl}/data/all-search-alert-count`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public downloadReportForDeviceAvailability(moduleName: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get(`${environment.apiUrl}/device/all-for-availability-report`, {
        params,
        responseType: 'arraybuffer',
        headers: headers,
      })
      .pipe(take(1))
      .toPromise();
  }

  public downloadReportForDeviceData(
    {
      clientId,
      deviceId,
      dateFrom,
      dateTo,
      moduleName,
      deviceOriginalName,
    }: FilterDeviceData = {
      clientId: null,
      deviceId: null,
      dateFrom: null,
      dateTo: null,
      moduleName: null,
      deviceOriginalName: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (deviceId) {
      params = params.append('deviceId', deviceId);
    }
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom + '');
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo + '');
    }
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    if (deviceOriginalName) {
      params = params.append('deviceOriginalName', deviceOriginalName);
    }
    return this.httpClient
      .get(`${environment.apiUrl}/device-data/report`, {
        params,
        responseType: 'arraybuffer',
        headers: headers,
      })
      .pipe(take(1))
      .toPromise();
  }

  public downloadReportForRssiSnr(
    {
      clientId,
      deviceId,
      dateFrom,
      dateTo,
      moduleName,
      deviceOriginalName,
    }: FilterDeviceData = {
      clientId: null,
      deviceId: null,
      dateFrom: null,
      dateTo: null,
      moduleName: null,
      deviceOriginalName: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (deviceId) {
      params = params.append('deviceId', deviceId);
    }
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom + '');
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo + '');
    }
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    if (deviceOriginalName) {
      params = params.append('deviceOriginalName', deviceOriginalName);
    }
    return this.httpClient
      .get(`${environment.apiUrl}/data/report-rssi-snr`, {
        params,
        responseType: 'arraybuffer',
        headers: headers,
      })
      .pipe(take(1))
      .toPromise();
  }

  public findAllSmartSensoricsAverageSearch(
    {
      clientId,
      deviceId,
      dateFrom,
      dateTo,
      locationId,
      deviceOriginalName,
    }: FilterSmartSensoricsAverage = {
      clientId: null,
      deviceId: null,
      dateFrom: null,
      dateTo: null,
      locationId: null,
      deviceOriginalName: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (deviceId) {
      params = params.append('deviceId', deviceId);
    }
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom + '');
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo + '');
    }
    if (locationId) {
      params = params.append('locationId', locationId);
    }
    if (deviceOriginalName) {
      params = params.append('deviceOriginalName', deviceOriginalName);
    }
    return this.httpClient
      .get<HttpResponse<SmartSensoricsAveragesDTO>>(
        `${environment.apiUrl}/smart-sensorics/average`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public downloadSmartSensoricsReportForAverage(
    {
      clientId,
      deviceId,
      dateFrom,
      dateTo,
      locationId,
      deviceOriginalName,
    }: FilterSmartSensoricsAverage = {
      clientId: null,
      deviceId: null,
      dateFrom: null,
      dateTo: null,
      locationId: null,
      deviceOriginalName: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (deviceId) {
      params = params.append('deviceId', deviceId);
    }
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom + '');
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo + '');
    }
    if (locationId) {
      params = params.append('locationId', locationId);
    }
    if (deviceOriginalName) {
      params = params.append('deviceOriginalName', deviceOriginalName);
    }
    return this.httpClient
      .get(`${environment.apiUrl}/smart-sensorics/report-average`, {
        params,
        responseType: 'arraybuffer',
        headers: headers,
      })
      .pipe(take(1))
      .toPromise();
  }

  public findAllDataGateway(
    {
      originalId,
      name,
      locationId,
      dateFrom,
      dateTo,
      moduleName,
    }: FilterDataGateway = {
      originalId: null,
      name: null,
      locationId: null,
      dateFrom: null,
      dateTo: null,
      moduleName: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (originalId) {
      params = params.append('originalId', originalId);
    }
    if (name) {
      params = params.append('name', name);
    }
    if (locationId) {
      params = params.append('locationId', locationId);
    }
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom + '');
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo + '');
    }
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<DataGatewaysDTO>>(
        `${environment.apiUrl}/data/all-gateway`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public downloadReportForDataGateway(
    {
      originalId,
      name,
      locationId,
      dateFrom,
      dateTo,
      moduleName,
    }: FilterDataGateway = {
      originalId: null,
      name: null,
      locationId: null,
      dateFrom: null,
      dateTo: null,
      moduleName: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }
    if (originalId) {
      params = params.append('originalId', originalId);
    }
    if (name) {
      params = params.append('name', name);
    }
    if (locationId) {
      params = params.append('locationId', locationId);
    }
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom + '');
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo + '');
    }
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get(`${environment.apiUrl}/data/report-gateway`, {
        params,
        responseType: 'arraybuffer',
        headers: headers,
      })
      .pipe(take(1))
      .toPromise();
  }

  public findAllHaccpIndividual(
    {
      clientId,
      deviceId,
      dateFrom,
      dateTo,
      temperatureMin,
      temperatureMax,
      status,
    }: FilterHaccpIndividual = {
      clientId: null,
      deviceId: null,
      dateFrom: null,
      dateTo: null,
      temperatureMin: null,
      temperatureMax: null,
      status: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (deviceId) {
      params = params.append('deviceId', deviceId);
    }
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom + '');
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo + '');
    }
    if (temperatureMin) {
      params = params.append('temperatureMin', temperatureMin);
    }
    if (temperatureMax) {
      params = params.append('temperatureMax', temperatureMax);
    }
    if (status) {
      params = params.append('status', status);
    }
    return this.httpClient
      .get<HttpResponse<HaccpsIndividualDTO>>(
        `${environment.apiUrl}/haccp/all-search-individual`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllHaccpDaily(
    {
      clientId,
      deviceId,
      date,
      temperatureMin,
      temperatureMax,
      status,
    }: FilterHaccpDaily = {
      clientId: null,
      deviceId: null,
      date: null,
      temperatureMin: null,
      temperatureMax: null,
      status: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (deviceId) {
      params = params.append('deviceId', deviceId);
    }
    if (date) {
      params = params.append('date', date + '');
    }
    if (temperatureMin) {
      params = params.append('temperatureMin', temperatureMin);
    }
    if (temperatureMax) {
      params = params.append('temperatureMax', temperatureMax);
    }
    if (status) {
      params = params.append('status', status);
    }
    return this.httpClient
      .get<HttpResponse<HaccpsDailyDTO>>(
        `${environment.apiUrl}/haccp/all-search-daily`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllHaccpWeekly(
    {
      clientId,
      deviceId,
      date,
      temperatureMin,
      temperatureMax,
      status,
      week,
    }: FilterHaccpWeekly = {
      clientId: null,
      deviceId: null,
      date: null,
      temperatureMin: null,
      temperatureMax: null,
      status: null,
      week: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (deviceId) {
      params = params.append('deviceId', deviceId);
    }
    if (date) {
      params = params.append('date', date + '');
    }
    if (temperatureMin) {
      params = params.append('temperatureMin', temperatureMin);
    }
    if (temperatureMax) {
      params = params.append('temperatureMax', temperatureMax);
    }
    if (status) {
      params = params.append('status', status);
    }
    if (week) {
      params = params.append('week', week);
    }
    return this.httpClient
      .get<HttpResponse<HaccpsWeeklyDTO>>(
        `${environment.apiUrl}/haccp/all-search-weekly`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllHaccp10Daily(
    {
      clientId,
      deviceId,
      date,
      temperatureMin,
      temperatureMax,
      status,
      day,
    }: FilterHaccp10Daily = {
      clientId: null,
      deviceId: null,
      date: null,
      temperatureMin: null,
      temperatureMax: null,
      status: null,
      day: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (deviceId) {
      params = params.append('deviceId', deviceId);
    }
    if (date) {
      params = params.append('date', date + '');
    }
    if (temperatureMin) {
      params = params.append('temperatureMin', temperatureMin);
    }
    if (temperatureMax) {
      params = params.append('temperatureMax', temperatureMax);
    }
    if (status) {
      params = params.append('status', status);
    }
    if (day) {
      params = params.append('day', day);
    }
    return this.httpClient
      .get<HttpResponse<Haccps10DailyDTO>>(
        `${environment.apiUrl}/haccp/all-search-10-daily`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllHaccpMonthly(
    {
      clientId,
      deviceId,
      date,
      temperatureMin,
      temperatureMax,
      status,
      type,
    }: FilterHaccpMonthly = {
      clientId: null,
      deviceId: null,
      date: null,
      temperatureMin: null,
      temperatureMax: null,
      status: null,
      type: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (deviceId) {
      params = params.append('deviceId', deviceId);
    }
    if (date) {
      params = params.append('date', date + '');
    }
    if (temperatureMin) {
      params = params.append('temperatureMin', temperatureMin);
    }
    if (temperatureMax) {
      params = params.append('temperatureMax', temperatureMax);
    }
    if (status) {
      params = params.append('status', status);
    }
    if (type) {
      params = params.append('type', type);
    }
    return this.httpClient
      .get<HttpResponse<HaccpsMonthlyDTO>>(
        `${environment.apiUrl}/haccp/all-search-monthly`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public verificateHaccpIndividual(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/haccp/individual-verification`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public verificateHaccpDaily(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (data.date) {
      params = params.append('date', data.date);
    }
    if (data.deviceId) {
      params = params.append('deviceId', data.deviceId);
    }
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/haccp/daily-verification`,
        data,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public verificateHaccpWeekly(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (data.date) {
      params = params.append('date', data.date);
    }
    if (data.week) {
      params = params.append('week', data.week);
    }
    if (data.deviceId) {
      params = params.append('deviceId', data.deviceId);
    }
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/haccp/weekly-verification`,
        data,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public verificateHaccp10Daily(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (data.date) {
      params = params.append('date', data.date);
    }
    if (data.day) {
      params = params.append('day', data.day);
    }
    if (data.deviceId) {
      params = params.append('deviceId', data.deviceId);
    }
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/haccp/10-daily-verification`,
        data,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public verificateHaccpMonthly(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (data.date) {
      params = params.append('date', data.date);
    }
    if (data.type) {
      params = params.append('type', data.type);
    }
    if (data.deviceId) {
      params = params.append('deviceId', data.deviceId);
    }
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/haccp/monthly-verification`,
        data,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public downloadHaccpReport(
    {
      clientId,
      deviceId,
      date,
      temperatureMin,
      temperatureMax,
      status,
      type
    }: FilterHaccpMonthly = {
      clientId: null,
      deviceId: null,
      date: null,
      temperatureMin: null,
      temperatureMax: null,
      status: null,
      type: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (deviceId) {
      params = params.append('deviceId', deviceId);
    }
    if (date) {
      params = params.append('date', date + '');
    }
    if (temperatureMin) {
      params = params.append('temperatureMin', temperatureMin);
    }
    if (temperatureMax) {
      params = params.append('temperatureMax', temperatureMax);
    }
    if (status) {
      params = params.append('status', status);
    }
    if (type) {
      params = params.append('type', type);
    }
    return this.httpClient
      .get(`${environment.apiUrl}/haccp/report`, {
        params,
        responseType: 'arraybuffer',
        headers: headers,
      })
      .pipe(take(1))
      .toPromise();
  }

  public uploadDeviceImage(id: number, image: File, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    const formData = new FormData();
    formData.append('image', image);

    return this.httpClient
      .post<HttpResponse<string>>(
        `${environment.apiUrl}/device/${id}/upload/image`,
        formData,
        { headers: headers }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public removeDeviceImage(deviceId: number, id: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .delete<HttpResponse<string>>(
        `${environment.apiUrl}/device/${deviceId}/image/${id}/remove`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public uploadGatewayImage(id: number, image: File, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    const formData = new FormData();
    formData.append('image', image);

    return this.httpClient
      .post<HttpResponse<string>>(
        `${environment.apiUrl}/gateway/${id}/upload/image`,
        formData,
        { headers: headers }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public removeGatewayImage(gatewayId: number, id: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .delete<HttpResponse<string>>(
        `${environment.apiUrl}/gateway/${gatewayId}/image/${id}/remove`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findDeviceDetailById(id: number, moduleName: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<DeviceViewWithDataDTO>>(
        `${environment.apiUrl}/device/${id}/detail`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findDeviceTypePowerSupplyByDeviceId(id: number, moduleName: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<DeviceTypePowerSupplyDTO>>(
        `${environment.apiUrl}/device/${id}/detail`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findGatewayDetailById(id: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<GatewayViewDTO>>(
        `${environment.apiUrl}/gateway/${id}/detail`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllSectionDevice(clientId: any, moduleName: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<SectionViewDTO[]>>(
        `${environment.apiUrl}/section/all-for-device`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllSectionLocation(
    clientId: any,
    moduleName: string,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<SectionViewDTO[]>>(
        `${environment.apiUrl}/section/all-for-location`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findSectionAverageSS(
    { sectionId, dateFrom, dateTo }: FilterSectionAverageSS = {
      sectionId: null,
      dateFrom: null,
      dateTo: null,
    },
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (sectionId) {
      params = params.append('sectionId', sectionId);
    }
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom + '');
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo + '');
    }
    return this.httpClient
      .get<HttpResponse<SectionAverageSSDTO>>(
        `${environment.apiUrl}/smart-sensorics/average-by-section`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllDeviceBySection(id: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    return this.httpClient
      .get<HttpResponse<DeviceViewDTO[]>>(
        `${environment.apiUrl}/section/${id}/all-device`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllProblemTypeDevice(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<ProblemTypeDeviceDTO[]>>(
        `${environment.apiUrl}/problem-type-device/all`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllProblemTypeDeviceModuleActive(
    moduleName: string,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<ProblemTypeDeviceDTO[]>>(
        `${environment.apiUrl}/problem-type-device/all-for-module-active`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllProblemTypeDeviceModule(moduleName: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<ProblemTypeDeviceDTO[]>>(
        `${environment.apiUrl}/problem-type-device/all-for-module`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public createCorrectiveMaintenanceDevice(
    data: any,
    moduleName: string,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .post<HttpResponse<number>>(
        `${environment.apiUrl}/corrective-maintenance-device/create`,
        data,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllCorrectiveMaintenanceDevice(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<CorrectiveMaintenanceDeviceDTO[]>>(
        `${environment.apiUrl}/corrective-maintenance-device/all`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllCorrectiveMaintenanceDeviceSearch(
    {
      clientId,
      moduleName,
      locationId,
      deviceId,
      problemTypeId,
      status,
    }: FilterCorrectiveMaintenanceDevice = {
      clientId: null,
      moduleName: null,
      locationId: null,
      deviceId: null,
      problemTypeId: null,
      status: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    if (locationId) {
      params = params.append('locationId', locationId);
    }
    if (deviceId) {
      params = params.append('deviceId', deviceId);
    }
    if (problemTypeId) {
      params = params.append('problemTypeId', problemTypeId);
    }
    if (status) {
      params = params.append('status', status);
    }
    return this.httpClient
      .get<HttpResponse<CorrectiveMaintenanceDevicesDTO>>(
        `${environment.apiUrl}/corrective-maintenance-device/all-search`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllDeviceCorrectiveMaintenance(
    clientId: number,
    moduleId: number,
    locationId: number,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (clientId) {
      params = params.append('clientId', clientId);
    }
    if (moduleId) {
      params = params.append('moduleId', moduleId);
    }
    if (locationId) {
      params = params.append('locationId', locationId);
    }
    return this.httpClient
      .get<HttpResponse<DeviceCorrectiveMaintenanceDTO[]>>(
        `${environment.apiUrl}/device/all-for-corrective-maintenance`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllCreatedOrAcceptedCorrectiveMaintenanceDevice(
    moduleName: string,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<CorrectiveMaintenanceDeviceLocationDTO[]>>(
        `${environment.apiUrl}/corrective-maintenance-device/all-created-or-accepted`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public acceptCorrectiveMaintenanceDevice(
    id: string,
    moduleName: string,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (id) {
      params = params.append('id', id);
    }
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/corrective-maintenance-device/${id}/accept`,
        null,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public closeCorrectiveMaintenanceDevice(id: string, data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/corrective-maintenance-device/${id}/close`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public cancelCorrectiveMaintenanceDevice(
    id: string,
    data: any,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/corrective-maintenance-device/${id}/cancel`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findCorrectiveMaintenanceDeviceById(id: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<CorrectiveMaintenanceDeviceDTO>>(
        `${environment.apiUrl}/corrective-maintenance-device/${id}`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllDeviceForCorrectiveMaintenanceView(
    moduleName: string,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (moduleName) {
      params = params.append('moduleName', moduleName);
    }
    return this.httpClient
      .get<HttpResponse<DeviceCorrectiveMaintenanceDTO[]>>(
        `${environment.apiUrl}/device/all-for-corrective-maintenance-view`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllProblemTypeGateway(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<ProblemTypeGatewayDTO[]>>(
        `${environment.apiUrl}/problem-type-gateway/all`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllProblemTypeGatewayActive(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<ProblemTypeGatewayDTO[]>>(
        `${environment.apiUrl}/problem-type-gateway/all-active`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public createCorrectiveMaintenanceGateway(data: any, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .post<HttpResponse<number>>(
        `${environment.apiUrl}/corrective-maintenance-gateway/create`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllCorrectiveMaintenanceGateway(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<CorrectiveMaintenanceGatewayDTO[]>>(
        `${environment.apiUrl}/corrective-maintenance-gateway/all`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllCorrectiveMaintenanceGatewaySearch(
    {
      locationId,
      gatewayId,
      problemTypeId,
      status,
    }: FilterCorrectiveMaintenanceGateway = {
      locationId: null,
      gatewayId: null,
      problemTypeId: null,
      status: null,
    },
    sort: { column: string; direction: string } = { column: '', direction: '' },
    page = 0,
    size = 50,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    params = params
      .append('page', page.toString())
      .append('size', size.toString());
    if (sort.direction) {
      params = params
        .append('sort', sort.column)
        .append('sortOrder', sort.direction);
    }

    if (locationId) {
      params = params.append('locationId', locationId);
    }
    if (gatewayId) {
      params = params.append('gatewayId', gatewayId);
    }
    if (problemTypeId) {
      params = params.append('problemTypeId', problemTypeId);
    }
    if (status) {
      params = params.append('status', status);
    }
    return this.httpClient
      .get<HttpResponse<CorrectiveMaintenanceGatewaysDTO>>(
        `${environment.apiUrl}/corrective-maintenance-gateway/all-search`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllGatewayCorrectiveMaintenance(locationId: number, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    let params = new HttpParams();
    if (locationId) {
      params = params.append('locationId', locationId);
    }
    return this.httpClient
      .get<HttpResponse<GatewayCorrectiveMaintenanceDTO[]>>(
        `${environment.apiUrl}/gateway/all-for-corrective-maintenance`,
        {
          params,
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllCreatedOrAcceptedCorrectiveMaintenanceGateway(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<CorrectiveMaintenanceGatewayLocationDTO[]>>(
        `${environment.apiUrl}/corrective-maintenance-gateway/all-created-or-accepted`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public acceptCorrectiveMaintenanceGateway(id: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/corrective-maintenance-gateway/${id}/accept`,
        null,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public closeCorrectiveMaintenanceGateway(
    id: string,
    data: any,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/corrective-maintenance-gateway/${id}/close`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public cancelCorrectiveMaintenanceGateway(
    id: string,
    data: any,
    lang: string
  ) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .put<HttpResponse<string>>(
        `${environment.apiUrl}/corrective-maintenance-gateway/${id}/cancel`,
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findCorrectiveMaintenanceGatewayById(id: string, lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<CorrectiveMaintenanceGatewayDTO>>(
        `${environment.apiUrl}/corrective-maintenance-gateway/${id}`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }

  public findAllGatewayForCorrectiveMaintenanceView(lang: string) {
    const headers = new HttpHeaders({ 'Accept-Language': lang });
    return this.httpClient
      .get<HttpResponse<GatewayCorrectiveMaintenanceDTO[]>>(
        `${environment.apiUrl}/gateway/all-for-corrective-maintenance-view`,
        {
          headers: headers,
        }
      )
      .pipe(
        map(({ payload }) => payload),
        take(1)
      )
      .toPromise();
  }
}
