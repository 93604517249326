import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { RestrictionDTO } from '../models/restriction/restriction.model';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { LoggerService } from '../services/logger.service';
import { UiService } from '../services/ui.service';
import { UtilService } from '../services/util.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() public sidenavToggle = new EventEmitter();

  restrictions: RestrictionDTO[];

  constructor(private router: Router,
    private authService: AuthService,
    private utilService: UtilService,
    private logger: LoggerService,
    private apiService: ApiService,
    private translate: TranslateService,
    private uiService: UiService,
    private activeRoute: ActivatedRoute,
    private notifierService: NotifierService,) {
  }

  ngOnInit(): void {

  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  get isAuthenticated() {
    return this.authService.isAuthenticated;
  }

  get adminRole() {
    return this.authService.hasADMRole();
  }

  get userRole() {
    return this.authService.hasUSERRole();
  }

  get managerRole() {
    return this.authService.hasMANRole();
  }

  get user() {
    return this.authService.loggedInUser;
  }

  get ssModule() {
    return this.authService.hasSSModule();
  }

  get ssSelectedModule() {
    return this.authService.hasSSSelectedModule();
  }

  get slModule() {
    return this.authService.hasSLModule();
  }

  get slSelectedModule() {
    return this.authService.hasSLSelectedModule();
  }

  get swmModule() {
    return this.authService.hasSWMModule();
  }

  get swmSelectedModule() {
    return this.authService.hasSWMSelectedModule();
  }

  get semModule() {
    return this.authService.hasSEMModule();
  }

  get semSelectedModule() {
    return this.authService.hasSEMSelectedModule();
  }

  get selectedModule() {
    return this.authService.selectedModule;
  }

  get userFirstnameLastname() {
    return this.authService.loggedInUser?.firstName + ' ' + this.authService.loggedInUser?.lastName;
  }

  public logout() {
    try {
      this.uiService.setGlobalLoaderVisible(true);
      this.authService.logout();
      this.router.navigateByUrl('login');
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.uiService.setGlobalLoaderVisible(false);
    }
  }

  public useLanguage(lang: string) {
    try {
      this.translate.use(lang);
      this.authService.saveLanguageToStorage(lang);
    } catch (error) {
      this.logger.error(error);
    }
  }

  public login() {
    try {
      this.router.navigateByUrl('login');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public createClient() {
    try {
      this.router.navigateByUrl('client/create');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewClient() {
    try {
      this.router.navigateByUrl('client/view');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public createGateway() {
    try {
      this.router.navigateByUrl('gateway/create');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewGateway() {
    try {
      this.router.navigateByUrl('gateway/view');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewGatewayMap() {
    try {
      this.router.navigateByUrl('gateway/view-map');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public createDevice() {
    try {
      this.router.navigateByUrl('device/create');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewDevice() {
    try {
      this.router.navigateByUrl('device/view');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public createRestriction() {
    try {
      this.router.navigateByUrl('restriction/create');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewDashboardSS() {
    try {
      this.router.navigateByUrl('dashboard/view-ss');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewDashboardSL() {
    try {
      this.router.navigateByUrl('dashboard/view-sl');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewData() {
    try {
      this.router.navigateByUrl('report/data');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewDeviceDataDetail() {
    try {
      this.router.navigateByUrl('dashboard/view-detail-device');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewRemoticomZSC160DaliLightState() {
    try {
      this.router.navigateByUrl('report/dali-light-state');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewRssiSnr() {
    try {
      this.router.navigateByUrl('report/data-detail');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewDeviceData() {
    try {
      this.router.navigateByUrl('report/device-data');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewEnergyConsumption() {
    try {
      this.router.navigateByUrl('report/energy-consumption');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewAlertCount() {
    try {
      this.router.navigateByUrl('report/data-alert-page');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewUsers() {
    try {
      this.router.navigateByUrl('user/view-all');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewManagers() {
    try {
      this.router.navigateByUrl('user/view-all-manager');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewAdmins() {
    try {
      this.router.navigateByUrl('user/view-all-admin');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public createAccount() {
    try {
      this.router.navigateByUrl('user/create-account');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewAllModuleForUser() {
    try {
      this.authService.selectedModule = null;
      this.authService.removeModuleFromStorage();
      this.authService.selectedModuleDTO = null;
      this.router.navigateByUrl('module/view-all-for-user');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewControl() {
    try {
      this.router.navigateByUrl('control/command');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewControlGeneral() {
    try {
      this.router.navigateByUrl('control/general-command');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewControlInterval() {
    try {
      this.router.navigateByUrl('control/interval-command');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public createLocation() {
    try {
      this.router.navigateByUrl('location/create');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewLocation() {
    try {
      this.router.navigateByUrl('location/view');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewMap() {
    try {
      this.router.navigateByUrl('module/' + this.authService.selectedModuleDTO?.id + '/view');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public createDeviceResponeTime() {
    try {
      this.router.navigateByUrl('device-response-time/create');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewDeviceResponeTime() {
    try {
      this.router.navigateByUrl('device-response-time/view');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public createDeviceCommandNumberPeriodTime() {
    try {
      this.router.navigateByUrl('device-command-number-period-time/create');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewDeviceCommandNumberPeriodTime() {
    try {
      this.router.navigateByUrl('device-command-number-period-time/view');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public createSection() {
    try {
      this.router.navigateByUrl('section/create');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewSection() {
    try {
      this.router.navigateByUrl('section/view');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public createNotificationSkeleton() {
    try {
      this.router.navigateByUrl('notification-skeleton/create');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewNotificationSkeleton() {
    try {
      this.router.navigateByUrl('notification-skeleton/view');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public createModuleClientNotification() {
    try {
      this.router.navigateByUrl('module-client-notification/create');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewModuleClientNotification() {
    try {
      this.router.navigateByUrl('module-client-notification/view');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public createJob() {
    try {
      this.router.navigateByUrl('job/create');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewJob() {
    try {
      this.router.navigateByUrl('job/view');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewNotificationInfo() {
    try {
      this.router.navigateByUrl('info/view-notification');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewAccountInfo() {
    try {
      this.router.navigateByUrl('info/view-account');
    } catch (error) {
      this.logger.error(error);
    }
  }

  get selectedModuleDTO() {
    return this.authService.selectedModuleDTO;
  }

  get selectedSSModule() {
    if (this.authService.hasSSSelectedModule()) {
      return true;
    }
    return false;
  }

  get selectedSLModule() {
    if (this.authService.hasSLSelectedModule()) {
      return true;
    }
    return false;
  }

  get selectedSWMModule() {
    if (this.authService.hasSWMSelectedModule()) {
      return true;
    }
    return false;
  }

  get selectedSEMModule() {
    if (this.authService.hasSEMSelectedModule()) {
      return true;
    }
    return false;
  }

  public viewGatewayReport() {
    try {
      this.router.navigateByUrl('report/gateway-data');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewHaccp() {
    try {
      this.router.navigateByUrl('report/haccp');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewDataAverageBySectionSS() {
    try {
      this.router.navigateByUrl('report/average-by-section');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewDataAverage() {
    try {
      this.router.navigateByUrl('report/average');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public createCorrectiveMaintenanceDevice() {
    try {
      this.router.navigateByUrl('device/corrective-maintenance/create');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewAllCorrectiveMaintenanceDevice() {
    try {
      this.router.navigateByUrl('device/corrective-maintenance/view');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewForActionCorrectiveMaintenanceDevice() {
    try {
      this.router.navigateByUrl('device/corrective-maintenance/view-for-action');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public createCorrectiveMaintenanceGateway() {
    try {
      this.router.navigateByUrl('gateway/corrective-maintenance/create');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewAllCorrectiveMaintenanceGateway() {
    try {
      this.router.navigateByUrl('gateway/corrective-maintenance/view');
    } catch (error) {
      this.logger.error(error);
    }
  }

  public viewForActionCorrectiveMaintenanceGateway() {
    try {
      this.router.navigateByUrl('gateway/corrective-maintenance/view-for-action');
    } catch (error) {
      this.logger.error(error);
    }
  }
}
