
<div mat-dialog-content>
  <div fxLayoutAlign="space-around center" fxLayoutGap="5px" fxLayout="row wrap" class="general">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{
          'DEVICE_RESPONSE_TIME.Title' | translate
        }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <mat-icon (click)="tooltip.toggle()" #tooltip="matTooltip" matTooltip="{{'DEVICE_RESPONSE_TIME.Info' | translate}}">info</mat-icon>
        </div>
        <form [formGroup]="form" autocomplete="off">
          <div fxLayout="row wrap" fxLayoutAlign="center center" >
            <mat-form-field>
              <mat-label translate>DEVICE_RESPONSE_TIME.Time in seconds</mat-label>
              <input matInput formControlName="timeInSecond" type="number" required/>
            </mat-form-field>
            <!--<mat-icon (click)="tooltip.toggle()" #tooltip="matTooltip" matTooltip="{{'DEVICE_RESPONSE_TIME.Time in seconds info' | translate}}">info</mat-icon>-->
          </div>

          <mat-divider [vertical]="true"></mat-divider>

          <div fxLayout="row wrap" fxLayoutAlign="space-around center" >
            <mat-form-field>
              <mat-label translate>DEVICE_RESPONSE_TIME.Client</mat-label>
              <mat-select formControlName="client" required>
                <mat-option *ngFor="let client of data.clients" [value]="client">{{
                  client.name
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>DEVICE_RESPONSE_TIME.Status</mat-label>
              <mat-select formControlName="status" required>
                <mat-option *ngFor="let status of statuses" [value]="status">{{
                  'DEVICE_RESPONSE_TIME.' + status | translate
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <mat-divider [vertical]="true"></mat-divider>

          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>DEVICE_RESPONSE_TIME.Description</mat-label>
              <textarea matInput formControlName="description"></textarea>
              <mat-hint align="end">{{
                form.get("description").value?.length || 0
                }}/500</mat-hint>
            </mat-form-field>
          </div>
        </form>
        <div fxLayoutAlign="center center" fxLayoutGap="5px" fxLayout="row wrap">
          <button mat-raised-button (click)="onNoClick()" color="accent">{{'DEVICE_RESPONSE_TIME.Close' | translate}}</button>
          <button mat-raised-button (click)="onYesClick()" color="primary" [disabled]="form.invalid">{{'DEVICE_RESPONSE_TIME.Edit' | translate}}</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

</div>

