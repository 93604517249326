
<div mat-dialog-content>
  <div fxLayoutAlign="space-around center" fxLayoutGap="5px" fxLayout="row wrap" class="general">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{
          'NOTIFICATION.SKELETON.Title' | translate
        }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="form" autocomplete="off">
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>NOTIFICATION.SKELETON.Name</mat-label>
              <input matInput type="text" formControlName="name" required />
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>NOTIFICATION.SKELETON.Status</mat-label>
              <mat-select formControlName="status" required>
                <mat-option *ngFor="let status of statuses" [value]="status">{{
                  'NOTIFICATION.SKELETON.' + status | translate
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>NOTIFICATION.SKELETON.Subject</mat-label>
              <textarea matInput formControlName="subject" required></textarea>
              <mat-hint align="end">{{
                form.get("subject").value?.length || 0
                }}/500</mat-hint>
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>NOTIFICATION.SKELETON.Start body</mat-label>
              <textarea matInput formControlName="startBody" required></textarea>
              <mat-hint align="end">{{
                form.get("startBody").value?.length || 0
                }}/500</mat-hint>
            </mat-form-field>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>NOTIFICATION.SKELETON.End body</mat-label>
              <textarea matInput formControlName="endBody" required></textarea>
              <mat-hint align="end">{{
                form.get("endBody").value?.length || 0
                }}/500</mat-hint>
            </mat-form-field>
            <div>
              <mat-form-field>
                <mat-label translate>NOTIFICATION.SKELETON.Central body</mat-label>
                <textarea matInput formControlName="centralBody"></textarea>
                <mat-hint align="end">{{
                  form.get("centralBody").value?.length || 0
                  }}/500</mat-hint>
              </mat-form-field>
              <mat-icon (click)="tooltip.toggle()" #tooltip="matTooltip" matTooltip="{{'NOTIFICATION.SKELETON.Central body info' | translate}}">info</mat-icon>
            </div>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>NOTIFICATION.SKELETON.Description</mat-label>
              <textarea matInput formControlName="description"></textarea>
              <mat-hint align="end">{{
                form.get("description").value?.length || 0
                }}/500</mat-hint>
            </mat-form-field>
          </div>
        </form>
        <div fxLayoutAlign="center center" fxLayoutGap="5px" fxLayout="row wrap">
          <button mat-raised-button (click)="onNoClick()" color="accent">{{'NOTIFICATION.SKELETON.Close' | translate}}</button>
          <button mat-raised-button (click)="onYesClick()" color="primary" [disabled]="form.invalid">{{'NOTIFICATION.SKELETON.Edit' | translate}}</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

</div>

