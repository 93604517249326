<div class="forbidden-page">
  <div class="logo">
    <span class="logo-number">4</span
    ><img src="assets/img/403.svg" alt="Forbidden" width="250" /><span
      class="logo-number"
      >3</span
    >
  </div>

  <h2 class="title is-3 has-text-centered" style="color: black;" translate>FORBIDDEN.403</h2>
  <p class="has-text-centered title is-5" style="color: black;" translate>
    FORBIDDEN.Message
  </p>

  <!-- <div class="has-text-centered">
    <a (click)="goBack()" class="button is-primary" translate>FORBIDDEN.Back</a>
  </div> -->
</div>

