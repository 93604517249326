import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CorrectiveMaintenanceDeviceDTO } from '../../models/device/corrective-maintenance/corrective-maintenance-device.model';
import { DeviceProblemType, TypePowerSypply } from '../../models/user/user.model';
import { TypePowerSupplyDTO } from '../../models/type-power-supply/type-power-supply.model';
import { ApiService } from '../../services/api.service';
import { LoggerService } from '../../services/logger.service';
import { TypePowerSuppliesDTO } from '../../models/type-power-supply/type-power-supplies.model';
import { DeviceTypePowerSuppliesDTO } from '../../models/device-type-power-supply/device-type-power-supplies.model';
import { DeviceTypePowerSupplyDTO } from '../../models/device-type-power-supply/device-type-power-supply.model';
import { ModelBatteryDTO } from '../../models/battery/model-battery/model-battery.model';
import { TypeBatteryDTO } from '../../models/battery/type-battery/type-battery.model';
import { ProducerBatteryDTO } from '../../models/battery/producer-battery/producer-battery.model';
import { UiService } from '../../services/ui.service';
import { ReplaySubject, Subject } from 'rxjs';
import { UtilService } from '../../services/util.service';
import { takeUntil } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import { BatteryDTO } from '../../models/battery/battery.model';


@Component({
  selector: 'app-dialog-close-corrective-maintenance-device',
  templateUrl: './dialog-close-corrective-maintenance-device.component.html',
  styleUrls: ['./dialog-close-corrective-maintenance-device.component.scss']
})
export class DialogCloseCorrectiveMaintenanceDeviceComponent implements OnInit {

  form: FormGroup = this.createForm({});
  correctiveMaintenance: CorrectiveMaintenanceDeviceDTO;
  modelBatteries: ModelBatteryDTO[];
  typeBatteries: TypeBatteryDTO[];
  producerBatteries: ProducerBatteryDTO[];
  batteries: BatteryDTO[];
  battery:BatteryDTO;

  @Output()
  public onSubmit = new EventEmitter<{}>();

  public filteredTypeBatteries: ReplaySubject<TypeBatteryDTO[]> = new ReplaySubject<
    TypeBatteryDTO[]
  >(1);
  public typeBatteryFilterCtrl: FormControl = new FormControl();

  private _onDestroy = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private uiService: UiService,
    private logger: LoggerService,
    private utilService: UtilService,
    private notifierService: NotifierService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogCloseCorrectiveMaintenanceDeviceComponent>,
    @Inject(MAT_DIALOG_DATA) public allData: any, private translate: TranslateService
  ) {
    this.form = this.createForm(allData.data);
    this.selectedProblemType(allData);
  }

  ngOnInit(): void {
    try {
    } catch (error) {}
  }

  async selectedProblemType(allData: any) {
    if (this.allData.data.problemType !== null && this.allData.data.problemType !== undefined) {
      const selectedProblemType = this.allData.problemTypes.find(c => c.name.toUpperCase() === this.allData.data.problemType.name.toUpperCase());
      this.form.get('problemType').setValue(selectedProblemType);
      this.form.get('problemTypeView').setValue(selectedProblemType);
    }
    if (this.form.get('problemType').value !== null && this.form.get('problemType').value !== undefined && this.form.get('problemType').value.name === DeviceProblemType.BAD_BATTERY){
      this.findAllActiveModelBattery();
      this.findAllActiveProducerBattery();
    }
  }

  public async findAllActiveModelBattery() {
    try {
      this.modelBatteries = await this.apiService.findAllActiveModelBattery(this.translate.currentLang);
    } catch (error) {
      this.logger.error(error);
      throw error;
    }
  }

  public async findAllActiveProducerBattery() {
    try {
      this.producerBatteries = await this.apiService.findAllActiveProducerBattery(this.translate.currentLang);
    } catch (error) {
      this.logger.error(error);
      throw error;
    }
  }

  public async findAllActiveBattery(model: any, type: any, producer: any) {
    try {
      this.batteries = await this.apiService.findAllActiveBattery(model.id, type.id, producer.id, this.translate.currentLang);
    } catch (error) {
      this.logger.error(error);
      throw error;
    }
  }

  createForm(data) {
    if (JSON.stringify(data) === '{}') {
      return this.fb.group({
        problemTypeView: [{disabled: true, value: null}, [Validators.required]],
        problemType: [{disabled: false, value: null}, [Validators.required]],
        operationName: [{disabled: false, value: null}, [Validators.required]],
        comment: [{disabled: false, value: null}, [Validators.maxLength(5000)]],
        battery: this.fb.group({
          model: [{ disabled: false, value: null }],
          type: [{ disabled: false, value: null }],
          producer: [{ disabled: false, value: null }],
          guarantee: [{ disabled: false, value: null }],
        }),
      });
    } else {
      return this.fb.group({
        problemTypeView: [{disabled: true, value: data.problemType}, [Validators.required]],
        problemType: [{disabled: false, value: data.problemType}, [Validators.required]],
        operationName: [{disabled: false, value: data.operationName}, [Validators.required]],
        comment: [{disabled: false, value: data.comment}, [Validators.maxLength(5000)]],
        battery: this.fb.group({
          model: [{ disabled: false, value: null}],
          type: [{ disabled: false, value: null}],
          producer: [{ disabled: false, value: null}],
          guarantee: [{ disabled: false, value: null}],
        }),
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    let data = {
      id: this.allData.data.id,
      body: this.form.value,
    }
    this.onSubmit.emit(data);
  }

  public async changeModelBattery(event: any) {
    try {
      this.uiService.setGlobalLoaderVisible(true);
      this.typeBatteries = [];
      this.filteredTypeBatteries.next(this.typeBatteries.slice());
      this.typeBatteryFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterTypeBatteries();
        });
      this.form.get('battery').get('type').setValue(null);
      if (event.isUserInput) {
        if (event.source.selected) {
          if (this.form.get('battery') !== null && this.form.get('battery') !== undefined) {
            this.typeBatteries = await this.apiService.findAllActiveTypeBattery(
              event.source.value.id,
              this.translate.currentLang
            );
            this.filteredTypeBatteries.next(this.typeBatteries.slice());
            this.typeBatteryFilterCtrl.valueChanges
              .pipe(takeUntil(this._onDestroy))
              .subscribe(() => {
                this.filterTypeBatteries();
              });
          }
        }
      }
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.uiService.setGlobalLoaderVisible(false);
    }
  }

  public async changeTypeBattery(event: any) {
    try {
      this.uiService.setGlobalLoaderVisible(true);
      this.batteries = [];
      this.form.get('battery').get('guarantee').setValue(null);
      if (event.isUserInput) {
        if (event.source.selected) {
          if (this.form.get('battery').get('model') !== null && this.form.get('battery').get('model') !== undefined &&
            this.form.get('battery').get('producer') !== null && this.form.get('battery').get('producer') !== undefined &&
            this.form.get('battery').get('model').value !== null && this.form.get('battery').get('model').value !== undefined &&
            this.form.get('battery').get('producer').value !== null && this.form.get('battery').get('producer').value !== undefined) {
            this.findAllActiveBattery(this.form.get('battery').get('model').value, event.source.value, this.form.get('battery').get('producer').value);
          }
        }
      }
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.uiService.setGlobalLoaderVisible(false);
    }
  }

  public async changeProducerBattery(event: any) {
    try {
      this.uiService.setGlobalLoaderVisible(true);
      this.batteries = [];
      this.form.get('battery').get('guarantee').setValue(null);
      if (event.isUserInput) {
        if (event.source.selected) {
          if (this.form.get('battery').get('model') !== null && this.form.get('battery').get('model') !== undefined &&
            this.form.get('battery').get('type') !== null && this.form.get('battery').get('type') !== undefined &&
            this.form.get('battery').get('model').value !== null && this.form.get('battery').get('model').value !== undefined &&
            this.form.get('battery').get('type').value !== null && this.form.get('battery').get('type').value !== undefined) {
            this.findAllActiveBattery(this.form.get('battery').get('model').value, this.form.get('battery').get('type').value, event.source.value);
          }
        }
      }
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.uiService.setGlobalLoaderVisible(false);
    }
  }

  private async selectedBattery() {
    // if (this.correctiveMaintenance.device?.battery?.guarantee.modelType.model !== null && this.device?.battery?.modelType.model !== undefined) {
    //   const selectedModelBattery = this.data.modelBatteries.find(c => c.id === this.device.battery.modelType.model.id);
    //   this.form.get('battery').get('model').setValue(selectedModelBattery);
    //   if (selectedModelBattery !== null && selectedModelBattery !== undefined) {
    //     this.typeBatteries = await this.apiService.findAllTypeBattery(
    //       this.device.battery.modelType.model.id,
    //       this.translate.currentLang
    //     );
    //     this.filteredTypeBatteries.next(this.typeBatteries.slice());
    //     this.typeBatteryFilterCtrl.valueChanges
    //       .pipe(takeUntil(this._onDestroy))
    //       .subscribe(() => {
    //         this.filterTypeBatteries();
    //       });
    //     const selectedTypeBattery = this.typeBatteries?.find(c => c.id === this.device.battery.modelType.type.id);
    //     this.form.get('battery').get('type').setValue(selectedTypeBattery);
    //     const selectedProducerBattery = this.data?.producerBatteries?.find(c => c.id === this.device.battery.producer.id);
    //     this.form.get('battery').get('producer').setValue(selectedProducerBattery);
    //     if (selectedModelBattery !== null && selectedModelBattery !== undefined && selectedTypeBattery !== null && selectedTypeBattery !== undefined && selectedProducerBattery !== null && selectedProducerBattery !== undefined) {
    //       await this.findAllActiveBattery(selectedModelBattery, selectedTypeBattery, selectedProducerBattery);
    //       const selectedGuarantee = this.batteries?.find(c => c.id === this.device.battery.id);
    //       this.form.get('battery').get('guarantee').setValue(selectedGuarantee);
    //     }
    //   }
    // }
  }

  public filterTypeBatteries() {
    try {
      if (!this.typeBatteries) {
        return;
      }
      let search = this.typeBatteryFilterCtrl.value;
      if (!search) {
        this.filteredTypeBatteries.next(this.typeBatteries.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      this.filteredTypeBatteries.next(
        this.typeBatteries.filter(
          (typeBattery) => typeBattery.name.toLowerCase().indexOf(search) > -1
        )
      );
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    }
  }


  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  get visibleBattery() {
    if (this.form.get('problemType').value !== null && this.form.get('problemType').value !== undefined && this.form.get('problemType').value.name === DeviceProblemType.BAD_BATTERY){
      return true;
    }
    return false;
  }
}

