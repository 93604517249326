import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ModuleDTO } from '../models/module/module.model';

@Pipe({
  name: 'moduleImage'
})
export class ModuleImagePipe implements PipeTransform {

  transform(module: ModuleDTO, value?: any): string {
    if (!module || !module.id || !module.image) {
      return 'assets/img/default-avatar.svg';
    }
    return `${environment.apiUrl}/module/${module.id}/image`;
  }

}
