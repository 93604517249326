import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private translate: TranslateService) { }

  public getErrorMessage(error: any) {
    if (
      error instanceof HttpErrorResponse &&
      error.error &&
      error.error.errors &&
      typeof error.error.errors === 'string'
    ) {
      return error.error.errors;
    }
    if (
      error instanceof HttpErrorResponse &&
      error.error &&
      error.error.errors &&
      typeof error.error.errors === 'object'
    ) {
      return JSON.stringify(error.error.errors);
    }
    if (
      error instanceof HttpErrorResponse &&
      error.error &&
      error.error.status &&
      error.error.status.message
    ) {
      return error.error.status.message;
    }
    if (error instanceof Error && error.message) {
      return error.message;
    }
    if (
      error instanceof HttpErrorResponse
    ) {
      let message = null
      this.translate.get('COMMON.Error with file').subscribe((value) => {
        message = value;
      });
      return message;
    }
    return 'Error';
  }
}
