import { Pipe, PipeTransform } from '@angular/core';
import { GatewayImageDTO } from '../models/gateway/image/gateway-image.model';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'gatewayImage'
})
export class GatewayImagePipe implements PipeTransform {

  transform(gatewayImage: GatewayImageDTO, data?: any): string {
    if (!gatewayImage || !gatewayImage.name) {
      return 'assets/img/default-avatar.svg';
    }
    return `${environment.apiUrl}/gateway/${gatewayImage.gatewayId}/image/${gatewayImage.id}`;
  }

}
