import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { LocationViewDTO } from '../../models/location/location.model';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { UiService } from '../../services/ui.service';
import { UtilService } from '../../services/util.service';
import { LoggerService } from '../../services/logger.service';
import { NotifierService } from 'angular-notifier';
import { ApiService } from '../../services/api.service';
import { GatewayViewDTO } from '../../models/gateway/gateway-view.model';
import { GatewayImageDTO } from '../../models/gateway/image/gateway-image.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dialog-edit-gateway',
  templateUrl: './dialog-edit-gateway.component.html',
  styleUrls: ['./dialog-edit-gateway.component.scss']
})
export class DialogEditGatewayComponent implements OnDestroy {

  public isLoading = false;

  form: FormGroup = this.createForm({});

  statuses: string[] = ['active', 'inactive'];
  public filteredLocations: ReplaySubject<LocationViewDTO[]> = new ReplaySubject<
    LocationViewDTO[]
  >(1);
  public locationFilterCtrl: FormControl = new FormControl();
  private _onDestroy = new Subject<void>();

  @Output()
  public onSubmit = new EventEmitter<{}>();
  gateway: GatewayViewDTO;

  constructor(public dialogRef: MatDialogRef<DialogEditGatewayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private translate: TranslateService, private fb: FormBuilder,
    private authService: AuthService,
    private uiService: UiService,
    private utilService: UtilService,
    private logger: LoggerService,
    private notifierService: NotifierService,
    private apiService: ApiService) {
      try {
        this.isLoading = true;
        setTimeout(async () => {
          try {
            await this.findGatewayById(data.gateway.id);
            this.filteredLocations.next(this.data.locations.slice());
            this.locationFilterCtrl.valueChanges
              .pipe(takeUntil(this._onDestroy))
              .subscribe(() => {
                this.filterLocations();
              });
            this.form = this.createForm(data.gateway);
            this.selectedLocation();
            this.selectedStatus();
          } catch (error) {
            this.logger.error(error);
            this.utilService.getErrorMessage(error);
            this.notifierService.show({
              type: 'error',
              message: this.utilService.getErrorMessage(error),
            });
            this.dialogRef.close();
          } finally {
            this.isLoading = false;
          }
        });
      } catch (error) {
        this.logger.error(error);
        this.utilService.getErrorMessage(error);
        this.notifierService.show({
          type: 'error',
          message: this.utilService.getErrorMessage(error),
        });
        this.dialogRef.close();
        this.isLoading = false;
      } finally {
      }
  }

  public async findGatewayById(id: number) {
    try {
      this.gateway = await this.apiService.findGatewayDetailById(id, this.translate.currentLang);
    } catch (error) {
      this.logger.error(error);
      throw error;
    }
  }

  private selectedLocation() {
    if (this.data.locations !== null && this.data.locations !== undefined && this.data.gateway.location !== null && this.data.gateway.location !== undefined) {
      const selectedLocation = this.data.locations.find(c => c.id == this.data.gateway.location.id);
      this.form.get('location').setValue(selectedLocation);
    }
  }

  private selectedStatus() {
    if (this.data.gateway.status !== null && this.data.gateway.status !== undefined) {
      const selectedStatus = this.statuses.find(c => c.toUpperCase() === this.data.gateway.status.toUpperCase());
      this.form.get('status').setValue(selectedStatus);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public filterLocations() {
    try {
      if (!this.data.locations) {
        return;
      }
      let search = this.locationFilterCtrl.value;
      if (!search) {
        this.filteredLocations.next(this.data.locations.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      this.filteredLocations.next(
        this.data.locations.filter(
          (location) => location.name.toLowerCase().indexOf(search) > -1
        )
      );
    } catch (error) {
    }
  }

  private createForm(data) {
    if (JSON.stringify(data) === '{}') {
      return this.fb.group({
        originalId: [{ disabled: false, value: '' }, [Validators.required]],
        location: [{ disabled: false, value: '' }],
        name: [{ disabled: false, value: '' }, [Validators.required]],
        latitude: [{ disabled: false, value: '' }],
        longitude: [{ disabled: false, value: '' }],
        description: [{ disabled: false, value: '' }, [Validators.maxLength(500)]],
        status: [{ disabled: false, value: '' }, [Validators.required]],
      });
    } else {
      return this.fb.group({
        originalId: [{ disabled: false, value: data.originalId }, [Validators.required]],
        location: [{ disabled: false, value: data.location }],
        name: [{ disabled: false, value: data.name }, [Validators.required]],
        latitude: [{ disabled: false, value: data.latitude }],
        longitude: [{ disabled: false, value: data.longitude }],
        description: [{ disabled: false, value: data.description }, [Validators.maxLength(500)]],
        status: [{ disabled: false, value: data.status }, [Validators.required]],
      });
    }
  }

  onYesClick(): void {
    let data = {
      ...this.form.value,
    }
    this.onSubmit.emit(data);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  public async showImage(data: any) {
    try {
      this.isLoading = true;
      this.uiService.openGatewayImageModal(data);
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.isLoading = false;
    }
  }

  public async deleteImage(gatewayImage: GatewayImageDTO) {
    try {
      this.isLoading = true;
      const message = await this.apiService.removeGatewayImage(gatewayImage.gatewayId, gatewayImage.id, this.translate.currentLang);
      this.notifierService.show({
        type: 'success',
        message,
      });
      await this.findGatewayById(gatewayImage.gatewayId);
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.isLoading = false;
    }
  }

  public async onFileInputAddImage(data: any, changeEvent: { target: HTMLInputElement }) {
    try {
      this.isLoading = true;
      const file = changeEvent.target.files[0];
      if (!file) {
        // TODO: handle this
        return;
      }
      const message = await this.apiService.uploadGatewayImage(data.id, file, this.translate.currentLang);
      this.notifierService.show({
        type: 'success',
        message,
      });
      await this.findGatewayById(data.id);
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      changeEvent.target.value = null;
      this.isLoading = false;
    }
  }

  get gatewayImageCount() {
    return environment.gatewayImageCount;
  }
}
