<div mat-dialog-content>
  <div fxLayoutAlign="space-around center" fxLayoutGap="5px" fxLayout="row wrap" class="general">
    <mat-card>
      <mat-card-content>
        <span class="exception-title" *ngIf="data.new">
          {{ "RESTRICTION." + data?.rp?.restriction?.name | translate }} * {{ data?.rp?.restrictionProfile?.name }}: {{ data?.rp?.restrictionProfile?.min }} -
          {{ data?.rp?.restrictionProfile?.middleMax }} -
          {{ data?.rp?.restrictionProfile?.max }}
        </span>
        <span class="exception-title" *ngIf="!data.new">
          {{ "RESTRICTION." + data?.rp?.restriction?.name | translate }} * {{ data?.rp?.name }}: {{ data?.rp?.min }} -
          {{ data?.rp?.middleMax }} -
          {{ data?.rp?.max }}
        </span>
        <form [formGroup]="form" autocomplete="off" *ngIf="adminRole || managerRole">
          <div fxLayout="row wrap" fxLayoutAlign="start center" class="div-content">
            <mat-form-field>
              <mat-label translate>RESTRICTION.EXCEPTION.Day</mat-label>
              <mat-select formControlName="day">
                <mat-option *ngFor="let day of days" [value]="day">{{'RESTRICTION.EXCEPTION.' + day | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div>
              <div fxLayout="row wrap" fxLayoutAlign="start center">
                <span class="start-time">
                  {{ "RESTRICTION.EXCEPTION.Start time" | translate }}
                </span>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="start center">
                <ngx-mat-timepicker formControlName="startTime" [defaultTime]="defaulTime"></ngx-mat-timepicker>
              </div>
            </div>
            <div>
              <div fxLayout="row wrap" fxLayoutAlign="start center">
                <span class="end-time">
                  {{ "RESTRICTION.EXCEPTION.End time" | translate }}
                </span>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="start center">
                <ngx-mat-timepicker formControlName="endTime" [defaultTime]="defaulTime"></ngx-mat-timepicker>
              </div>
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="5px">
            <button
            mat-raised-button
            [disabled]="form.invalid"
            (click)="addItem(form.value)" color="primary"
          >
          {{ "RESTRICTION.EXCEPTION.Add item" | translate}}
          </button>
          <button mat-raised-button (click)="cancel()" class="cancel">
            {{ "RESTRICTION.EXCEPTION.Cancel item" | translate}}
          </button>
          </div>

        </form>
        <div *ngIf="tableData">
          <app-table-item2
            [tableData]="tableData"
            (action)="performAction($event)"
            (slide)="performSlide($event)"
            [checked]="checked"
          ></app-table-item2>
        </div>
        <div mat-dialog-actions align="end" fxLayout="row wrap">
          <button mat-raised-button (click)="onNoClick()" color="accent">{{'RESTRICTION.EXCEPTION.Close' | translate}}</button>
          <button mat-raised-button (click)="onYesClick()" color="primary" *ngIf="adminRole || managerRole">{{'RESTRICTION.EXCEPTION.Edit' | translate}}</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
