<!-- <mat-form-field>
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="{{'TABLE.Search' | translate}}" autocomplete="off"/>
</mat-form-field> -->
<!--
  moze i ovako ali kada je sort @ViewChild(MatSort, { static: false }) sort: MatSort;
  <table
  class="mat-elevation-z8"
  mat-table
  *ngIf="tableData"
  [dataSource]="dataSource"
  matSort
  (matSortChange)="sortChanged($event)"
  style="width: 100%"
>
-->
<div class="table-wrapper">
<table
  class="mat-elevation-z8"
  mat-table
  [dataSource]="dataSource"
  matSort
  (matSortChange)="sortChanged($event)"
  style="width: 100%"
>
  <ng-container
    *ngFor="let column of tableData.displayedColumns"
    [matColumnDef]="column.value"
  >
    <th mat-header-cell *matHeaderCellDef>
      <div *ngIf="column.checkBox" style="text-align: center">
        {{ column.header | translate }}
        <!--<mat-divider [vertical]="true"></mat-divider>
        <mat-checkbox (change)="changeCheckAll($event.checked)">
        </mat-checkbox>-->
      </div>
      <div *ngIf="!column.checkBox">
        {{ column.header | translate }}
      </div>

    </th>
    <!-- kad ima sorta. sklonjen sort zato sto se prilikom sorta gubi dobar id reda i pokazuje se los element zato sto se sortira u memoriji a ne poziva se baza i ne
      menja se data tabele i tabela zbog toga pamti stari redosled elemenata
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center">
      {{ column.header }}
    </th>
    -->
    <td
      mat-cell
      *matCellDef="let element; let i = index"
    >
      <div *ngIf="column.checkBox" style="text-align: center">
        <mat-checkbox
          [checked]="getProperty(element, column.value)" (change)="changeOperationStatus($event.checked, i)"
        >
        </mat-checkbox>
      </div>
      <div *ngIf="!column.checkBox && !column.translate && !column.pipe">
        {{ getProperty(element, column.value) }}
      </div>
      <div *ngIf="!column.checkBox && !column.translate && column.pipe">
        <div *ngIf="column.type === 'date'">
          {{ getProperty(element, column.value) | date: column.format }}
        </div>
      </div>
      <div *ngIf="!column.checkBox && column.translate">
        {{ 'COMMON.' + getProperty(element, column.value) | translate}}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="slider" *ngIf="tableData.slider">
    <th mat-header-cell *matHeaderCellDef>{{tableData.slider.name | translate}}</th>
    <td mat-cell *matCellDef="let element; let i = index" class="slider">
      <mat-slide-toggle color="primary" [(ngModel)]="checked[i]" (toggleChange)="performSlide(checked[i], i)" *ngIf="element.id !== null && element.id !== undefined && element.id !== 0"></mat-slide-toggle>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions" *ngIf="tableData.actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element; let i = index" >
      <div *ngFor="let action of tableData.actions">
        <button
        color="primary"
        mat-icon-button
        *ngIf="(action.id === 1) || (action.id === 2 && (element.id === null || element.id === undefined || element.id === 0))"
      >
        <mat-icon (click)="performAction(action.name, i)">{{ action.iconClass }}</mat-icon>
      </button>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns; let even = even; let odd = odd;" [ngClass]="{'make-even': even, 'make-odd': odd}"></tr>
</table>
</div>
<mat-paginator
  [pageSizeOptions]="[50, 100, 200]"
  [length]="length"
  showFirstLastButtons
  (page)="pageChanged($event)"
  [pageIndex]="pageIndex"
></mat-paginator>
