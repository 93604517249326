<div mat-dialog-content>
  <div fxLayoutAlign="space-around center" fxLayoutGap="5px" fxLayout="row wrap" class="general">
    <mat-card>
      <mat-card-header fxLayoutAlign="space-around center" fxLayout="row wrap" class="title">
        <mat-card-title>{{ data.client.name }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="form" autocomplete="off">
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>CLIENT.DIALOG.Modules</mat-label>
              <mat-select formControlName="modules" multiple>
                <mat-option *ngFor="let module of data.modules" [value]="module"
                  (onSelectionChange)="changeModule($event)">{{ "MODULE." + module.name | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-accordion class="example-headers-align" multi *ngIf="visibleSS || visibleSL || visibleSWM">
              <mat-expansion-panel class="client-basic ss" expanded="true" *ngIf="visibleSS">
                <mat-expansion-panel-header>
                  <mat-panel-title class="title-ss"> {{ 'MODULE.' + moduleSS | translate }} </mat-panel-title>
                  <mat-panel-description fxLayoutAlign="end center" translate>
                    <mat-icon>apps</mat-icon>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <form [formGroup]="moduleSSForm" autocomplete="off">
                  <div fxLayout="row wrap" fxLayoutAlign="space-around center">
                    <mat-form-field>
                      <mat-label translate>CLIENT.DIALOG.Locations</mat-label>
                      <mat-select #selectss formControlName="locations" multiple (selectionChange)="changeLocationSS($event)">
                        <mat-option>
                          <ngx-mat-select-search [formControl]="locationFilterCtrlSS" placeholderLabel="{{'CLIENT.DIALOG.Location placeholder' | translate}}" noEntriesFoundLabel="{{'CLIENT.DIALOG.Location not found' | translate}}"></ngx-mat-select-search>
                        </mat-option>
                        <div class="select-all">
                          <mat-checkbox
                            [(ngModel)]="allSelectedSS"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="toggleAllSelectionSS()"
                            >{{ "COMMON.Choose all" | translate }}</mat-checkbox
                          >
                        </div>
                        <mat-option *ngFor="let location of filteredLocationsSS | async" [value]="location">{{
                          location.name
                          }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </form>
                <div fxLayout="row wrap" fxLayoutAlign="space-around center">
                  <app-table-item
                    [tableData]="tableDataSS"
                    (slide)="performSlideSS($event)"
                    [checked]="checkedSS"
                  ></app-table-item>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel class="client-basic sl" expanded="true" *ngIf="visibleSL">
                <mat-expansion-panel-header>
                  <mat-panel-title class="title-sl"> {{ 'MODULE.' + moduleSL | translate }} </mat-panel-title>
                  <mat-panel-description fxLayoutAlign="end center" translate>
                    <mat-icon>apps</mat-icon>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <form [formGroup]="moduleSLForm" autocomplete="off">
                  <div fxLayout="row wrap" fxLayoutAlign="space-around center">
                    <mat-form-field>
                      <mat-label translate>CLIENT.DIALOG.Locations</mat-label>
                      <mat-select #selectsl formControlName="locations" multiple (selectionChange)="changeLocationSL($event)">
                        <mat-option>
                          <ngx-mat-select-search [formControl]="locationFilterCtrlSL" placeholderLabel="{{'CLIENT.DIALOG.Location placeholder' | translate}}" noEntriesFoundLabel="{{'CLIENT.DIALOG.Location not found' | translate}}"></ngx-mat-select-search>
                        </mat-option>
                        <div class="select-all">
                          <mat-checkbox
                            [(ngModel)]="allSelectedSL"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="toggleAllSelectionSL()"
                            >{{ "COMMON.Choose all" | translate }}</mat-checkbox
                          >
                        </div>
                        <mat-option *ngFor="let location of filteredLocationsSL | async" [value]="location">{{
                          location.name
                          }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </form>
                <div fxLayout="row wrap" fxLayoutAlign="space-around center">
                  <app-table-item
                    [tableData]="tableDataSL"
                    (slide)="performSlideSL($event)"
                    [checked]="checkedSL"
                  ></app-table-item>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel class="client-basic swm" expanded="true" *ngIf="visibleSWM">
                <mat-expansion-panel-header>
                  <mat-panel-title class="title-swm"> {{ 'MODULE.' + moduleSWM | translate }} </mat-panel-title>
                  <mat-panel-description fxLayoutAlign="end center" translate>
                    <mat-icon>apps</mat-icon>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <form [formGroup]="moduleSWMForm" autocomplete="off">
                  <div fxLayout="row wrap" fxLayoutAlign="space-around center">
                    <mat-form-field>
                      <mat-label translate>CLIENT.DIALOG.Locations</mat-label>
                      <mat-select #selectswm formControlName="locations" multiple (selectionChange)="changeLocationSWM($event)">
                        <mat-option>
                          <ngx-mat-select-search [formControl]="locationFilterCtrlSWM" placeholderLabel="{{'CLIENT.DIALOG.Location placeholder' | translate}}" noEntriesFoundLabel="{{'CLIENT.DIALOG.Location not found' | translate}}"></ngx-mat-select-search>
                        </mat-option>
                        <div class="select-all">
                          <mat-checkbox
                            [(ngModel)]="allSelectedSWM"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="toggleAllSelectionSWM()"
                            >{{ "COMMON.Choose all" | translate }}</mat-checkbox
                          >
                        </div>
                        <mat-option *ngFor="let location of filteredLocationsSWM | async" [value]="location">{{
                          location.name
                          }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </form>
                <div fxLayout="row wrap" fxLayoutAlign="space-around center">
                  <app-table-item
                    [tableData]="tableDataSWM"
                    (slide)="performSlideSWM($event)"
                    [checked]="checkedSWM"
                  ></app-table-item>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </form>
        <br/>
        <div fxLayoutAlign="center center" fxLayoutGap="5px" fxLayout="row wrap">
          <button mat-raised-button (click)="onNoClick()" color="accent">
            {{ "CLIENT.DIALOG.Close" | translate }}
          </button>
          <button mat-raised-button (click)="onYesClick()" color="primary" [disabled]="form.invalid">
            {{ "CLIENT.DIALOG.Edit" | translate }}
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
