import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { UiService } from '../../services/ui.service';
import { UtilService } from '../../services/util.service';
import { LoggerService } from '../../services/logger.service';
import { NotifierService } from 'angular-notifier';
import { ApiService } from '../../services/api.service';
import { ClientDTO } from '../../models/client/client.model';
import { ModuleDTO } from '../../models/module/module.model';
import { LocationViewDTO } from '../../models/location/location.model';
import { ReplaySubject, Subject } from 'rxjs';
import { DeviceDTO } from '../../models/device/device.model';
import { takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { DeviceStatus, SectionDeviceStatus, SectionModuleClientLocationStatus } from '../../models/user/user.model';

@Component({
  selector: 'app-dialog-edit-section',
  templateUrl: './dialog-edit-section.component.html',
  styleUrls: ['./dialog-edit-section.component.scss']
})
export class DialogEditSectionComponent implements OnDestroy {

  @ViewChild('selectLocation') selectLocation: MatSelect;
  public allSelectedLocation: boolean = false;

  @ViewChild('selectDevice') selectDevice: MatSelect;
  public allSelectedDevice: boolean = false;

  form: FormGroup = this.createForm({});

  clients: ClientDTO[];
  modules: ModuleDTO[];

  statuses: string[] = ['active', 'inactive'];

  @Output()
  public onSubmit = new EventEmitter<{}>();

  locations: LocationViewDTO[] = [];
  public filteredLocations: ReplaySubject<LocationViewDTO[]> =
    new ReplaySubject<LocationViewDTO[]>(1);
  public locationFilterCtrl: FormControl = new FormControl();
  private _onDestroy = new Subject<void>();

  devices: DeviceDTO[] = [];
  public filteredDevices: ReplaySubject<DeviceDTO[]> =
    new ReplaySubject<DeviceDTO[]>(1);
  public deviceFilterCtrl: FormControl = new FormControl();

  constructor(public dialogRef: MatDialogRef<DialogEditSectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private fb: FormBuilder,
    private authService: AuthService,
    private uiService: UiService,
    private utilService: UtilService,
    private logger: LoggerService,
    private notifierService: NotifierService,
    private apiService: ApiService) {
    try {
      setTimeout(async () => {
        try {
          this.uiService.setGlobalLoaderVisible(true);
          this.form = this.createForm(data);
          await this.findAllDeviceOrLocation();
          this.selectedDevicesOrLocations();
          this.selectedStatus();
        } catch (error) {
          this.logger.error(error);
          this.notifierService.show({
            type: 'error',
            message: this.utilService.getErrorMessage(error),
          });
        } finally {
          this.uiService.setGlobalLoaderVisible(false);
        }
      });
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
      if (this.uiService.isGlobalLoaderVisible) {
        this.uiService.setGlobalLoaderVisible(false);
      }
    } finally {
    }
  }

  private selectedDevicesOrLocations() {
    if (this.device) {
      let selectedDevices = [];
      if (this.data.devices !== null && this.data.devices !== undefined) {
        this.data.devices.forEach(element => {
          const selectedDevice = this.devices.find(c => c.id == element.device.id && element.status === SectionDeviceStatus.ACTIVE);
          selectedDevices.push(selectedDevice);
        });
        this.form.get('devices').setValue(selectedDevices);
      }
    }
    if (this.location) {
      let selectedLocations = [];
      if (this.data.moduleClientLocations !== null && this.data.moduleClientLocations !== undefined) {
        this.data.moduleClientLocations.forEach(element => {
          const selectedLocation = this.locations.find(c => c.id == element.moduleClientLocation.location.id && element.status === SectionModuleClientLocationStatus.ACTIVE);
          selectedLocations.push(selectedLocation);
        });
        this.form.get('locations').setValue(selectedLocations);
      }
    }
  }

  public async findAllDeviceOrLocation() {
    try {
      if (this.device) {
        this.devices = await this.apiService.findAllDeviceForSection(this.form.get('client').value.id, this.form.get('module').value.id, this.translate.currentLang);
        this.filteredDevices.next(this.devices.slice());
        this.deviceFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterDevices();
          });
      }
      if (this.location) {
        this.locations = await this.apiService.findAllLocation(this.form.get('client').value.id, this.form.get('module').value.id, this.translate.currentLang);
        this.filteredLocations.next(this.locations.slice());
        this.locationFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterLocations();
          });
      }
    } catch (error) {
      this.logger.error(error);
      throw error;
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private createForm(data) {
    this.clients = [];
    this.modules = [];
    if (JSON.stringify(data) === '{}') {
      return this.fb.group({
        name: [{ disabled: false, value: '' }, [Validators.required]],
        client: [{ disabled: false, value: '' }, [Validators.required]],
        module: [{ disabled: false, value: '' }, [Validators.required]],
        description: [{ disabled: false, value: '' }, [Validators.maxLength(500)]],
        status: [{ disabled: false, value: '' }, [Validators.required]],
        type: [{ disabled: false, value: null }, [Validators.required]],
        locations: [{ disabled: false, value: null }],
        devices: [{ disabled: false, value: null }],
        //emails: [{ disabled: false, value: null }],
      });
    } else {
      this.clients.push(data?.moduleClient.client);
      this.modules.push(data?.moduleClient.module);
      return this.fb.group({
        name: [{ disabled: false, value: data.name }, [Validators.required]],
        client: [{ disabled: false, value: data?.moduleClient.client }, [Validators.required]],
        module: [{ disabled: false, value: data?.moduleClient.module }, [Validators.required]],
        description: [{ disabled: false, value: data.description }, [Validators.maxLength(500)]],
        status: [{ disabled: false, value: data.status }, [Validators.required]],
        type: [{ disabled: false, value: data.type.toLowerCase() }, [Validators.required]],
        locations: [{ disabled: false, value: [] }],
        devices: [{ disabled: false, value: [] }],
        //emails: [{ disabled: false, value: null }],
      });
    }
  }

  private selectedStatus() {
    if (this.data !== null && this.data.status !== undefined) {
      const selectedStatus = this.statuses.find(c => c.toUpperCase() === this.data.status.toUpperCase());
      this.form.get('status').setValue(selectedStatus);
    }
  }

  get adminRole() {
    return this.authService.hasADMRole();
  }

  get userRole() {
    return this.authService.hasUSERRole();
  }

  get location() {
    if (this.form.get('type') !== null && this.form.get('type') !== undefined && this.form.get('type').value === 'location') {
      return true;
    }
    return false;
  }

  get device() {
    if (this.form.get('type') !== null && this.form.get('type') !== undefined && this.form.get('type').value === 'device') {
      return true;
    }
    return false;
  }

  public filterLocations() {
    try {
      if (!this.locations) {
        return;
      }
      let search = this.locationFilterCtrl.value;
      if (!search) {
        this.filteredLocations.next(this.locations.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      this.filteredLocations.next(
        this.locations.filter(
          (location) => location.name.toLowerCase().indexOf(search) > -1
        )
      );
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    }
  }

  public filterDevices() {
    try {
      if (!this.devices) {
        return;
      }
      let search = this.deviceFilterCtrl.value;
      if (!search) {
        this.filteredDevices.next(this.devices.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      this.filteredDevices.next(
        this.devices.filter(
          (device) => device.name.toLowerCase().indexOf(search) > -1
        )
      );
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    }
  }

  public toggleAllSelectionLocation() {
    if (this.allSelectedLocation) {
      this.selectLocation.options.forEach((item: MatOption) => {
        if (item.value !== null && item.value !== undefined) {
          item.select();
        }
      });
    } else {
      this.selectLocation.options.forEach((item: MatOption) => item.deselect());
    }
  }

  public toggleAllSelectionDevice() {
    if (this.allSelectedDevice) {
      this.selectDevice.options.forEach((item: MatOption) => {
        if (item.value !== null && item.value !== undefined) {
          item.select();
        }
      });
    } else {
      this.selectDevice.options.forEach((item: MatOption) => item.deselect());
    }
  }

  onYesClick(): void {
    let data = {
      ...this.form.value,
    };
    this.onSubmit.emit(data);
  }
}
