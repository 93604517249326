<h1 mat-dialog-title>{{ allData.device.name }}</h1>
<div mat-dialog-content>
  <form [formGroup]="form" autocomplete="off">
    <div class="columns">
      <div class="column">
        <mat-form-field>
          <mat-label translate
            >DEVICE.CORRECTIVE_MAINTENANCE.DIALOG.Comment</mat-label
          >
          <textarea formControlName="comment" matInput rows="5"></textarea>
          <mat-hint align="end">{{
            form.get("comment").value?.length || 0
            }}/5000</mat-hint>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button (click)="onNoClick()" color="accent">
    {{ "DEVICE.CORRECTIVE_MAINTENANCE.DIALOG.Cancel" | translate }}
  </button>
  <button
    mat-raised-button
    (click)="onYesClick()"
    color="primary"
    [disabled]="form.invalid"
  >
    {{ "DEVICE.CORRECTIVE_MAINTENANCE.DIALOG.Edit" | translate }}
  </button>
</div>
