import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { UtilService } from 'src/app/shared/services/util.service';


@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  animations: [
    trigger('parent', [transition(':enter', [])]),
    trigger('formAnimation', [
      transition(':leave', [
        style({ position: 'absolute' }),
        animate('0.5s', style({ opacity: 0, transform: 'translateX(200%)' })),
      ]),
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(200%)' }),
        animate('0.5s', style({ opacity: 1, transform: 'translateX(0px)' })),
      ]),
    ]),
  ]
})
export class LoginPageComponent implements OnInit {
  signin: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  });

  public hide = true;

  public loginErrorMessage = '';
  public isLoginLoading = false;

  get usernameInput() { return this.signin.get('username'); }
  get passwordInput() { return this.signin.get('password'); }

  constructor(private authService: AuthService, private router: Router, private utilService: UtilService, private uiService: UiService, private logger: LoggerService, private translate: TranslateService) { }

  ngOnInit(): void {
  }

  public async onLoginSubmit() {
    try {
      this.uiService.setGlobalLoaderVisible(true);
      this.isLoginLoading = true;
      await this.authService.login(
        this.signin.value.username,
        this.signin.value.password,
        this.translate.currentLang
      );
      this.router.navigateByUrl('module/view-all-for-user');
      this.uiService.setGlobalLoaderVisible(false);
    } catch (error) {
      this.logger.error(error);
      this.isLoginLoading = false;
      this.uiService.setGlobalLoaderVisible(false);
      this.loginErrorMessage = this.utilService.getErrorMessage(error);
    }
  }
}


