import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { UiService } from '../../services/ui.service';
import { UtilService } from '../../services/util.service';
import { LoggerService } from '../../services/logger.service';
import { NotifierService } from 'angular-notifier';
import { ApiService } from '../../services/api.service';
import { LocationViewDTO } from '../../models/location/location.model';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DeviceModelDTO } from '../../models/device/model/device-model.model';
import { DeviceResponseTimeDTO } from '../../models/device/response-time/device-response-time.model';
import { DeviceCommandNumberPeriodTimeDTO } from '../../models/device/command-number-period-time/device-command-number-period-time.model';
import { ModelName, ModuleEnum, ProducerName, TypePowerSypply } from '../../models/user/user.model';
import { DeviceImageDTO } from '../../models/device/image/device-image.model';
import { DeviceViewWithDataDTO } from '../../models/device/device-view-with-data.model';
import { environment } from 'src/environments/environment';
import { BatteryDTO } from '../../models/battery/battery.model';
import { TypeBatteryDTO } from '../../models/battery/type-battery/type-battery.model';
import { ProducerDTO } from '../../models/producer/producer.model';
import { ModelBatteryDTO } from '../../models/battery/model-battery/model-battery.model';

@Component({
  selector: 'app-dialog-edit-device',
  templateUrl: './dialog-edit-device.component.html',
  styleUrls: ['./dialog-edit-device.component.scss'],
})
export class DialogEditDeviceComponent implements OnDestroy {

  public isLoading = false;

  form: FormGroup = this.createForm({});

  @Output()
  public onSubmit = new EventEmitter<{}>();
  device: DeviceViewWithDataDTO;
  battery: BatteryDTO;

  statuses: string[] = ['active', 'inactive'];
  public filteredLocations: ReplaySubject<LocationViewDTO[]> =
    new ReplaySubject<LocationViewDTO[]>(1);
  public filteredTypeBatteries: ReplaySubject<TypeBatteryDTO[]> = new ReplaySubject<
    TypeBatteryDTO[]
  >(1);
  public locationFilterCtrl: FormControl = new FormControl();
  public typeBatteryFilterCtrl: FormControl = new FormControl();

  private _onDestroy = new Subject<void>();

  models: DeviceModelDTO[] = [];
  responseTimes: DeviceResponseTimeDTO[] = [];
  commandNumberPeriodTimes: DeviceCommandNumberPeriodTimeDTO[] = [];
  producerBatteries: ProducerDTO[] = [];
  modelBatteries: ModelBatteryDTO[];
  typeBatteries: TypeBatteryDTO[];
  batteries: BatteryDTO[];

  constructor(
    public dialogRef: MatDialogRef<DialogEditDeviceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private fb: FormBuilder,
    private authService: AuthService,
    private uiService: UiService,
    private utilService: UtilService,
    private logger: LoggerService,
    private notifierService: NotifierService,
    private apiService: ApiService
  ) {
    try {
      this.isLoading = true;
      setTimeout(async () => {
        try {
          await this.findDeviceById(data.device.id);
          this.form = this.createForm(this.device);
          if (authService.hasADMRole()) {
            this.selectedModuleClientLocation();
            this.selectedProducer();
            this.selectedReadType();
            this.selectedResponseTime();
            this.selectedCommandNumberPerPeriod();
          }
          this.selectedTypePowerSupply();
          this.selectedBattery();
          this.selectedStatus();
        } catch (error) {
          this.logger.error(error);
          this.utilService.getErrorMessage(error);
          this.notifierService.show({
            type: 'error',
            message: this.utilService.getErrorMessage(error),
          });
          this.dialogRef.close();
        } finally {
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.logger.error(error);
      this.utilService.getErrorMessage(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
      this.dialogRef.close();
      this.isLoading = false;
    } finally {
    }
  }

  get deviceImageCount() {
    return environment.deviceImageCount;
  }

  public async findDeviceById(id: number) {
    try {
      this.device = await this.apiService.findDeviceDetailById(id, this.authService.selectedModule, this.translate.currentLang);
    } catch (error) {
      this.logger.error(error);
      throw error;
    }
  }

  get userRole() {
    return this.authService.hasUSERRole();
  }

  get adminRole() {
    return this.authService.hasADMRole();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private createForm(data) {
    if (JSON.stringify(data) === '{}') {
      return this.fb.group({
        eui: [{ disabled: false, value: '' }, [Validators.required]],
        name: [{ disabled: false, value: '' }, [Validators.required]],
        originalName: [{ disabled: false, value: '' }, [Validators.required]],
        latitude: [{ disabled: false, value: '' }],
        longitude: [{ disabled: false, value: '' }],
        description: [
          { disabled: false, value: '' },
          [Validators.maxLength(500)],
        ],
        status: [{ disabled: false, value: '' }, [Validators.required]],
        moduleClientLocation: this.fb.group({
          clientId: [{ disabled: false, value: null }],
          moduleId: [{ disabled: false, value: null }],
          locationId: [{ disabled: false, value: null }],
        }),
        devAddr: [{ disabled: false, value: '' }],
        producer: [{ disabled: false, value: null }, [Validators.required]],
        model: [{ disabled: false, value: null }, [Validators.required]],
        sendCommand: [{ disabled: false, value: 'false' }, [Validators.required]],
        readType: [{ disabled: false, value: null }],
        responseTime: [{ disabled: false, value: null }],
        commandNumberPeriodTime: [{ disabled: false, value: null }],
        typePowerSupply: [{ disabled: false, value: null }],
        newBattery: [{ disabled: false, value: null }],
        battery: this.fb.group({
          model: [{ disabled: false, value: null }],
          type: [{ disabled: false, value: null }],
          producer: [{ disabled: false, value: null }],
          guarantee: [{ disabled: false, value: null }],
        }),
      });
    } else {
      return this.fb.group({
        eui: [{ disabled: false, value: data.eui }, [Validators.required]],
        name: [{ disabled: false, value: data.name }, [Validators.required]],
        originalName: [
          { disabled: false, value: data.originalName },
          [Validators.required],
        ],
        latitude: [{ disabled: false, value: data.latitude }],
        longitude: [{ disabled: false, value: data.longitude }],
        description: [
          { disabled: false, value: data.description },
          [Validators.maxLength(500)],
        ],
        status: [
          { disabled: false, value: data.status },
          [Validators.required],
        ],
        moduleClientLocation: this.fb.group({
          clientId: [
            {
              disabled: false,
              value: data.moduleClientLocation?.moduleClient?.client.id,
            },
          ],
          moduleId: [
            {
              disabled: false,
              value: data.moduleClientLocation?.moduleClient?.module.moduleId,
            },
          ],
          locationId: [
            { disabled: false, value: data.moduleClientLocation?.location.id },
          ],
        }),
        devAddr: [{ disabled: false, value: data.devAddr }],
        producer: [{ disabled: false, value: data.model.producer }, [Validators.required]],
        model: [{ disabled: false, value: data.model }, [Validators.required]],
        sendCommand: [{ disabled: false, value: data.sendCommand + '' }, [Validators.required]],
        readType: [{ disabled: false, value: data.readType }],
        responseTime: [{ disabled: false, value: data.responseTime }],
        commandNumberPeriodTime: [{ disabled: false, value: data.commandNumberPeriodTime }],
        typePowerSupply: [{ disabled: false, value: data.typePowerSupply }],
        newBattery: [{ disabled: false, value: data?.battery !== null && data?.battery !== undefined ? '0' : null }],
        battery: this.fb.group({
          model: [{ disabled: data?.battery !== null && data?.battery !== undefined ? true : false, value: data?.battery?.modelType?.model }],
          type: [{ disabled: data?.battery !== null && data?.battery !== undefined ? true : false, value: data?.battery?.type }],
          producer: [{ disabled: data?.battery !== null && data?.battery !== undefined ? true : false, value: data?.battery?.producer }],
          guarantee: [{ disabled: data?.battery !== null && data?.battery !== undefined ? true : false, value: data?.battery?.guarantee }],
        }),
      });
    }
  }

  private async selectedModuleClientLocation() {
    if (
      this.data.device.moduleClientLocation !== undefined &&
      this.data.device.moduleClientLocation !== null &&
      this.data.device.moduleClientLocation.moduleClient !== undefined &&
      this.data.device.moduleClient !== null &&
      this.data.clients !== null &&
      this.data.clients !== undefined &&
      this.data.modules !== null &&
      this.data.modules !== undefined &&
      this.data.locations !== null &&
      this.data.locations !== undefined
    ) {
      const selectedClient = this.data.clients.find(
        (c) =>
          c.id == this.data.device.moduleClientLocation.moduleClient.client.id
      );
      this.form
        .get('moduleClientLocation')
        .get('clientId')
        .setValue(selectedClient.id);
      const selectedModule = this.data.modules.find(
        (c) =>
          c.id == this.data.device.moduleClientLocation.moduleClient.module.id
      );
      this.form
        .get('moduleClientLocation')
        .get('moduleId')
        .setValue(selectedModule.id);
      this.data.locations = await this.apiService.findAllLocation(
        this.form.get('moduleClientLocation').get('clientId').value,
        this.form.get('moduleClientLocation').get('moduleId').value,
        this.translate.currentLang
      );
      this.filteredLocations.next(this.data.locations.slice());
      this.locationFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterLocations();
        });
      const selectedLocation = this.data.locations.find(
        (c) => c.id == this.data.device.moduleClientLocation.location.id
      );
      this.form
        .get('moduleClientLocation')
        .get('locationId')
        .setValue(selectedLocation.id);
    }
  }

  public async findAllActiveModelBattery() {
    try {
      this.modelBatteries = await this.apiService.findAllActiveModelBattery(this.translate.currentLang);
    } catch (error) {
      this.logger.error(error);
      throw error;
    }
  }

  private selectedProducer() {
    if (this.data.device.model !== null && this.data.device.model !== undefined) {
      const selectedProducer = this.data.producers.find(c => c.id === this.data.device.model.producerId);
      this.form.get('producer').setValue(selectedProducer);
      this.form.get('producer').value.devicesModels.forEach(value => {
        this.models.push(value);
      });
      const selectedModel = this.models.find(c => c.id === this.data.device.model.id);
      this.form.get('model').setValue(selectedModel);
    }
  }

  private selectedReadType() {
    if (this.data.device.readType !== null && this.data.device.readType !== undefined) {
      const selectedReadType = this.data.readTypes.find(c => c.id === this.data.device.readType.id);
      this.form.get('readType').setValue(selectedReadType);
    }
  }

  private async selectedResponseTime() {
    if (
      this.data.device.moduleClientLocation !== undefined &&
      this.data.device.moduleClientLocation !== null &&
      this.data.device.moduleClientLocation.moduleClient !== undefined &&
      this.data.device.moduleClient !== null) {
      this.responseTimes = await this.apiService.findAllDeviceResponseTime(
        this.form.get('moduleClientLocation').get('clientId').value,
        this.translate.currentLang
      );
    }
    if (this.data.device.responseTime !== null && this.data.device.responseTime !== undefined) {
      const selectedResponseTime = this.responseTimes.find(c => c.id === this.data.device.responseTime.id);
      this.form.get('responseTime').setValue(selectedResponseTime);
    }
  }

  private async selectedCommandNumberPerPeriod() {
    if (
      this.data.device.moduleClientLocation !== undefined &&
      this.data.device.moduleClientLocation !== null &&
      this.data.device.moduleClientLocation.moduleClient !== undefined &&
      this.data.device.moduleClient !== null) {
      this.commandNumberPeriodTimes = await this.apiService.findAllDeviceCommandNumberPeriodTime(
        this.form.get('moduleClientLocation').get('clientId').value,
        this.translate.currentLang
      );
    }
    if (this.data.device.commandNumberPeriodTime !== null && this.data.device.commandNumberPeriodTime !== undefined) {
      const selectedCommandNumberPeriodTime = this.commandNumberPeriodTimes.find(c => c.id === this.data.device.commandNumberPeriodTime.id);
      this.form.get('commandNumberPeriodTime').setValue(selectedCommandNumberPeriodTime);
    }
  }
  private selectedTypePowerSupply() {
    if (this.device.typePowerSupply !== null && this.device.typePowerSupply !== undefined) {
      const selectedTypePowerSupply = this.data.typePowerSupplies.find(c => c.id === this.device.typePowerSupply.id);
      this.form.get('typePowerSupply').setValue(selectedTypePowerSupply);
    }
  }

  private async selectedBattery() {
    if (this.device?.battery?.modelType.model !== null && this.device?.battery?.modelType.model !== undefined) {
      const selectedModelBattery = this.data.modelBatteries.find(c => c.id === this.device.battery.modelType.model.id);
      this.form.get('battery').get('model').setValue(selectedModelBattery);
      if (selectedModelBattery !== null && selectedModelBattery !== undefined) {
        this.typeBatteries = await this.apiService.findAllTypeBattery(
          this.device.battery.modelType.model.id,
          this.translate.currentLang
        );
        this.filteredTypeBatteries.next(this.typeBatteries.slice());
        this.typeBatteryFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterTypeBatteries();
          });
        const selectedTypeBattery = this.typeBatteries?.find(c => c.id === this.device.battery.modelType.type.id);
        this.form.get('battery').get('type').setValue(selectedTypeBattery);
        const selectedProducerBattery = this.data?.producerBatteries?.find(c => c.id === this.device.battery.producer.id);
        this.form.get('battery').get('producer').setValue(selectedProducerBattery);
        if (selectedModelBattery !== null && selectedModelBattery !== undefined && selectedTypeBattery !== null && selectedTypeBattery !== undefined && selectedProducerBattery !== null && selectedProducerBattery !== undefined) {
          await this.findAllActiveBattery(selectedModelBattery, selectedTypeBattery, selectedProducerBattery);
          const selectedGuarantee = this.batteries?.find(c => c.id === this.device.battery.id);
          this.form.get('battery').get('guarantee').setValue(selectedGuarantee);
        }
      }
    }
  }

  private selectedStatus() {
    if (this.data.device.status !== null && this.data.device.status !== undefined) {
      const selectedStatus = this.statuses.find(c => c.toUpperCase() === this.data.device.status.toUpperCase());
      this.form.get('status').setValue(selectedStatus);
    }
  }

  onYesClick(): void {
    let data = {
      ...this.form.value,
    };
    this.onSubmit.emit(data);
  }

  public async changeClient() {
    try {
      this.uiService.setGlobalLoaderVisible(true);
      this.data.modules = [];
      this.data.locations = [];
      this.responseTimes = [];
      this.commandNumberPeriodTimes = [];
      this.form.get('moduleClientLocation').get('moduleId').setValue(null);
      this.form.get('moduleClientLocation').get('locationId').setValue(null);
      this.form.get('responseTime').setValue(null);
      this.form.get('commandNumberPeriodTime').setValue(null);
      if (
        this.form.get('moduleClientLocation').get('clientId').value !==
        undefined &&
        this.form.get('moduleClientLocation').get('clientId').value !== null
      ) {
        this.data.modules = await this.apiService.findAllModule(
          this.form.get('moduleClientLocation').get('clientId').value,
          this.translate.currentLang
        );
        this.responseTimes = await this.apiService.findAllDeviceResponseTime(
          this.form.get('moduleClientLocation').get('clientId').value,
          this.translate.currentLang
        );
        this.commandNumberPeriodTimes = await this.apiService.findAllDeviceCommandNumberPeriodTime(
          this.form.get('moduleClientLocation').get('clientId').value,
          this.translate.currentLang
        );
      }
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.uiService.setGlobalLoaderVisible(false);
    }
  }

  public async changeModule() {
    try {
      this.uiService.setGlobalLoaderVisible(true);
      this.data.locations = [];
      this.filteredLocations.next(this.data.locations.slice());
      this.locationFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterLocations();
        });
      this.form.get('moduleClientLocation').get('locationId').setValue(null);
      if (
        this.form.get('moduleClientLocation') !== null &&
        this.form.get('moduleClientLocation').get('clientId') !== null &&
        this.form.get('moduleClientLocation').get('clientId').value !== null &&
        this.form.get('moduleClientLocation') !== null &&
        this.form.get('moduleClientLocation').get('moduleId') !== null &&
        this.form.get('moduleClientLocation').get('moduleId').value !== null
      ) {
        this.data.locations = await this.apiService.findAllLocation(
          this.form.get('moduleClientLocation').get('clientId').value,
          this.form.get('moduleClientLocation').get('moduleId').value,
          this.translate.currentLang
        );
        this.filteredLocations.next(this.data.locations.slice());
        this.locationFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterLocations();
          });
      }
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.uiService.setGlobalLoaderVisible(false);
    }
  }

  public filterLocations() {
    try {
      if (!this.data.locations) {
        return;
      }
      let search = this.locationFilterCtrl.value;
      if (!search) {
        this.filteredLocations.next(this.data.locations.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      this.filteredLocations.next(
        this.data.locations.filter(
          (location) => location.name.toLowerCase().indexOf(search) > -1
        )
      );
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    }
  }

  public filterTypeBatteries() {
    try {
      if (!this.typeBatteries) {
        return;
      }
      let search = this.typeBatteryFilterCtrl.value;
      if (!search) {
        this.filteredTypeBatteries.next(this.typeBatteries.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      this.filteredTypeBatteries.next(
        this.typeBatteries.filter(
          (typeBattery) => typeBattery.name.toLowerCase().indexOf(search) > -1
        )
      );
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  public async changeProducer(event: any) {
    try {
      this.uiService.setGlobalLoaderVisible(true);
      this.models = [];
      this.form.get('model').setValue(null);
      this.form.get('sendCommand').setValue('false');
      if (event.value.devicesModels !== null && event.value.devicesModels !== undefined) {
        event.value.devicesModels.forEach(value => {
          this.models.push(value);
        });
      }
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.uiService.setGlobalLoaderVisible(false);
    }
  }

  public async changeModelBattery(event: any) {
    try {
      this.uiService.setGlobalLoaderVisible(true);
      this.typeBatteries = [];
      this.filteredTypeBatteries.next(this.typeBatteries.slice());
      this.typeBatteryFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterTypeBatteries();
        });
      this.form.get('battery').get('type').setValue(null);
      if (event.isUserInput) {
        if (event.source.selected) {
          if (this.form.get('battery') !== null && this.form.get('battery') !== undefined) {
            this.typeBatteries = await this.apiService.findAllTypeBattery(
              event.source.value.id,
              this.translate.currentLang
            );
            this.filteredTypeBatteries.next(this.typeBatteries.slice());
            this.typeBatteryFilterCtrl.valueChanges
              .pipe(takeUntil(this._onDestroy))
              .subscribe(() => {
                this.filterTypeBatteries();
              });

          }
        }
      }
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.uiService.setGlobalLoaderVisible(false);
    }
  }


  public async changeTypeBattery(event: any) {
    try {
      this.uiService.setGlobalLoaderVisible(true);
      this.batteries = [];
      this.form.get('battery').get('guarantee').setValue(null);
      if (event.isUserInput) {
        if (event.source.selected) {
          if (this.form.get('battery').get('model') !== null && this.form.get('battery').get('model') !== undefined &&
            this.form.get('battery').get('producer') !== null && this.form.get('battery').get('producer') !== undefined &&
            this.form.get('battery').get('model').value !== null && this.form.get('battery').get('model').value !== undefined &&
            this.form.get('battery').get('producer').value !== null && this.form.get('battery').get('producer').value !== undefined) {
            this.findAllActiveBattery(this.form.get('battery').get('model').value, event.source.value, this.form.get('battery').get('producer').value);
          }
        }
      }
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.uiService.setGlobalLoaderVisible(false);
    }
  }

  public async changeProducerBattery(event: any) {
    try {
      this.uiService.setGlobalLoaderVisible(true);
      this.batteries = [];
      this.form.get('battery').get('guarantee').setValue(null);
      if (event.isUserInput) {
        if (event.source.selected) {
          if (this.form.get('battery').get('model') !== null && this.form.get('battery').get('model') !== undefined &&
            this.form.get('battery').get('type') !== null && this.form.get('battery').get('type') !== undefined &&
            this.form.get('battery').get('model').value !== null && this.form.get('battery').get('model').value !== undefined &&
            this.form.get('battery').get('type').value !== null && this.form.get('battery').get('type').value !== undefined) {
            this.findAllActiveBattery(this.form.get('battery').get('model').value, this.form.get('battery').get('type').value, event.source.value);
          }
        }
      }
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.uiService.setGlobalLoaderVisible(false);
    }
  }



  get visibleSendCommand() {
    if (this.form.get('producer').value !== null && this.form.get('producer').value !== undefined && this.form.get('producer').value.name === ProducerName.BOSCH
      && this.form.get('model').value !== null && this.form.get('model').value !== undefined && this.form.get('model').value.name === ModelName.RAK7204) {
      return false;
    }
    if (this.form.get('producer').value !== null && this.form.get('producer').value !== undefined && this.form.get('producer').value.name === ProducerName.REMOTICOM
      && this.form.get('model').value !== null && this.form.get('model').value !== undefined && this.form.get('model').value.name === ModelName.ZSC160) {
      return true;
    }
    return false;
  }

  public async showImage(data: any) {
    try {
      this.isLoading = true;
      this.uiService.openDeviceImageModal(data);
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.isLoading = false;
    }
  }

  public async deleteImage(deviceImage: DeviceImageDTO) {
    try {
      this.isLoading = true;
      const message = await this.apiService.removeDeviceImage(deviceImage.deviceId, deviceImage.id, this.translate.currentLang);
      this.notifierService.show({
        type: 'success',
        message,
      });
      await this.findDeviceById(deviceImage.deviceId);
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.isLoading = false;
    }
  }

  public async onFileInputAddImage(data: any, changeEvent: { target: HTMLInputElement }) {
    try {
      this.isLoading = true;
      const file = changeEvent.target.files[0];
      if (!file) {
        // TODO: handle this
        return;
      }
      const message = await this.apiService.uploadDeviceImage(data.id, file, this.translate.currentLang);
      this.notifierService.show({
        type: 'success',
        message,
      });
      await this.findDeviceById(data.id);
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      changeEvent.target.value = null;
      this.isLoading = false;
    }
  }



  public async findAllActiveBattery(model: any, type: any, producer: any) {
    try {
      this.batteries = await this.apiService.findAllActiveBattery(model.id, type.id, producer.id, this.translate.currentLang);
    } catch (error) {
      this.logger.error(error);
      throw error;
    }
  }

  get visibleBattery() {
    if (this.form.get('typePowerSupply').value !== null && this.form.get('typePowerSupply').value !== undefined && this.form.get('typePowerSupply').value.name === TypePowerSypply.BATTERY) {
      return true;
    }
    return false;
  }

  public async changeTypePowerSupply(event: any) {
    try {
      this.uiService.setGlobalLoaderVisible(true);
      if (this.device.battery !== null && this.device.battery !== undefined && event.value?.name === TypePowerSypply.BATTERY) {
        await this.selectedBattery();
      }
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.uiService.setGlobalLoaderVisible(false);
    }
  }

  public changeNewBattery(event: any) {
    try {
      if (event.value === '0') {
        this.form.get('battery').get('model').disable();
        this.form.get('battery').get('type').disable();
        this.form.get('battery').get('producer').disable();
        this.form.get('battery').get('guarantee').disable();
      } else {
        this.form.get('battery').get('model').enable();
        this.form.get('battery').get('type').enable();
        this.form.get('battery').get('producer').enable();
        this.form.get('battery').get('guarantee').enable();
      }
    } catch (error) {
      this.logger.error(error);
    } finally {
    }
  }
}
