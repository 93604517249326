import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { LoggerService } from '../services/logger.service';
import { UiService } from '../services/ui.service';
import { UtilService } from '../services/util.service';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss']
})
export class SidenavListComponent implements OnInit {

  @Output() sidenavClose = new EventEmitter();

  constructor(private authService: AuthService,
    private utilService: UtilService,
    private logger: LoggerService,
    private notifierService: NotifierService,
    private apiService: ApiService,
    private translate: TranslateService,
    private uiService: UiService,
    private fb: FormBuilder,) {
  }

  get isAuthenticated() {
    return this.authService.isAuthenticated;
  }

  get userRole() {
    return this.authService.hasUSERRole();
  }

  get adminRole() {
    return this.authService.hasADMRole();
  }

  get supervisorRole() {
    return this.authService.hasUSERRole();
  }

  ngOnInit(): void {
  }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }
}
