import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UtilService } from '../services/util.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router, private utilService: UtilService) { }

  ngOnInit(): void {
  }

  get user() {
    return this.authService.loggedInUser;
  }

  public logout() {
    try{
      this.authService.logout();
      this.router.navigateByUrl('login');
    }catch(error){
      this.utilService.getErrorMessage(error);
    }
  }
}
