<h1 mat-dialog-title>{{ allData.data?.gateway.name }}</h1>
<div mat-dialog-content>
  <form [formGroup]="form" autocomplete="off">
    <div class="columns">
      <div class="column">
        <div class="row">
          <mat-form-field>
            <mat-label translate
              >GATEWAY.CORRECTIVE_MAINTENANCE.DIALOG.Problem type</mat-label
            >
            <mat-select
              cdkFocusInitial
              formControlName="problemTypeView"
              required
            >
              <mat-option
                *ngFor="let problemType of allData.problemTypes"
                [value]="problemType"
                >{{ "GATEWAY.CORRECTIVE_MAINTENANCE.PROBLEM_TYPE." + problemType.name | translate }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field>
            <mat-label translate>GATEWAY.CORRECTIVE_MAINTENANCE.DIALOG.Operation name</mat-label>
            <input matInput type="text" formControlName="operationName" required />
          </mat-form-field>
        </div>
      </div>
      <div class="column">
        <mat-form-field>
          <mat-label translate
            >GATEWAY.CORRECTIVE_MAINTENANCE.DIALOG.Comment</mat-label
          >
          <textarea formControlName="comment" matInput rows="5"></textarea>
          <mat-hint align="end">{{
            form.get("comment").value?.length || 0
            }}/5000</mat-hint>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button (click)="onNoClick()" color="accent">
    {{ "GATEWAY.CORRECTIVE_MAINTENANCE.DIALOG.Cancel" | translate }}
  </button>
  <button
    mat-raised-button
    (click)="onYesClick()"
    color="primary"
    [disabled]="form.invalid"
  >
    {{ "GATEWAY.CORRECTIVE_MAINTENANCE.DIALOG.Edit" | translate }}
  </button>
</div>
