import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { NotifierModule } from 'angular-notifier';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeSr from '@angular/common/locales/sr-Latn';
import localeSrExtra from '@angular/common/locales/extra/sr-Latn';

registerLocaleData(localeSr, localeSrExtra);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    AuthModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 40,
        },
        vertical: {
          position: 'bottom',
          distance: 40,
        },
      },
      behaviour: {
        autoHide:2500
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
  ],
  providers: [DatePipe, {
    provide: LOCALE_ID,
    useFactory: () => 'en' || navigator.language,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
