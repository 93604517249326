import {
  Component,
  EventEmitter,
  Inject,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { RestrictionProfileDTO } from '../../models/restriction/restriction-profile.model';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { LoggerService } from '../../services/logger.service';
import { UiService } from '../../services/ui.service';
import { UtilService } from '../../services/util.service';
import { DataTable } from '../../table/model/data-table.interface';

@Component({
  selector: 'app-dialog-view-restriction-for-device',
  templateUrl: './dialog-view-restriction-for-device.component.html',
  styleUrls: ['./dialog-view-restriction-for-device.component.scss'],
})
export class DialogViewRestrictionForDeviceComponent {
  tableData: DataTable;

  form: FormGroup = this.createForm({});

  @Output()
  public onSubmit = new EventEmitter<{}>();

  restrictionEditId: number;

  restrictionProfiles: RestrictionProfileDTO[];

  checked: boolean[] = new Array();

  constructor(
    public dialogRef: MatDialogRef<DialogViewRestrictionForDeviceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private fb: FormBuilder,
    private uiService: UiService,
    private apiService: ApiService,
    private logger: LoggerService,
    private notifierService: NotifierService,
    private utilService: UtilService,
    private authService: AuthService
  ) {
    this.prepareTableData(data.restrictionsProfilesForDevice);
    this.checkedValues();
  }

  get userRole() {
    return this.authService.hasUSERRole();
  }

  get adminRole() {
    return this.authService.hasADMRole();
  }

  get managerRole() {
    return this.authService.hasMANRole();
  }

  createForm(item): FormGroup {
    return this.fb.group({
      restriction: [
        { disabled: false, value: item.restriction || null },
        [Validators.required],
      ],
      restrictionProfile: [
        { disabled: false, value: item || null },
        [Validators.required],
      ],
      id: [
        { disabled: false, value: item.id || null },
        [],
      ],
      status: [
        { disabled: false, value: item.status || null },
        [],
      ],
      client: [
        { disabled: false, value: this.data.device?.moduleClientLocation.moduleClient.client || null },
        [Validators.required],
      ],
      notificationTime: [
        { disabled: false, value: item.notificationTime  || null },
        [Validators.min(1)]
      ],
    });
  }

  public checkedValues() {
    if (this.data.restrictionsProfilesForDevice !== null && this.data.restrictionsProfilesForDevice !== undefined && this.data.restrictionsProfilesForDevice.length > 0) {
      this.data.restrictionsProfilesForDevice.forEach(element => {
        if (element.status === 'ACTIVE') {
          this.checked.push(true);
        }
        if (element.status === 'INACTIVE') {
          this.checked.push(false);
        }
      });
    }
  }

  prepareTableData(data) {
    if (this.authService.hasADMRole()) {
      this.tableData = {
        displayedColumns: [
          {
            header: 'RESTRICTION.TABLE.Client',
            value: 'restrictionProfile.client.name',
            checkBox: false,
            translate: false,
          },
          {
            header: 'RESTRICTION.TABLE.Profile name',
            value: 'restrictionProfile.name',
            checkBox: false,
            translate: false,
          },
          {
            header: 'RESTRICTION.TABLE.Min',
            value: 'restrictionProfile.min',
            checkBox: false,
            translate: false,
          },
          {
            header: 'RESTRICTION.TABLE.Middle max',
            value: 'restrictionProfile.middleMax',
            checkBox: false,
            translate: false,
          },
          {
            header: 'RESTRICTION.TABLE.Max',
            value: 'restrictionProfile.max',
            checkBox: false,
            translate: false,
          },
          {
            header: 'RESTRICTION.TABLE.Name',
            value: 'restrictionProfile.restriction.name',
            checkBox: false,
            translate: true,
          },
          { header: 'RESTRICTION.TABLE.Notification time',
            value: "notificationTime",
            checkBox: false,
            translate: false
          },
          {
            header: 'RESTRICTION.TABLE.Exceptions',
            value: "exceptions",
            checkBox: false,
            translate: true,
            array: true,
            dialog: true,
          },
        ],
        data: data,
        actions: [{ id: 2, name: 'delete', iconClass: 'delete' }],
        slider: { name: 'RESTRICTION.TABLE.Status rpd' },
      };
    } else if (this.authService.hasMANRole()) {
      this.tableData = {
        displayedColumns: [
          {
            header: 'RESTRICTION.TABLE.Profile name',
            value: 'restrictionProfile.name',
            checkBox: false,
            translate: false,
          },
          {
            header: 'RESTRICTION.TABLE.Min',
            value: 'restrictionProfile.min',
            checkBox: false,
            translate: false,
          },
          {
            header: 'RESTRICTION.TABLE.Middle max',
            value: 'restrictionProfile.middleMax',
            checkBox: false,
            translate: false,
          },
          {
            header: 'RESTRICTION.TABLE.Max',
            value: 'restrictionProfile.max',
            checkBox: false,
            translate: false,
          },
          {
            header: 'RESTRICTION.TABLE.Name',
            value: 'restrictionProfile.restriction.name',
            checkBox: false,
            translate: true,
          },
          { header: 'RESTRICTION.TABLE.Notification time',
            value: "notificationTime",
            checkBox: false,
            translate: false
          },
          {
            header: 'RESTRICTION.TABLE.Exceptions',
            value: "exceptions",
            checkBox: false,
            translate: true,
            array: true,
            dialog: true,
          },
        ],
        data: data,
        actions: [{ id: 2, name: 'delete', iconClass: 'delete' }],
        slider: { name: 'RESTRICTION.TABLE.Status rpd' },
      };
    }
  }
  performSlide(slide: any) {
    if (slide.check === false) {
      this.tableData.data[slide.elementId].status = "ACTIVE";
    } else {
      this.tableData.data[slide.elementId].status = "INACTIVE";
    }
    this.prepareTableData(this.tableData.data);
  }

  performAction(action: any) {
    switch (action.name) {
      case 'edit': {
        this.prepareEdit(action.elementId);
        break;
      }
      case 'delete': {
        this.delete(action.elementId);
        break;
      }
    }
  }

  prepareEdit(id: number) {
    const item = this.tableData.data[id];
    this.form = this.createForm(item);
    this.restrictionEditId = id;
  }

  delete(id: number) {
    this.tableData.data.splice(id, 1);
    this.prepareTableData(this.tableData.data);
    this.restrictionEditId = null;
  }

  addItem(item) {
    if (!this.tableData) {
      this.prepareTableData([]);
    }
    if (
      this.restrictionEditId !== null &&
      this.restrictionEditId !== undefined
    ) {
      this.tableData.data.splice(this.restrictionEditId, 1, item);
      this.prepareTableData(this.tableData.data);
      this.restrictionEditId = null;
    } else {
      this.prepareTableData(this.tableData.data.concat(item));
    }
  }

  cancel() {
    this.form = this.createForm({});
    this.restrictionEditId = null;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    this.onSubmit.emit(this.tableData.data);
  }

  public async changeRestrictionType(event: any) {
    try {
      this.restrictionProfiles = [];
      this.uiService.setGlobalLoaderVisible(true);
      if (event.isUserInput) {
        if (event.source.selected && this.form.get('client') !== null && this.form.get('client').value !== null && this.form.get('client').value.id !== null) {
          this.restrictionProfiles = await this.apiService.findAllProfileForRestriction(
            event.source.value.id,
            this.form.get('client').value.id,
            this.translate.currentLang
          );
        }
      }
      this.form.get('restrictionProfile').setValue(null);
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.uiService.setGlobalLoaderVisible(false);
    }
  }

  public async changeClient(event: any) {
    try {
      this.restrictionProfiles = [];
      this.uiService.setGlobalLoaderVisible(true);
      if (event.isUserInput) {
        if (event.source.selected && this.form.get('restriction') !== null && this.form.get('restriction').value !== null && this.form.get('restriction').value.id !== null) {
          this.restrictionProfiles = await this.apiService.findAllProfileForRestriction(
            this.form.get('restriction').value.id,
            event.source.value.id,
            this.translate.currentLang
          );
        }
      }
      this.form.get('restrictionProfile').setValue(null);
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.uiService.setGlobalLoaderVisible(false);
    }
  }
}
