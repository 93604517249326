
<div mat-dialog-content>
  <div fxLayoutAlign="space-around center" fxLayoutGap="5px" fxLayout="row wrap" class="general">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{
          'NOTIFICATION.JOB.Title' | translate
        }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="form" autocomplete="off">
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>NOTIFICATION.JOB.Client</mat-label>
              <mat-select formControlName="client" required>
                <mat-option *ngFor="let client of clients" [value]="client">{{
                  client.name
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>NOTIFICATION.JOB.Module</mat-label>
              <mat-select formControlName="module" required>
                <mat-option *ngFor="let module of modules" [value]="module">{{
                  'MODULE.' + module.name | translate
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>NOTIFICATION.JOB.Name</mat-label>
              <input matInput formControlName="name" required />
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>NOTIFICATION.MODULE_CLIENT.Status</mat-label>
              <mat-select formControlName="status" required>
                <mat-option *ngFor="let status of statuses" [value]="status">{{
                  'NOTIFICATION.JOB.' + status | translate
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field class="example-chip-list">
              <mat-label translate>NOTIFICATION.JOB.Emails</mat-label>
              <mat-chip-list #chipList aria-label="Emails selection">
                <mat-chip *ngFor="let email of emails" (removed)="remove(email)">
                  {{ email }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
                <input [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="add($event)" #emailsInput formControlName="emails" />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-checkbox [(ngModel)]="checkSms" [ngModelOptions]="{ standalone: true }"
            (change)="toggle()" class="check-detail">{{ 'NOTIFICATION.JOB.Sms?' | translate }}</mat-checkbox>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center" *ngIf="sms">
            <mat-form-field class="example-chip-list">
              <mat-label translate>NOTIFICATION.JOB.Phones</mat-label>
              <mat-chip-list #chipListPhones aria-label="Phones selection">
                <mat-chip *ngFor="let phone of phones" (removed)="removePhone(phone)">
                  {{ phone }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
                <input [matChipInputFor]="chipListPhones" prefix="+" [dropSpecialCharacters]="false" mask="000000000000"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodesPhone" [matChipInputAddOnBlur]="addOnBlurPhone"
                  (matChipInputTokenEnd)="addPhone($event)" #phonesInput formControlName="phones" />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>NOTIFICATION.JOB.Users</mat-label>
              <mat-select #select formControlName="users" multiple>
                <mat-option>
                  <ngx-mat-select-search [formControl]="userFilterCtrl" placeholderLabel="{{'NOTIFICATION.JOB.User placeholder' | translate}}" noEntriesFoundLabel="{{'NOTIFICATION.JOB.User not found' | translate}}"></ngx-mat-select-search>
                </mat-option>
                <div class="select-all" *ngIf="users?.length > 0">
                  <mat-checkbox
                    [(ngModel)]="allSelected"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="toggleAllSelection()"
                    >{{ "COMMON.Choose all" | translate }}</mat-checkbox
                  >
                </div>
                <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
                  {{ user.firstName }} {{user.lastName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>NOTIFICATION.JOB.Skeletons</mat-label>
              <mat-select #selects formControlName="skeletons" required multiple>
                <div class="select-all">
                  <mat-checkbox
                    [(ngModel)]="allSelectedS"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="toggleAllSelectionS()"
                    >{{ "COMMON.Choose all" | translate }}</mat-checkbox
                  >
                </div>
                <mat-option *ngFor="let skeleton of skeletons" [value]="skeleton">{{
                  skeleton.name
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>NOTIFICATION.JOB.Description</mat-label>
              <textarea matInput formControlName="description"></textarea>
              <mat-hint align="end">{{
                form.get("description").value?.length || 0
                }}/500</mat-hint>
            </mat-form-field>
          </div>
        </form>
        <div fxLayoutAlign="center center" fxLayoutGap="5px" fxLayout="row wrap">
          <button mat-raised-button (click)="onNoClick()" color="accent">{{'NOTIFICATION.JOB.Close' | translate}}</button>
          <button mat-raised-button (click)="onYesClick()" color="primary" [disabled]="form.invalid">{{'NOTIFICATION.JOB.Edit' | translate}}</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

</div>

