
<div mat-dialog-content>
  <div fxLayoutAlign="space-around center" fxLayoutGap="5px" fxLayout="row wrap" class="general">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{
          'GATEWAY.Title' | translate
        }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="form" autocomplete="off">
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>GATEWAY.Id</mat-label>
              <input matInput type="text" formControlName="originalId" required />
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>GATEWAY.Name</mat-label>
              <input matInput type="text" formControlName="name" required />
            </mat-form-field>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>GATEWAY.Latitude</mat-label>
              <input matInput type="number" formControlName="latitude" />
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>GATEWAY.Longitude</mat-label>
              <input matInput type="number" formControlName="longitude" />
            </mat-form-field>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>GATEWAY.Location</mat-label>
              <mat-select formControlName="location">
                <mat-option>
                  <ngx-mat-select-search [formControl]="locationFilterCtrl" placeholderLabel="{{'GATEWAY.Location placeholder' | translate}}" noEntriesFoundLabel="{{'GATEWAY.Location not found' | translate}}"></ngx-mat-select-search>
                </mat-option>
                <mat-option [value]="null"></mat-option>
                <mat-option *ngFor="let location of filteredLocations | async" [value]="location">{{
                  location.name
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>GATEWAY.Status</mat-label>
              <mat-select formControlName="status" required>
                <mat-option *ngFor="let status of statuses" [value]="status">{{
                  'GATEWAY.' + status | translate
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>GATEWAY.Description</mat-label>
              <textarea matInput formControlName="description"></textarea>
              <mat-hint align="end">{{
                form.get("description").value?.length || 0
                }}/500</mat-hint>
            </mat-form-field>
          </div>
          <div
          fxLayout="row wrap"
          fxLayoutAlign="space-around center"
          *ngIf="gateway && gateway.images"
        >
          <div fxLayout="column" *ngFor="let image of gateway.images">
            <img
              [src]="image | gatewayImage"
              default="assets/img/default-avatar.svg"
              class="avatar"
              (click)="showImage(image)"
            />
            <div fxLayoutAlign="space-around center">
              <mat-icon (click)="deleteImage(image)" #tooltip="matTooltip" matTooltip="{{'GATEWAY.Remove image' | translate}}">delete</mat-icon>
            </div>
          </div>
        </div>
          <div fxLayout="column" fxLayoutAlign="center center" *ngIf="gateway && gateway.images !== null && gateway.images !== undefined && gateway.images.length < gatewayImageCount">
            <input
            #fileInput2
            (change)="onFileInputAddImage(gateway, $event)"
            type="file"
            accept="image/*"
            hidden
          />
            <mat-icon #tooltip="matTooltip" matTooltip="{{'GATEWAY.Add image' | translate}}" *ngIf="gateway.images.length > 0"
            (click)="fileInput2.click()"
          >attach_file</mat-icon>
          <button *ngIf="gateway.images.length === 0"
                mat-raised-button
                (click)="fileInput2.click()"
                color="primary"
              >
              <mat-icon>attach_file</mat-icon>
                {{ "GATEWAY.Add image" | translate }}
          </button>
          </div>
        </form>
        <div fxLayoutAlign="center center" fxLayoutGap="5px" fxLayout="row wrap" class="detail">
          <button mat-raised-button (click)="onNoClick()" color="accent">{{'GATEWAY.Close' | translate}}</button>
          <button mat-raised-button (click)="onYesClick()" color="primary" [disabled]="form.invalid">{{'GATEWAY.Edit' | translate}}</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

</div>

