<div mat-dialog-content>
  <div fxLayoutAlign="space-around center" fxLayoutGap="5px" fxLayout="row wrap" class="general">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{
          'RESTRICTION.Title' | translate
          }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="form" autocomplete="off">
          <div fxLayoutAlign="space-around center" fxLayoutGap="5px" fxLayout="row wrap">
            <mat-form-field>
              <mat-label translate>RESTRICTION.Type</mat-label>
              <mat-select formControlName="restriction" required>
                <mat-option *ngFor="let restriction of data.restrictions" [value]="restriction">{{
                  'RESTRICTION.' + restriction.name | translate
                  }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label translate>RESTRICTION.Name</mat-label>
              <input matInput type="text" formControlName="name" required />
            </mat-form-field>
          </div>

          <mat-divider [vertical]="true"></mat-divider>

          <div fxLayoutAlign="space-around center" fxLayoutGap="5px" fxLayout="row wrap">
            <mat-form-field>
              <mat-label translate>RESTRICTION.Min</mat-label>
              <input matInput type="number" formControlName="min" required />
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>RESTRICTION.Middle max</mat-label>
              <input matInput type="number" formControlName="middleMax" />
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>RESTRICTION.Max</mat-label>
              <input matInput type="number" formControlName="max" required />
            </mat-form-field>
          </div>

          <mat-divider [vertical]="true"></mat-divider>

          <div fxLayoutAlign="space-around center" fxLayoutGap="5px" fxLayout="row wrap">
            <mat-form-field *ngIf="adminRole" required>
              <mat-label translate>RESTRICTION.Client</mat-label>
              <mat-select formControlName="client">
                <mat-option [value]="null"></mat-option>
                <mat-option *ngFor="let client of data.clients" [value]="client">{{
                  client.name
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>RESTRICTION.Description</mat-label>
              <input matInput type="text" formControlName="description" />
            </mat-form-field>
          </div>
        </form>
        <div fxLayoutAlign="center center" fxLayoutGap="5px" fxLayout="row wrap">
          <button mat-raised-button (click)="onNoClick()" color="accent">{{'RESTRICTION.Close' | translate}}</button>
          <button mat-raised-button (click)="onYesClick()" color="primary"
            [disabled]="form.invalid">{{'RESTRICTION.Edit' | translate}}</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
