<div *ngIf="isLoading" class="loading-overlay">
  <mat-spinner></mat-spinner>
</div>
<div mat-dialog-content>
  <div fxLayoutAlign="space-around center" fxLayoutGap="5px" fxLayout="row wrap" class="general">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{
          'COMMON.Devices for section' | translate
        }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-list>
          <mat-list-item *ngFor="let device of devices">
            {{ device.name }} - {{device.moduleClientLocation.location.name}}
            <mat-divider></mat-divider>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>
</div>

