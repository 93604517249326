import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dialog-edit-client',
  templateUrl: './dialog-edit-client.component.html',
  styleUrls: ['./dialog-edit-client.component.scss']
})
export class DialogEditClientComponent {

  statuses: string[] = ['active', 'inactive'];
  form: FormGroup = this.createForm({});

  @Output()
  public onSubmit = new EventEmitter<{}>();

  constructor(public dialogRef: MatDialogRef<DialogEditClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private translate: TranslateService, private fb: FormBuilder) {
    this.form = this.createForm(data.client);
    this.selectedStatus();
  }

  private selectedStatus() {
    if (this.data.client.status !== null && this.data.client.status !== undefined) {
      const selectedStatus = this.statuses.find(c => c.toUpperCase() === this.data.client.status.toUpperCase());
      this.form.get('status').setValue(selectedStatus);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private createForm(data) {
    if (JSON.stringify(data) === '{}') {
      return this.fb.group({
        originalId: [{ disabled: false, value: '' }, [Validators.required]],
        name: [{ disabled: false, value: '' }, [Validators.required]],
        status: [{ disabled: false, value: '' }, [Validators.required]],
        latitude: [{ disabled: false, value: null }],
        longitude: [{ disabled: false, value: null }],
      });
    } else {
      return this.fb.group({
        originalId: [{ disabled: false, value: data.originalId }, [Validators.required]],
        name: [{ disabled: false, value: data.name }, [Validators.required]],
        status: [{ disabled: false, value: data.status }, [Validators.required]],
        latitude: [{ disabled: false, value: data.latitude }],
        longitude: [{ disabled: false, value: data.longitude }],
      });
    }
  }

  onYesClick(): void {
    let data = {
      ...this.form.value,
    }
    this.onSubmit.emit(data);
  }
}
