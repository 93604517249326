import { RoleDTO } from '../role/role.model';

export enum UserRole {
  ADM = 'ADM',
  USER = 'USER',
  MAN = 'MAN'
}

export enum RestrictionEnum {
  AIR = 'AIR',
  TEMPERATURE = 'TEMPERATURE',
  PRESSURE = 'PRESSURE',
  HUMIDITY = 'HUMIDITY',
  VOLTAGE = 'VOLTAGE'
}

export enum ModuleEnum {
  SS = 'SS',
  SL = 'SL',
  SWM = 'SWM',
  SEM = 'SEM'
}

export enum DeviceStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum DeviceSubStatus {
  TEMPORARILY_DEACTIVATED = 'TEMPORARILY_DEACTIVATED',
  GLITCH = 'GLITCH',
  FUNCTIONAL = 'FUNCTIONAL',
}

export enum GatewayStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum ProducerName {
  BOSCH = 'BOSCH',
  REMOTICOM = 'REMOTICOM'
}

export enum TypePowerSypply {
  BATTERY = 'BATTERY',
}

export enum DeviceProblemType {
  BAD_BATTERY = 'BAD_BATTERY',
}

export enum ModelName {
  RAK7204 = 'RAK7204',
  ZSC160 = 'ZSC160'
}

export enum SectionDeviceStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum SectionModuleClientLocationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface UserDTO {
  id: number;
  firstName: string;
  lastName: string;
  username:string;
  roles: UserRole[];
  rolesObject: RoleDTO[];
  status: string;
  modules: ModuleEnum[];
  email: string;
}
