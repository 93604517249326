<div *ngIf="isLoading" class="loading-overlay">
  <mat-spinner></mat-spinner>
</div>
<div mat-dialog-content>
  <div
    fxLayoutAlign="space-around center"
    fxLayoutGap="5px"
    fxLayout="row wrap"
    class="general"
  >
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{ "DEVICE.Title" | translate }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="form" autocomplete="off">
          <div
            fxLayoutAlign="space-around center"
            fxLayoutGap="5px"
            fxLayout="row wrap"
          >
            <mat-form-field *ngIf="adminRole">
              <mat-label translate>DEVICE.EUI</mat-label>
              <input matInput formControlName="eui" required />
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>DEVICE.Name</mat-label>
              <input matInput formControlName="name" required />
            </mat-form-field>
            <mat-form-field *ngIf="adminRole">
              <mat-label translate>DEVICE.Original name</mat-label>
              <input matInput formControlName="originalName" required />
            </mat-form-field>
          </div>

          <div
            fxLayout="row wrap"
            fxLayoutAlign="space-around center"
            *ngIf="adminRole"
          >
            <mat-form-field>
              <mat-label translate>DEVICE.Latitude</mat-label>
              <input matInput type="number" formControlName="latitude" />
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>DEVICE.Longitude</mat-label>
              <input matInput type="number" formControlName="longitude" />
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>DEVICE.Addr</mat-label>
              <input matInput formControlName="devAddr" />
            </mat-form-field>
          </div>

          <div
            fxLayout="row wrap"
            fxLayoutAlign="space-around center"
            formGroupName="moduleClientLocation"
            *ngIf="adminRole"
          >
            <mat-form-field>
              <mat-label translate>DEVICE.Client</mat-label>
              <mat-select
                formControlName="clientId"
                (selectionChange)="changeClient()"
              >
                <mat-option [value]="null"></mat-option>
                <mat-option
                  *ngFor="let client of data.clients"
                  [value]="client.id"
                  >{{ client.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>DEVICE.Module</mat-label>
              <mat-select
                formControlName="moduleId"
                (selectionChange)="changeModule()"
              >
                <mat-option [value]="null"></mat-option>
                <mat-option
                  *ngFor="let module of data.modules"
                  [value]="module.id"
                  >{{ "MODULE." + module.name | translate }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>DEVICE.Location</mat-label>
              <mat-select formControlName="locationId">
                <mat-option>
                  <ngx-mat-select-search
                    [formControl]="locationFilterCtrl"
                    placeholderLabel="{{
                      'DEVICE.Location placeholder' | translate
                    }}"
                    noEntriesFoundLabel="{{
                      'DEVICE.Location not found' | translate
                    }}"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option [value]="null"></mat-option>
                <mat-option
                  *ngFor="let location of filteredLocations | async"
                  [value]="location.id"
                  >{{ location.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div
            fxLayout="row wrap"
            fxLayoutAlign="space-around center"
            *ngIf="adminRole"
          >
            <mat-form-field>
              <mat-label translate>DEVICE.Producer</mat-label>
              <mat-select
                formControlName="producer"
                (selectionChange)="changeProducer($event)"
                required
              >
                <mat-option
                  *ngFor="let producer of data.producers"
                  [value]="producer"
                  >{{ producer.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>DEVICE.Model</mat-label>
              <mat-select formControlName="model" required>
                <mat-option *ngFor="let model of models" [value]="model">{{
                  model.name
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>DEVICE.Read type</mat-label>
              <mat-select formControlName="readType">
                <mat-option
                  *ngFor="let readType of data.readTypes"
                  [value]="readType"
                  >{{ "DEVICE." + readType.name | translate }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div
            fxLayout="row wrap"
            fxLayoutAlign="space-around center"
            *ngIf="adminRole"
          >
            <mat-form-field>
              <mat-label translate>DEVICE.Status</mat-label>
              <mat-select formControlName="status" required>
                <mat-option *ngFor="let status of statuses" [value]="status">{{
                  "DEVICE." + status | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>DEVICE.Description</mat-label>
              <textarea matInput formControlName="description"></textarea>
              <mat-hint align="end"
                >{{ form.get("description").value?.length || 0 }}/500</mat-hint
              >
            </mat-form-field>
          </div>

          <div
            fxLayout="row wrap"
            fxLayoutAlign="space-around center"
            *ngIf="adminRole"
          >
            <mat-form-field>
              <mat-label translate>DEVICE.Response time</mat-label>
              <mat-select formControlName="responseTime">
                <mat-option
                  *ngFor="let responseTime of responseTimes"
                  [value]="responseTime"
                  >{{ responseTime.timeInSecond }}
                  {{ "DEVICE.Seconds" | translate }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>DEVICE.Command number per period</mat-label>
              <mat-select formControlName="commandNumberPeriodTime">
                <mat-option
                  *ngFor="
                    let commandNumberPeriodTime of commandNumberPeriodTimes
                  "
                  [value]="commandNumberPeriodTime"
                >
                  {{ commandNumberPeriodTime.numberDetail }} -
                  {{ commandNumberPeriodTime.timeInSecond }}
                  {{ "DEVICE.Seconds" | translate }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label translate>DEVICE.TYPE_POWER_SUPPLY.Title</mat-label>
              <mat-select formControlName="typePowerSupply" (selectionChange)="changeTypePowerSupply($event)">
                <mat-option [value]="null"></mat-option>
                <mat-option
                  *ngFor="let typePowerSupply of data.typePowerSupplies"
                  [value]="typePowerSupply"
                  >
                  {{  "DEVICE.TYPE_POWER_SUPPLY." +
                  typePowerSupply?.name | translate }}
                  </mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center" *ngIf="form.get('typePowerSupply').value?.name === 'BATTERY' && device?.battery !== null && device?.battery !== undefined">
            <mat-radio-group formControlName="newBattery" (change)="changeNewBattery($event)">
              <div fxLayout="row wrap" fxLayoutAlign="space-around center">
                <mat-radio-button value="0">{{'DEVICE.BATTERY.Old' | translate}}</mat-radio-button>
                <mat-radio-button value="1">{{'DEVICE.BATTERY.New' | translate}}</mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
          <div *ngIf="visibleBattery">
            <div
            fxLayout="row wrap"
            fxLayoutAlign="space-around center"
            formGroupName="battery"
          >
            <mat-form-field>
              <mat-label translate>DEVICE.BATTERY.Model</mat-label>
              <mat-select formControlName="model">
                <mat-option [value]="model"></mat-option>
                <mat-option (onSelectionChange)="changeModelBattery($event)"
                  *ngFor="let model of data.modelBatteries"
                  [value]="model"
                  >{{ model.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>DEVICE.BATTERY.Type</mat-label>
              <mat-select formControlName="type">
                <mat-option [value]="typeBattery"></mat-option>
                <mat-option (onSelectionChange)="changeTypeBattery($event)"
                  *ngFor="let type of typeBatteries"
                  [value]="type"
                  >{{ "DEVICE.BATTERY.TYPE." + type.name | translate }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div
              fxLayout="row wrap"
              fxLayoutAlign="space-around center"
              formGroupName="battery"
            >
              <mat-form-field>
                <mat-label translate>DEVICE.BATTERY.Producer</mat-label>
                <mat-select formControlName="producer">
                  <mat-option [value]="null"></mat-option>
                  <mat-option (onSelectionChange)="changeProducerBattery($event)"
                    *ngFor="let producer of data.producerBatteries"
                    [value]="producer"
                    >{{ producer.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label translate>DEVICE.BATTERY.Guarantee</mat-label>
                <mat-select formControlName="guarantee">
                  <mat-option [value]="null"></mat-option>
                  <mat-option
                    *ngFor="let battery of batteries"
                    [value]="battery"
                    >{{ battery?.guarantee }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="visibleSendCommand">
            <div
              fxLayout="row wrap"
              fxLayoutAlign="space-around center"
              *ngIf="adminRole"
            >
              <label class="command">{{
                "DEVICE.Send command" | translate
              }}</label>
            </div>
            <div
              fxLayout="row wrap"
              fxLayoutAlign="space-around center"
              *ngIf="adminRole"
            >
              <mat-radio-group formControlName="sendCommand">
                <mat-radio-button value="false">{{
                  "DEVICE.Send command no" | translate
                }}</mat-radio-button>
                <mat-radio-button value="true">{{
                  "DEVICE.Send command yes" | translate
                }}</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div
            fxLayout="row wrap"
            fxLayoutAlign="space-around center"
            *ngIf="device && device.images"
          >
            <div fxLayout="column" *ngFor="let image of device.images">
              <img
                [src]="image | deviceImage"
                default="assets/img/default-avatar.svg"
                class="avatar"
                (click)="showImage(image)"
              />
              <div fxLayoutAlign="space-around center">
                <mat-icon
                  (click)="deleteImage(image)"
                  #tooltip="matTooltip"
                  matTooltip="{{ 'DEVICE.Remove image' | translate }}"
                  >delete</mat-icon
                >
              </div>
            </div>
          </div>
          <div
            fxLayout="column"
            fxLayoutAlign="center center"
            *ngIf="
              device &&
              device.images !== null &&
              device.images !== undefined &&
              device.images.length < deviceImageCount
            "
          >
            <input
              #fileInput2
              (change)="onFileInputAddImage(device, $event)"
              type="file"
              accept="image/*"
              hidden
            />
            <mat-icon
              #tooltip="matTooltip"
              matTooltip="{{ 'DEVICE.Add image' | translate }}"
              *ngIf="device.images.length > 0"
              (click)="fileInput2.click()"
              >attach_file</mat-icon
            >
            <button
              *ngIf="device.images.length === 0"
              mat-raised-button
              (click)="fileInput2.click()"
              color="primary"
            >
              <mat-icon>attach_file</mat-icon>
              {{ "DEVICE.Add image" | translate }}
            </button>
          </div>
        </form>
        <div
          fxLayoutAlign="center center"
          fxLayoutGap="5px"
          fxLayout="row wrap"
          class="detail"
        >
          <button mat-raised-button (click)="onNoClick()" color="accent">
            {{ "DEVICE.Close" | translate }}
          </button>
          <button
            mat-raised-button
            (click)="onYesClick()"
            color="primary"
            [disabled]="form.invalid"
          >
            {{ "DEVICE.Edit" | translate }}
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
