import { Component, OnInit, Input, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DataTable } from '../model/data-table.interface';
import { MatSort } from '@angular/material/sort';
import { UiService } from '../../services/ui.service';
import { LoggerService } from '../../services/logger.service';
import { NotifierService } from 'angular-notifier';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-table-item',
  templateUrl: './table-item.component.html',
  styleUrls: ['./table-item.component.scss']
})
export class TableItemComponent implements OnInit, OnChanges {

  @Input() new: boolean;
  @Input() tableData: DataTable;
  @Input() pageIndex: number = 0;
  @Input() pageSizeOptions: number[];
  @Input() pageSize: number;
  @Input() length: number;
  //@Input() paging: boolean = false;
  //@Input() sorting: boolean = false;
  displayedColumns: string[];
  dataSource: MatTableDataSource<any>;
  @Input()
  checked: boolean[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // kod ovakvog sorta mogu da stavim 3 nacina: 1. [mat-sort-header] = "column.value" i @ViewChild(MatSort, {static: false}) sort: MatSort i moze da stoji <table *ngIf="tableData">, 2. izbaciti iz <table *ngIf="tableData"> i staviti @ViewChild(MatSort, {static: true}) sort: MatSort i moze i ne mora da ima [mat-sort-header] = "column.value", 3. @ViewChild(MatSort, {static: false}) sort: MatSort i onda moze da stoji <table *ngIf="tableData"> i moze i ne mora [mat-sort-header] = "column.value"
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Output() action = new EventEmitter<any>();
  @Output() slide = new EventEmitter<any>();
  @Output() page = new EventEmitter<any>();
  @Output() sortChange = new EventEmitter<any>();
  @Output() operation = new EventEmitter<any>();
  @Output() checkAll = new EventEmitter<any>();

  constructor(private uiService: UiService,
    private logger: LoggerService,
    private notifierService: NotifierService,
    private utilService: UtilService,
  ) { }

  ngOnInit() {
    if (this.tableData) {
      this.prepareDataForTable();
    }
  }

  ngOnChanges() {
    if (this.tableData) {
      this.updateDataInTable();
    }
  }

  public async sortChanged(event) {
    this.sortChange.emit(event);
  }

  prepareDataForTable() {
    this.displayedColumns = this.tableData.displayedColumns.map((item) => {
      return item.value;
    });
    if (this.tableData.slider) {
      this.displayedColumns.push("slider");
    }
    if (this.tableData.actions) {
      this.displayedColumns.push("actions");
    }
    this.dataSource = new MatTableDataSource<any>(this.tableData.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = (data, filter: string) => {
      const accumulator = (currentTerm, key) => {
        return this.nestedFilterCheck(currentTerm, data, key);
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
    this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
    this.dataSource.sort = this.sort;

  }

  updateDataInTable() {
    this.dataSource = new MatTableDataSource<any>(this.tableData.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = (data, filter: string) => {
      const accumulator = (currentTerm, key) => {
        return this.nestedFilterCheck(currentTerm, data, key);
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
    this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
    this.dataSource.sort = this.sort;
  }

  performAction(actionName: string, elementId: any) {
    this.action.emit({ name: actionName, elementId: elementId });
  }

  performSlide(check: any, elementId: any) {
    this.slide.emit({ check: check, elementId: elementId });
  }

  pageChanged(event) {
    this.page.emit(event);
  }

  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
  )

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  nestedFilterCheck(search, data, key) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }

  changeCheckAll(checked: boolean) {
    this.checkAll.emit(checked);
  }

  changeOperationStatus(checked: boolean, elementId: number) {
    this.operation.emit({ checked: checked, elementId: elementId });
  }

  public async editException(element: any) {
    try {
      this.uiService.setGlobalLoaderVisible(true);
      let ex = [];
      if (element.exceptions !== null && element.exceptions !== undefined && element.exceptions.length !== 0) {
        element.exceptions.forEach(element => {
          ex.push(element);
        });
      }
      let dataView = {
        exceptions: ex,
        rp: element.restrictionProfile,
        new: this.new
      };
      this.uiService.openViewRestrictionExceptionForDeviceModal(dataView, async (data, dialogRef) => {
        try {
          this.uiService.setGlobalLoaderVisible(true);
          element.exceptions = [];
          if (data !== null && data !== undefined && data.length !== 0) {
            data.forEach(exception => {
              element.exceptions.push(exception);
            });
          }
          dialogRef.close();
        } catch (error) {
          this.logger.error(error);
          this.notifierService.show({
            type: 'error',
            message: this.utilService.getErrorMessage(error),
          });
        } finally {
          this.uiService.setGlobalLoaderVisible(false);
        }
      });
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.uiService.setGlobalLoaderVisible(false);
    }
  }
}
