import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CorrectiveMaintenanceGatewayDTO } from '../../models/gateway/corrective-maintenance/corrective-maintenance-gateway.model';

@Component({
  selector: 'app-dialog-close-corrective-maintenance-gateway',
  templateUrl: './dialog-close-corrective-maintenance-gateway.component.html',
  styleUrls: ['./dialog-close-corrective-maintenance-gateway.component.scss']
})
export class DialogCloseCorrectiveMaintenanceGatewayComponent implements OnInit {

  form: FormGroup = this.createForm({});
  correctiveMaintenance: CorrectiveMaintenanceGatewayDTO;

  @Output()
  public onSubmit = new EventEmitter<{}>();

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogCloseCorrectiveMaintenanceGatewayComponent>,
    @Inject(MAT_DIALOG_DATA) public allData: any, private translate: TranslateService
  ) {
    this.form = this.createForm(allData.data);
    this.selectedProblemType(allData);
  }

  ngOnInit(): void {
    try {
    } catch (error) {}
  }

  selectedProblemType(allData: any) {
    if (this.allData.data.problemType !== null && this.allData.data.problemType !== undefined) {
      const selectedProblemType = this.allData.problemTypes.find(c => c.name.toUpperCase() === this.allData.data.problemType.name.toUpperCase());
      this.form.get('problemType').setValue(selectedProblemType);
      this.form.get('problemTypeView').setValue(selectedProblemType);
    }
  }

  createForm(data) {
    if (JSON.stringify(data) === '{}') {
      return this.fb.group({
        problemTypeView: [{disabled: true, value: null}, [Validators.required]],
        problemType: [{disabled: false, value: null}, [Validators.required]],
        operationName: [{disabled: false, value: null}, [Validators.required]],
        comment: [{disabled: false, value: null}, [Validators.maxLength(5000)]],
      });
    } else {
      return this.fb.group({
        problemTypeView: [{disabled: true, value: data.problemType}, [Validators.required]],
        problemType: [{disabled: false, value: data.problemType}, [Validators.required]],
        operationName: [{disabled: false, value: data.operationName}, [Validators.required]],
        comment: [{disabled: false, value: data.comment}, [Validators.maxLength(5000)]],
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    let data = {
      id: this.allData.data.id,
      body: this.form.value,
    }
    this.onSubmit.emit(data);
  }
}

