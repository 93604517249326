import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { AuthService } from './shared/services/auth.service';
import { LoggerService } from './shared/services/logger.service';
import { UiService } from './shared/services/ui.service';
import { ModuleEnum } from './shared/models/user/user.model';
import { ApiService } from './shared/services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'T3Soft smart systems';

  public offlineNotificationVisible = false;

  constructor(
    private authService: AuthService,
    private notifier: NotifierService,
    private logger: LoggerService,
    private translate: TranslateService,
    private uiService: UiService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    try {
      this.translate.addLangs(['en', 'sr']);
      if (
        this.authService.getLanguageFromStorage() === null ||
        this.authService.getLanguageFromStorage() === undefined ||
        this.authService.getLanguageFromStorage().trim() === '' ||
        (this.authService.getLanguageFromStorage().trim() !== 'en' &&
          this.authService.getLanguageFromStorage().trim() !== 'sr')
      ) {
        this.authService.saveLanguageToStorage('en');
        this.translate.setDefaultLang('en');
        this.translate.use('en');
      } else {
        this.translate.use(this.authService.getLanguageFromStorage().trim());
      }
      let modules = [ModuleEnum.SS.toString(), ModuleEnum.SL.toString(), ModuleEnum.SWM.toString(), ModuleEnum.SEM.toString()];
      if (
        this.authService.getModuleFromStorage() === null ||
        this.authService.getModuleFromStorage() === undefined || this.authService.getModuleFromStorage().trim() === ''
        || !modules.includes(this.authService.getModuleFromStorage())
      ) {
        this.authService.selectedModule = null;
        this.authService.removeModuleFromStorage();
        this.authService.selectedModuleDTO = null;
      } else {
        this.authService.selectedModule = this.authService.getModuleFromStorage();
      }
    } catch (error) {
      this.logger.error(error);
    }
  }

  get isAuthenticated() {
    return this.authService.isAuthenticated;
  }

  @HostListener('window:online')
  public onOnline() {
    let messageText = null;
    this.translate.get('INTERNET.Message').subscribe((value) => {
      messageText = value;
    });
    this.offlineNotificationVisible = false;
    this.notifier.show({
      type: 'success',
      message: messageText,
    });
  }

  @HostListener('window:offline')
  public onOffline() {
    this.offlineNotificationVisible = true;
  }

  get isGlobalLoaderVisible() {
    return this.uiService.isGlobalLoaderVisible;
  }

  get globalLoaderText() {
    return this.uiService.globalLoaderText;
  }
}
