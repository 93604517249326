
<div mat-dialog-content>
  <div fxLayoutAlign="space-around center" fxLayoutGap="5px" fxLayout="row wrap" class="general">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{
          'DEVICE.Title' | translate
        }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="form" autocomplete="off">
          <div fxLayout="row wrap" fxLayoutAlign="space-around center" >
            <mat-form-field class="default">
              <mat-label translate>SECTION.Name</mat-label>
              <input matInput formControlName="name" required />
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center" *ngIf="adminRole">
            <mat-form-field class="default">
              <mat-label translate>SECTION.Client</mat-label>
              <mat-select formControlName="client">
                <mat-option *ngFor="let client of clients" [value]="client">{{
                  client.name
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="default">
              <mat-label translate>SECTION.Module</mat-label>
              <mat-select formControlName="module">
                <mat-option *ngFor="let module of modules" [value]="module">{{
                  'MODULE.' + module.name | translate
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center" *ngIf="location">
            <mat-form-field class="default">
              <mat-label translate>SECTION.Locations</mat-label>
              <mat-select #selectLocation formControlName="locations" multiple>
                <mat-option>
                  <ngx-mat-select-search [formControl]="locationFilterCtrl" placeholderLabel="{{'SECTION.Location placeholder' | translate}}" noEntriesFoundLabel="{{'SECTION.Location not found' | translate}}"></ngx-mat-select-search>
                </mat-option>
                <div class="select-all">
                  <mat-checkbox
                    [(ngModel)]="allSelectedLocation"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="toggleAllSelectionLocation()"
                    >{{ "COMMON.Choose all" | translate }}</mat-checkbox
                  >
                </div>
                <mat-option *ngFor="let location of filteredLocations | async" [value]="location">{{
                  location.name
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center" *ngIf="device">
            <mat-form-field class="default">
              <mat-label translate>SECTION.Devices</mat-label>
              <mat-select #selectDevice formControlName="devices" multiple>
                <mat-option>
                  <ngx-mat-select-search [formControl]="deviceFilterCtrl" placeholderLabel="{{'SECTION.Device placeholder' | translate}}" noEntriesFoundLabel="{{'SECTION.Device not found' | translate}}"></ngx-mat-select-search>
                </mat-option>
                <div class="select-all">
                  <mat-checkbox
                    [(ngModel)]="allSelectedDevice"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="toggleAllSelectionDevice()"
                    >{{ "COMMON.Choose all" | translate }}</mat-checkbox
                  >
                </div>
                <mat-option *ngFor="let device of filteredDevices | async" [value]="device">{{
                  device.name
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- <div fxLayout="row wrap" fxLayoutAlign="center">
            <mat-form-field class="example-chip-list">
              <mat-label translate>SECTION.Emails</mat-label>
              <mat-chip-list #chipList aria-label="Emails selection">
                <mat-chip *ngFor="let email of emails" (removed)="remove(email)">
                  {{ email }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
                <input [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="add($event)" #emailsInput formControlName="emails" />
              </mat-chip-list>
            </mat-form-field>
          </div> -->
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field class="default">
              <mat-label translate>SECTION.Status</mat-label>
              <mat-select formControlName="status" required>
                <mat-option *ngFor="let status of statuses" [value]="status">{{
                  'SECTION.' + status | translate
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field class="default">
              <mat-label translate>SECTION.Description</mat-label>
              <textarea matInput formControlName="description"></textarea>
              <mat-hint align="end">{{
                form.get("description").value?.length || 0
                }}/500</mat-hint>
            </mat-form-field>
          </div>

        </form>
        <div fxLayoutAlign="center center" fxLayoutGap="5px" fxLayout="row wrap">
          <button mat-raised-button (click)="onNoClick()" color="accent">{{'SECTION.Close' | translate}}</button>
          <button mat-raised-button (click)="onYesClick()" color="primary" [disabled]="form.invalid">{{'SECTION.Edit' | translate}}</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

</div>

