<div mat-dialog-content>
  <div fxLayoutAlign="space-around center" fxLayoutGap="5px" fxLayout="row wrap" class="general">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{
          data.device.name
        }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="form" autocomplete="off" *ngIf="adminRole || managerRole">
          <div fxLayoutAlign="center center" fxLayoutGap="5px" fxLayout="row wrap">
            <mat-form-field>
              <mat-label translate>RESTRICTION.Client</mat-label>
              <mat-select formControlName="client">
                <mat-option (onSelectionChange)="changeClient($event)" [value]="data.device?.moduleClientLocation.moduleClient.client"
                  >{{ data.device?.moduleClientLocation.moduleClient.client.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayoutAlign="center center" fxLayoutGap="5px" fxLayout="row wrap" class="div-rp">
            <mat-form-field>
              <mat-label translate>RESTRICTION.Type</mat-label>
              <mat-select formControlName="restriction">
                <mat-option (onSelectionChange)="changeRestrictionType($event)"
                  *ngFor="let restriction of data.restrictions"
                  [value]="restriction"
                  >{{ 'RESTRICTION.' + restriction.name | translate }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>RESTRICTION.Profile</mat-label>
              <mat-select formControlName="restrictionProfile">
                <mat-option
                  *ngFor="let restrictionProfile of restrictionProfiles"
                  [value]="restrictionProfile"
                  >
                  <span *ngIf="restrictionProfile.middleMax">
                    {{ restrictionProfile.name }}: {{restrictionProfile.min}} - {{restrictionProfile.middleMax}} - {{restrictionProfile.max}}
                  </span>
                  <span *ngIf="!restrictionProfile.middleMax">
                    {{ restrictionProfile.name }}: {{restrictionProfile.min}} - / - {{restrictionProfile.max}}
                  </span>
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>RESTRICTION.Notification time</mat-label>
              <input matInput type="number" formControlName="notificationTime" />
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="5px">
            <button
            mat-raised-button
            [disabled]="form.invalid"
            (click)="addItem(form.value)" color="primary"
          >
          {{ "RESTRICTION.Add restriction profile" | translate}}
          </button>
          <button mat-raised-button (click)="cancel()" class="cancel">
            {{ "RESTRICTION.Cancel restriction profile" | translate}}
          </button>
          </div>

        </form>
        <div *ngIf="tableData">
          <app-table-item
            [tableData]="tableData"
            (action)="performAction($event)"
            (slide)="performSlide($event)"
            [checked]="checked"
          ></app-table-item>
        </div>
        <div mat-dialog-actions align="end" fxLayout="row wrap">
          <button mat-raised-button (click)="onNoClick()" color="accent">{{'DEVICE.Close' | translate}}</button>
          <button mat-raised-button (click)="onYesClick()" color="primary" *ngIf="adminRole || managerRole">{{'DEVICE.Edit' | translate}}</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

