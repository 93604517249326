<div fxLayoutAlign="space-around center" fxLayoutGap="5px" fxLayout="row wrap">
  <h1 mat-dialog-title>{{ question }}</h1>
</div>
<div class="confirm-modal" (click)="$event.stopPropagation()">
  <div
    fxLayoutAlign="space-around center"
    fxLayoutGap="5px"
    fxLayout="row wrap"
  >
    <div mat-dialog-content>
      <div
        fxLayoutAlign="space-around center"
        fxLayoutGap="5px"
        fxLayout="row wrap"
      >
        <div fxLayout="row wrap" class="comment-div">
          <form [formGroup]="form" autocomplete="off">
            <div
              fxLayoutAlign="space-around center"
              fxLayoutGap="5px"
              fxLayout="row wrap"
            >
              <mat-form-field>
                <mat-label translate>REPORT.HACCP.Comment</mat-label>
                <textarea matInput formControlName="comment"></textarea>
                <mat-hint align="end"
                  >{{
                    form.get("comment").value?.length || 0
                  }}/2000</mat-hint
                >
              </mat-form-field>
            </div>
          </form>
        </div>
      </div>
      <div
        fxLayoutAlign="space-around center"
        fxLayoutGap="5px"
        fxLayout="row wrap"
      >
        <button (click)="emitNo()" mat-raised-button color="accent">
          {{ "COMMON.No" | translate }}
        </button>
        <button
          mat-raised-button
          (click)="emitYes()"
          color="primary"
          cdkFocusInitial
        >
          {{ "COMMON.Yes" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
