<div class="exception-column">
  <mat-icon [matMenuTriggerFor]="menu" class="three-dots">more_horiz</mat-icon>
  <mat-menu #menu="matMenu" [xPosition]="'before'">
    <mat-list>
      <mat-list-item *ngFor="let exception of exceptions">
        {{ "RESTRICTION.EXCEPTION." + exception.day | translate }} : {{ exception.startTime | date: 'HH:mm'}} - {{ exception.endTime | date: 'HH:mm'}} <mat-slide-toggle color="primary" [checked]="exception.status === 'ACTIVE' ? true : false" *ngIf="status"></mat-slide-toggle>
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-list>
  </mat-menu>
</div>
