import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent {

  @Output()
  public onYes = new EventEmitter();

  @Output()
  public onNo = new EventEmitter();

  constructor(
    private dialogRef: MatDialogRef<DialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { question: string },
  ) {}

  get question() {
    return (this.data && this.data.question) || 'Are you sure?';
  }

  public emitNo() {
    this.onNo.emit();
    this.dialogRef.close();
  }

  public emitYes() {
    this.onYes.emit();
  }
}
