import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import jwt_decode from "jwt-decode";
import { environment } from 'src/environments/environment';
import { ModuleEnum, UserDTO, UserRole } from '../models/user/user.model';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';
import { ModuleDTO } from '../models/module/module.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public loggedInUser: UserDTO | null = null;
  public token: string | null = null;

  private selectedModule_: string | null = null;
  private selectedModuleDTO_: ModuleDTO | null = null;

  constructor(private apiService: ApiService, private logger: LoggerService) { }

  get isAuthenticated() {
    return this.loggedInUser !== null;
  }

  public get selectedModule() {
    return this.selectedModule_;
  }

  public set selectedModule(module: string) {
    this.selectedModule_ = module;
  }

  public get selectedModuleDTO() {
    return this.selectedModuleDTO_;
  }

  public set selectedModuleDTO(module: ModuleDTO) {
    this.selectedModuleDTO_ = module;
  }

  public getToken(): Observable<string | null> {
    return new Observable((observer) => {
      if (this.getTokenFromStorage() === null || this.getTokenFromStorage() === undefined || this.getTokenFromStorage().trim() === '') {
        observer.next(null);
        return;
      }
      if (!this.isTokenExpired(this.getTokenFromStorage())) {
        observer.next(this.getTokenFromStorage());
        return;
      }
      this.apiService
        .refresh(this.getLanguageFromStorage())
        .then((token) => {
          this.token = token;
          this.saveTokenToStorage(this.token);
          observer.next(this.token);
        })
        .catch((err) => {
          this.logger.error(err);
          observer.next(null);
        });
    });
  }

  public async initialSetup() {
    try {
      let token = this.getTokenFromStorage();
      if (!token) {
        throw new Error('No token in storage');
      }
      const isTokenExpired = this.isTokenExpired(token);
      if (isTokenExpired) {
        token = await this.apiService.refresh(this.getLanguageFromStorage()).catch((err) => {
          this.removeTokenFromStorage();
          throw err;
        });
        this.saveTokenToStorage(token);
      }
      this.token = token;
      this.loggedInUser = await this.apiService.getMe(this.getLanguageFromStorage());
      if (this.selectedModule !== null && this.selectedModule !== undefined) {
        this.selectedModuleDTO = await this.apiService.findModuleByName(this.selectedModule, this.getLanguageFromStorage());
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  public async login(username: string, password: string, lang: string) {
    const response = await this.apiService.login(username, password, lang);
    this.token = response.token;
    this.saveTokenToStorage(response.token);
    const me = await this.apiService.getMe(lang);
    this.logger.log(me);
    this.loggedInUser = me;

  }

  public hasRole(roles: UserRole[]) {
    if (this.loggedInUser !== null) {
      for (const role of roles) {
        if (this.loggedInUser.roles.includes(role)) {
          return true;
        }
      }
    }
    return false;
  }

  public hasModule(modules: ModuleEnum[]) {
    if (this.loggedInUser !== null) {
      for (const module of modules) {
        if (this.selectedModule !== null && this.selectedModule === module && (this.loggedInUser.modules.includes(module))) {
          return true;
        }
      }
    }
    return false;
  }

  public hasADMRole() {
    if (this.loggedInUser !== null) {
      if (this.loggedInUser.roles.includes(UserRole.ADM)) {
        return true;
      }
    }
    return false;
  }

  public hasUSERRole() {
    if (this.loggedInUser !== null) {
      if (this.loggedInUser.roles.includes(UserRole.USER)) {
        return true;
      }
    }
    return false;
  }

  public hasMANRole() {
    if (this.loggedInUser !== null) {
      if (this.loggedInUser.roles.includes(UserRole.MAN)) {
        return true;
      }
    }
    return false;
  }

  public hasSSModule() {
    if (this.loggedInUser !== null) {
      if (this.loggedInUser.modules.includes(ModuleEnum.SS)) {
        return true;
      }
    }
    return false;
  }

  public hasSSSelectedModule() {
    if (this.selectedModule !== null) {
      if (this.selectedModule === ModuleEnum.SS) {
        return true;
      }
    }
    return false;
  }

  public hasSLModule() {
    if (this.loggedInUser !== null) {
      if (this.loggedInUser.modules.includes(ModuleEnum.SL)) {
        return true;
      }
    }
    return false;
  }

  public hasSLSelectedModule() {
    if (this.selectedModule !== null) {
      if (this.selectedModule === ModuleEnum.SL) {
        return true;
      }
    }
    return false;
  }

  public hasSWMModule() {
    if (this.loggedInUser !== null) {
      if (this.loggedInUser.modules.includes(ModuleEnum.SWM)) {
        return true;
      }
    }
    return false;
  }

  public hasSWMSelectedModule() {
    if (this.selectedModule !== null) {
      if (this.selectedModule === ModuleEnum.SWM) {
        return true;
      }
    }
    return false;
  }

  public hasSEMModule() {
    if (this.loggedInUser !== null) {
      if (this.loggedInUser.modules.includes(ModuleEnum.SEM)) {
        return true;
      }
    }
    return false;
  }

  public hasSEMSelectedModule() {
    if (this.selectedModule !== null) {
      if (this.selectedModule === ModuleEnum.SEM) {
        return true;
      }
    }
    return false;
  }

  public logout() {
    this.loggedInUser = null;
    this.token = null;
    this.selectedModule = null;
    this.selectedModuleDTO = null;
    this.removeTokenFromStorage();
    this.removeModuleFromStorage();
    this.apiService
      .logout(this.getLanguageFromStorage())
      .then(() => {
        this.logger.log('Logout success');
      })
      .catch((err) => {
        console.error(err);
      });
  }

  private isTokenExpired(token: string | null): boolean {
    try {
      if (token !== null) {
        const decoded = jwt_decode<{ exp: number }>(token);
        // decoded.exp is UNIX timestamp in seconds
        // Date.now() returns UNIX timestamp in milliseconds
        // 5000 are extra 5 seconds to prevent unauthorized requests due to network latency
        return decoded.exp * 1000 <= Date.now() + 5000;
      }
      return true;
    } catch (error) {
      return true;
    }
  }

  public getTokenFromStorage() {
    return localStorage.getItem(environment.localStorage.tokenKey);
  }

  private saveTokenToStorage(token: string) {
    localStorage.setItem(environment.localStorage.tokenKey, token);
  }

  public removeTokenFromStorage() {
    localStorage.removeItem(environment.localStorage.tokenKey);
  }

  public saveLanguageToStorage(language: string) {
    localStorage.setItem(environment.localStorage.languageKey, language);
  }

  public getLanguageFromStorage() {
    return localStorage.getItem(environment.localStorage.languageKey);
  }

  public removeLanguageFromStorage() {
    localStorage.removeItem(environment.localStorage.languageKey);
  }

  public saveModuleToStorage(module: string) {
    localStorage.setItem(environment.localStorage.moduleKey, module);
  }

  public getModuleFromStorage() {
    return localStorage.getItem(environment.localStorage.moduleKey);
  }

  public removeModuleFromStorage() {
    localStorage.removeItem(environment.localStorage.moduleKey);
  }
}
