import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ModuleEnum, UserRole } from 'src/app/shared/models/user/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private uiService: UiService,
    private logger: LoggerService,
  ) { }

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated) {
      this.uiService.setGlobalLoaderVisible(true);
      this.checkRoleAndModule(route.data.roles, route.data.modules);
      this.uiService.setGlobalLoaderVisible(false);
      return true;
    }
    try {
      this.uiService.setGlobalLoaderVisible(true);
      await this.authService.initialSetup();
      this.checkRoleAndModule(route.data.roles, route.data.modules);
      this.uiService.setGlobalLoaderVisible(false);
      return true;
    } catch (error) {
      this.logger.error(error);
      this.authService.removeTokenFromStorage();
      this.router.navigateByUrl('login');
      this.uiService.setGlobalLoaderVisible(false);
      return false;
    }
  }

  private checkRoleAndModule(roles: UserRole[], modules: ModuleEnum[]) {
    if (roles) {
      if (this.checkRole(roles)) {
        if (this.authService.hasADMRole()) {
          this.uiService.setGlobalLoaderVisible(false);
          return true;
        } else {
          if (modules) {
            if (this.checkModule(modules)) {
              this.uiService.setGlobalLoaderVisible(false);
              return true;
            } else {
              this.logger.log('No Module');
              this.router.navigateByUrl('forbidden');
              this.uiService.setGlobalLoaderVisible(false);
              return false;
            }
          } else {
            this.uiService.setGlobalLoaderVisible(false);
            return true;
          }
        }
      } else {
        this.logger.log('No Role');
        this.router.navigateByUrl('forbidden');
        this.uiService.setGlobalLoaderVisible(false);
        return false;
      }
    } else {
      if (modules) {
        if (this.checkModule(modules)) {
          this.logger.log('No Module');
          this.router.navigateByUrl('forbidden');
          this.uiService.setGlobalLoaderVisible(false);
          return false;
        } else {
          this.uiService.setGlobalLoaderVisible(false);
          return true;
        }
      }
    }
  }

  private checkRole(roles: UserRole[]) {
    if (!this.authService.hasRole(roles)) {
      return false;
    }
    return true;
  }

  private checkModule(modules: ModuleEnum[]) {
    if (modules) {
      if (!this.authService.hasModule(modules)) {
        return false;
      }
      return true;
    }
  }
}
