import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientDTO } from '../../models/client/client.model';
import { ModuleDTO } from '../../models/module/module.model';
import { ModuleClientNotificationSkeletonDTO } from '../../models/module-client-notification/module-client-notification.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UserDTO } from '../../models/user/user.model';
import { ReplaySubject, Subject } from 'rxjs';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { AuthService } from '../../services/auth.service';
import { UiService } from '../../services/ui.service';
import { UtilService } from '../../services/util.service';
import { LoggerService } from '../../services/logger.service';
import { NotifierService } from 'angular-notifier';
import { ApiService } from '../../services/api.service';
import { takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import { UserDetailDTO } from '../../models/user/user-detail.model';

@Component({
  selector: 'app-dialog-edit-job',
  templateUrl: './dialog-edit-job.component.html',
  styleUrls: ['./dialog-edit-job.component.scss']
})
export class DialogEditJobComponent implements OnDestroy {

  @ViewChild('select') select: MatSelect;
  public allSelected: boolean = false;

  @ViewChild('selects') selectS: MatSelect;
  public allSelectedS: boolean = false;


  form: FormGroup = this.createForm({});

  statuses: string[] = ['active', 'inactive'];

  clients: ClientDTO[] = [];

  modules: ModuleDTO[] = [];

  skeletons: ModuleClientNotificationSkeletonDTO[] = [];

  users: UserDetailDTO[] = [];
  public filteredUsers: ReplaySubject<UserDetailDTO[]> = new ReplaySubject<
  UserDetailDTO[]
  >(1);
  public userFilterCtrl: FormControl = new FormControl();
  private _onDestroy = new Subject<void>();

  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  emails: string[] = [];

  public checkSms: boolean;

  addOnBlurPhone = true;
  separatorKeysCodesPhone: number[] = [ENTER, COMMA, SPACE];
  phones: string[] = [];

  private sms_: boolean = false;

  @Output()
  public onSubmit = new EventEmitter<{}>();

  constructor(public dialogRef: MatDialogRef<DialogEditJobComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private translate: TranslateService, private fb: FormBuilder,
    private authService: AuthService,
    private uiService: UiService,
    private utilService: UtilService,
    private logger: LoggerService,
    private notifierService: NotifierService,
    private apiService: ApiService) {
      this.clients.push(this.data.moduleClient.client);
      this.modules.push(this.data.moduleClient.module);
      if (this.data.emails?.length > 0) {
        this.data.emails.forEach(element => {
          this.emails.push(element.email);
        });
      }
      if (this.data.phones?.length > 0) {
        this.data.phones.forEach(element => {
          this.phones.push(element.phone);
        });
      }
      this.form = this.createForm(data);
      this.selectedStatus();
      this.selectedData();
      if (this.data.sms) {
        this.checkSms = true;
        this.sms_ = true;
      } else {
        this.checkSms = false;
        this.sms_ = false;
      }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  public toggle() {
    if (!this.checkSms) {
      this.sms_ = false;
      this.form.get('phones').setValue(null);
      this.phones = [];
    } else {
      this.sms_ = true;
    }
  }

  public get sms() {
    return this.sms_;
  }

  private async selectedData() {
    try {
      this.uiService.setGlobalLoaderVisible(true);
      this.form.get('client').setValue(this.clients[0]);
      this.form.get('module').setValue(this.modules[0]);
      this.form.get('emails').setValue(null);
      this.form.get('phones').setValue(null);
      this.skeletons = await this.apiService.findAllModuleClientNotificationSkeleton(
        this.form.get('client').value.id,
        this.form.get('module').value.id,
        this.data.id,
        this.translate.currentLang
      );
      if (this.data.moduleClientNotificationSkeletons !== null && this.data.moduleClientNotificationSkeletons !== undefined) {
        let selectedMCNSs = [];
        this.data.moduleClientNotificationSkeletons.forEach(element => {
          const selected = this.skeletons.find(c => c.clientId === element.clientId && c.moduleId === element.moduleId && c.notificationSkeletonId === element.notificationSkeletonId);
          selectedMCNSs.push(selected);
        });
        this.form.get('skeletons').setValue(selectedMCNSs);
      }
      this.users = await this.apiService.findAllUserJob(
        this.form.get('client').value.id,
        this.form.get('module').value.id,
        this.translate.currentLang
      );
      this.filteredUsers.next(this.users.slice());
      this.userFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterUsers();
        });
        if (this.data.users !== null && this.data.users !== undefined) {
          let selectedUsers = [];
          this.data.users.forEach(element => {
            const selected = this.users.find(c => c.id === element.user.id);
            selectedUsers.push(selected);
          });
          this.form.get('users').setValue(selectedUsers);
        }
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.uiService.setGlobalLoaderVisible(false);
    }
  }

  private selectedStatus() {
    if (this.data.status !== null && this.data.status !== undefined) {
      const selectedStatus = this.statuses.find(c => c.toUpperCase() === this.data.status.toUpperCase());
      this.form.get('status').setValue(selectedStatus);
    }
  }

  createForm(item): FormGroup {
    return this.fb.group({
      client: [{ disabled: false, value: item.client || null }, [Validators.required]],
      module: [{ disabled: false, value: item.module || null }, [Validators.required]],
      name: [{ disabled: false, value: item.name || null }, [Validators.required]],
      emails: [{ disabled: false, value: item.emails || null }],
      description: [{ disabled: false, value: item.description || null }, [Validators.maxLength(500)]],
      status: [{ disabled: false, value: item.status || null }, [Validators.required]],
      skeletons: [{ disabled: false, value: item.skeletons || null }, [Validators.required]],
      users: [{ disabled: false, value: item.users || null }],
      phones: [{ disabled: false, value: item.phones || null }],
    });
  }

  public filterUsers() {
    try {
      if (!this.users) {
        return;
      }
      let search = this.userFilterCtrl.value;
      if (!search) {
        this.filteredUsers.next(this.users.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      this.filteredUsers.next(
        this.users.filter(
          (user) => user.firstName.concat(' ').concat(user.lastName).toLowerCase().indexOf(search) > -1
        )
      );
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    }
  }

  add(event: MatChipInputEvent): void {
    try {
      let value = (event.value || '').trim();
      if (value) {
        value = value.replace(/(?:\s|^)(?![a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)\S+\b(?=\s|$)/ig, '');
        if (value.length !== 0) {
          this.emails.push(value);
        }
      }
      event.chipInput!.clear();
      this.form.get('emails').setValue(null);
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    }
  }

  addPhone(event: MatChipInputEvent): void {
    try {
      let value = (event.value || '').trim();
      if (value) {
        const data = value;
        value = value.replace(/^[\\+][0-9]{10,12}$/ig, '');
        if (value.length === 0) {
          this.phones.push(data);
        }
      }
      event.chipInput!.clear();
      this.form.get('phones').setValue(null);
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    }
  }

  remove(email: string): void {
    try {
      const index = this.emails.indexOf(email);
      if (index >= 0) {
        this.emails.splice(index, 1);
      }
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    }
  }

  removePhone(phone: string): void {
    try {
      const index = this.phones.indexOf(phone);
      if (index >= 0) {
        this.phones.splice(index, 1);
      }
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    }
  }

  public toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => {
        if (item.value !== null && item.value !== undefined) {
          item.select();
        }
      });
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  public toggleAllSelectionS() {
    if (this.allSelectedS) {
      this.selectS.options.forEach((item: MatOption) => {
        if (item.value !== null && item.value !== undefined) {
          item.select();
        }
      });
    } else {
      this.selectS.options.forEach((item: MatOption) => item.deselect());
    }
  }

  onYesClick(): void {
    let data = {
      ...this.form.value,
      emails: this.emails,
      sms: this.sms,
      phones: this.phones,
    }
    this.onSubmit.emit(data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
