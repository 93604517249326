import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { DataTable } from '../../table/model/data-table.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '../../services/ui.service';
import { ApiService } from '../../services/api.service';
import { LoggerService } from '../../services/logger.service';
import { NotifierService } from 'angular-notifier';
import { UtilService } from '../../services/util.service';
import { AuthService } from '../../services/auth.service';
import { DataTable2 } from '../../table2/model2/data-table2.interface';

@Component({
  selector: 'app-dialog-restriction-exception',
  templateUrl: './dialog-restriction-exception.component.html',
  styleUrls: ['./dialog-restriction-exception.component.scss']
})
export class DialogRestrictionExceptionComponent {

  days: string[] = ['everyDay', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  tableData: DataTable2;
  form: FormGroup = this.createForm({});

  @Output()
  public onSubmit = new EventEmitter<{}>();

  exceptionId: any;

  checked: boolean[] = new Array();

  defaulTime: any[] = [0, 0, 0];

  constructor(
    public dialogRef: MatDialogRef<DialogRestrictionExceptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private fb: FormBuilder,
    private uiService: UiService,
    private apiService: ApiService,
    private logger: LoggerService,
    private notifierService: NotifierService,
    private utilService: UtilService,
    private authService: AuthService
  ) {
    if (data.new) {
      if (data.exceptions === null || data.exceptions === undefined || data.exceptions.length === 0) {
        this.prepareTableDataWithoutSlider([]);
      } else {
        this.prepareTableDataWithoutSlider(data.exceptions);
      }
      this.checkedValues();
    } else {
      if (data.exceptions === null || data.exceptions === undefined || data.exceptions.length === 0) {
        this.prepareTableData([]);
      } else {
        this.prepareTableData(data.exceptions);
      }
      this.checkedValues();
    }
  }

  get userRole() {
    return this.authService.hasUSERRole();
  }

  get adminRole() {
    return this.authService.hasADMRole();
  }

  get managerRole() {
    return this.authService.hasMANRole();
  }

  createForm(item): FormGroup {
    return this.fb.group({
      day: [
        { disabled: false, value: item.day || null },
        [Validators.required],
      ],
      startTime: [
        { disabled: false, value: item.startTime || null },
        [Validators.required],
      ],
      endTime: [
        { disabled: false, value: item.endTime || null },
        [Validators.required],
      ],
      status: [
        { disabled: false, value: item.status || null },
        [],
      ],
    });
  }

  public checkedValues() {
    if (this.data.exceptions !== null && this.data.exceptions !== undefined && this.data.exceptions.length > 0) {
      this.data.exceptions.forEach(element => {
        if (element.status === 'ACTIVE') {
          this.checked.push(true);
        }
        if (element.status === 'INACTIVE') {
          this.checked.push(false);
        }
      });
    }
  }

  prepareTableData(data) {
    this.tableData = {
      displayedColumns: [
        { header: 'RESTRICTION.EXCEPTION.Day', value: "day", checkBox: false, translate: true, pipe: false, type: null, format: null },
        { header: 'RESTRICTION.EXCEPTION.Start time', value: "startTime", checkBox: false, translate: false, pipe: true, type: 'date', format: 'HH:mm' },
        { header: 'RESTRICTION.EXCEPTION.End time', value: "endTime", checkBox: false, translate: false, pipe: true, type: 'date', format: 'HH:mm' },
      ],
      data: data,
      actions: [{ id: 2, name: 'delete', iconClass: 'delete' }],
      slider: { name: 'RESTRICTION.EXCEPTION.Status' },
    };
  }

  prepareTableDataWithoutSlider(data) {
    this.tableData = {
      displayedColumns: [
        { header: 'RESTRICTION.EXCEPTION.Day', value: "day", checkBox: false, translate: true, pipe: false, type: null, format: null },
        { header: 'RESTRICTION.EXCEPTION.Start time', value: "startTime", checkBox: false, translate: false, pipe: true, type: 'date', format: 'HH:mm' },
        { header: 'RESTRICTION.EXCEPTION.End time', value: "endTime", checkBox: false, translate: false, pipe: true, type: 'date', format: 'HH:mm' },
      ],
      data: data,
      actions: [{ id: 2, name: 'delete', iconClass: 'delete' }],
    };
  }

  performSlide(slide: any) {
    if (slide.check === false) {
      this.tableData.data[slide.elementId].status = "ACTIVE";
    } else {
      this.tableData.data[slide.elementId].status = "INACTIVE";
    }
    this.prepareTableData(this.tableData.data);
  }

  performAction(action: any) {
    switch (action.name) {
      case 'edit': {
        this.prepareEdit(action.elementId);
        break;
      }
      case 'delete': {
        this.delete(action.elementId);
        break;
      }
    }
  }

  prepareEdit(id: number) {
    const item = this.tableData.data[id];
    this.form = this.createForm(item);
    this.exceptionId = id;
  }

  delete(id: number) {
    this.tableData.data.splice(id, 1);
    if (this.data.new) {
      this.prepareTableDataWithoutSlider(this.tableData.data);
    } else {
      this.prepareTableData(this.tableData.data);
    }
    this.exceptionId = null;
  }

  addItem(item) {
    if (!this.tableData) {
      if (this.data.new) {
        this.prepareTableDataWithoutSlider(this.tableData.data);
      } else {
        this.prepareTableData(this.tableData.data);
      }
    }
    if (
      this.exceptionId !== null &&
      this.exceptionId !== undefined
    ) {
      this.tableData.data.splice(this.exceptionId, 1, item);
      if (this.data.new) {
        this.prepareTableDataWithoutSlider(this.tableData.data);
      } else {
        this.prepareTableData(this.tableData.data);
      }
      this.form = this.createForm({});
      this.exceptionId = null;
    } else {
      if (this.data.new) {
        this.prepareTableDataWithoutSlider(this.tableData.data.concat(item));
      } else {
        this.prepareTableData(this.tableData.data.concat(item));
      }
      this.form = this.createForm({});
    }
  }

  cancel() {
    this.form = this.createForm({});
    this.exceptionId = null;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    this.onSubmit.emit(this.tableData.data);
  }
}
