import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-dialog-view-restriction',
  templateUrl: './dialog-view-restriction.component.html',
  styleUrls: ['./dialog-view-restriction.component.scss']
})
export class DialogViewRestrictionComponent {

  form: FormGroup = this.createForm({});

  @Output()
  public onSubmit = new EventEmitter<{}>();

  constructor(public dialogRef: MatDialogRef<DialogViewRestrictionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private translate: TranslateService, private fb: FormBuilder, private authService: AuthService) {
      this.form = this.createForm(data.restrictionProfile);
      this.selectedRestriction();
      this.selectedClient();
    }

  private createForm(data) {
    if (JSON.stringify(data) === '{}') {
      return this.fb.group({
        min: [{disabled: false, value: ''}, [Validators.required]],
        middleMax: [{ disabled: false, value: null }, []],
        max: [{disabled: false, value: ''}, [Validators.required]],
        restriction: [{disabled: false, value: null}, [Validators.required]],
        name: [{disabled: false, value: ''}, [Validators.required]],
        description: [{disabled: false, value: ''}],
        client: [{disabled: false, value: null}, [Validators.required]],
      });
    } else {
      return this.fb.group({
        min: [{disabled: false, value: data.min}, [Validators.required]],
        middleMax: [{ disabled: false, value: data.middleMax }, []],
        max: [{disabled: false, value: data.max}, [Validators.required]],
        restriction: [{disabled: false, value: data.restriction}, [Validators.required]],
        name: [{disabled: false, value: data.name}, [Validators.required]],
        description: [{disabled: false, value: data.description}],
        client: [{disabled: false, value: data.client}, [Validators.required]],
      });
    }
  }

  private selectedRestriction() {
    if(this.data.restrictions !==null && this.data.restrictions !== undefined){
      const selectedRestriction = this.data.restrictions.find(c => c.id == this.data.restrictionProfile.restriction.id);
      this.form.get('restriction').setValue(selectedRestriction);
    }
  }

  private selectedClient() {
    if(this.data.clients !==null && this.data.clients !== undefined){
      const selectedClient = this.data.clients.find(c => c.id == this.data.restrictionProfile.client.id);
      this.form.get('client').setValue(selectedClient);
    }
  }

  onYesClick(): void {
    this.onSubmit.emit({});
  }

  get adminRole() {
    return this.authService.hasADMRole();
  }
}
