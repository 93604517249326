import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-gateway-image',
  templateUrl: './dialog-gateway-image.component.html',
  styleUrls: ['./dialog-gateway-image.component.scss']
})
export class DialogGatewayImageComponent {

  constructor(public dialogRef: MatDialogRef<DialogGatewayImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
