<mat-toolbar color="primary" class="toolbar">
  <mat-toolbar-row>
    <div fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="isAuthenticated && selectedModuleDTO && ((ssModule || slModule || swmModule || semModule) || adminRole)">
      <div>
        <img
          [src]="selectedModuleDTO | moduleImage"
          default="assets/img/default-avatar.svg"
          class="module-avatar"
        />
      </div>
      <div class="active-module">
        <!-- <span *ngIf="selectedSSModule" translate>HEADER.Active module</span>
        <br> -->
        <span *ngIf="selectedSSModule" translate>HEADER.SS</span>
        <span *ngIf="selectedSLModule" translate>HEADER.SL</span>
        <span *ngIf="selectedSWMModule" translate>HEADER.SWM</span>
        <span *ngIf="selectedSEMModule" translate>HEADER.SEM</span>
      </div>
    </div>
    <!-- <div fxLayoutAlign="start center">
      <button mat-icon-button (click)="onToggleSidenav()" *ngIf="isAuthenticated">
        <mat-icon>menu</mat-icon>
      </button>
    </div> -->
    <div fxFlex fxLayoutAlign="center center">
      <!-- <h4 *ngIf="isAuthenticated" class="user">{{ user.firstName }} {{ user.lastName }}</h4> -->
      <h4 *ngIf="isAuthenticated" class="user" (click)="viewAllModuleForUser()">{{'HEADER.Name' | translate}}</h4>
    </div>
    <div *ngIf="!isAuthenticated" fxLayoutAlign="end center">
      <button mat-icon-button (click)="login()" matTooltip="{{'HEADER.Login tooltip' | translate}}">
        <mat-icon>login</mat-icon>
      </button>
    </div>
    <div fxLayoutAlign="end center">
      <button mat-icon-button (click)="viewHaccp()" matTooltip="{{'HEADER.REPORT.Haccp' | translate}}" *ngIf="ssSelectedModule && (ssModule || adminRole)">
        <mat-icon>calendar_today</mat-icon>
      </button>
      <button mat-icon-button [matMenuTriggerFor]="menuLanguage" matTooltip="{{'HEADER.Language tooltip' | translate}}">
        <mat-icon>language</mat-icon>
      </button>
      <mat-menu #menuLanguage="matMenu">
        <button mat-menu-item (click)="useLanguage('en')"><span class="flag-icon flag-icon-gb"></span> {{'LANGUAGE.En' | translate}}</button>
        <button mat-menu-item (click)="useLanguage('sr')"><span class="flag-icon flag-icon-rs"></span> {{'LANGUAGE.Sr' | translate}}</button>
      </mat-menu>
    </div>
    <div fxLayoutAlign="end center" *ngIf="isAuthenticated">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon fxHide.gt-xs>more_vert</mat-icon>
        <mat-icon fxHide.lt-sm>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item class="disabled">
          <mat-icon>account_circle</mat-icon>
          <span>{{userFirstnameLastname}}</span>
        </button>
        <button mat-menu-item (click)="viewMap()" *ngIf="(ssSelectedModule && (ssModule || adminRole)) || (slSelectedModule && (slModule || adminRole)) || (swmSelectedModule && (swmModule || adminRole)) || (semSelectedModule && (semModule || adminRole))">
          <mat-icon>map</mat-icon>
          <span translate>HEADER.Dashboard</span>
        </button>
        <button mat-menu-item (click)="viewDashboardSS()" *ngIf="ssSelectedModule && (ssModule || adminRole)">
          <mat-icon>dashboard</mat-icon>
          <span translate>HEADER.Monitoring</span>
        </button>
        <button mat-menu-item (click)="viewDashboardSL()" *ngIf="slSelectedModule && (slModule || adminRole)">
          <mat-icon>dashboard</mat-icon>
          <span translate>HEADER.Monitoring</span>
        </button>
        <button [matMenuTriggerFor]="menuSetting" mat-menu-item *ngIf="adminRole || (((ssSelectedModule && ssModule) || (slSelectedModule && slModule) || (swmSelectedModule && swmModule)) && (managerRole || userRole))">
          <mat-icon>settings</mat-icon>
          <span translate>HEADER.SETTING.Title</span>
        </button>
        <button [matMenuTriggerFor]="menuControl" mat-menu-item *ngIf="slSelectedModule && ((slModule && managerRole) || adminRole)">
          <mat-icon>shuffle</mat-icon>
          <span translate>HEADER.CONTROL.Title</span>
        </button>
        <button [matMenuTriggerFor]="menuCodebook" mat-menu-item *ngIf="adminRole || managerRole">
          <mat-icon>book</mat-icon>
          <span translate>HEADER.ADMINISTRATION.Title</span>
        </button>
        <button [matMenuTriggerFor]="menuReport" mat-menu-item *ngIf="(ssSelectedModule && (ssModule || adminRole)) || (slSelectedModule && (slModule || adminRole)) || (swmSelectedModule && (swmModule || adminRole))">
          <mat-icon>description</mat-icon>
          <span translate>HEADER.REPORT.Title</span>
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span translate>HEADER.Logout</span>
        </button>
      </mat-menu>
      <mat-menu #menuControl="matMenu">
        <button mat-menu-item (click)="viewControl()" *ngIf="slSelectedModule && (slModule || adminRole)">
          <mat-icon>view_cozy</mat-icon>
          <span translate>HEADER.CONTROL.COMMAND.Title</span>
        </button>
        <button mat-menu-item (click)="viewControlInterval()" *ngIf="slSelectedModule && adminRole">
          <mat-icon>assistant_direction</mat-icon>
          <span translate>HEADER.CONTROL.COMMAND.INTERVAL.Title</span>
        </button>
        <button mat-menu-item (click)="viewControlGeneral()" *ngIf="slSelectedModule && adminRole">
          <mat-icon>swap_horizontal_circle</mat-icon>
          <span translate>HEADER.CONTROL.COMMAND.GENERAL.Title</span>
        </button>
      </mat-menu>
      <mat-menu #menuSetting="matMenu">
        <button [matMenuTriggerFor]="menuClient" mat-menu-item *ngIf="adminRole">
          <mat-icon>apps</mat-icon>
          <span translate>HEADER.CLIENT.Title</span>
        </button>
        <button [matMenuTriggerFor]="menuGateway" mat-menu-item *ngIf="adminRole">
          <mat-icon>network_check</mat-icon>
          <span translate>HEADER.GATEWAY.Title</span>
        </button>
        <button [matMenuTriggerFor]="menuDevice" mat-menu-item *ngIf="adminRole || ((managerRole || userRole) && ssModule && ssSelectedModule) || ((managerRole || userRole) && slModule && slSelectedModule) || ((managerRole || userRole) && swmModule && swmSelectedModule)">
          <mat-icon>devices</mat-icon>
          <span translate>HEADER.DEVICE.Title</span>
        </button>
        <button mat-menu-item (click)="createRestriction()" *ngIf="ssSelectedModule && (adminRole || (managerRole && ssModule))">
          <mat-icon>sensors</mat-icon>
          <span translate>HEADER.RESTRICTION.Title</span>
        </button>
        <button [matMenuTriggerFor]="menuLocation" mat-menu-item *ngIf="adminRole">
          <mat-icon>location_on</mat-icon>
          <span translate>HEADER.LOCATION.Title</span>
        </button>
        <button [matMenuTriggerFor]="menuDeviceControl" mat-menu-item *ngIf="adminRole">
          <mat-icon>gamepad</mat-icon>
          <span translate>HEADER.DEVICE_CONTROL.Title</span>
        </button>
        <button [matMenuTriggerFor]="menuSection" mat-menu-item *ngIf="adminRole || (managerRole && ssModule && ssSelectedModule) || (managerRole && slModule && slSelectedModule) || (managerRole && swmModule && swmSelectedModule)">
          <mat-icon>workspaces</mat-icon>
          <span translate>HEADER.SECTION.Title</span>
        </button>
        <button [matMenuTriggerFor]="menuNotification" mat-menu-item *ngIf="adminRole">
          <mat-icon>notifications</mat-icon>
          <span translate>HEADER.NOTIFICATION.Title</span>
        </button>
      </mat-menu>
      <mat-menu #menuDeviceControl="matMenu">
        <button [matMenuTriggerFor]="menuDeviceResponseTime" mat-menu-item *ngIf="adminRole">
          <mat-icon>schedule</mat-icon>
          <span translate>HEADER.DEVICE_CONTROL.DEVICE_RESPONSE_TIME.Title</span>
        </button>
        <button [matMenuTriggerFor]="menuDeviceCommandNumberPeriodTime" mat-menu-item *ngIf="adminRole">
          <mat-icon>keyboard_command_key</mat-icon>
          <span translate>HEADER.DEVICE_CONTROL.DEVICE_COMMAND_NUMBER_PERIOD_TIME.Title</span>
        </button>
      </mat-menu>
      <mat-menu #menuDeviceCommandNumberPeriodTime="matMenu">
        <button mat-menu-item (click)="createDeviceCommandNumberPeriodTime()">
          <mat-icon>edit_document</mat-icon>
          <span translate>HEADER.DEVICE_CONTROL.DEVICE_COMMAND_NUMBER_PERIOD_TIME.Create</span>
        </button>
        <button mat-menu-item (click)="viewDeviceCommandNumberPeriodTime()">
          <mat-icon>view_list</mat-icon>
          <span translate>HEADER.DEVICE_CONTROL.DEVICE_COMMAND_NUMBER_PERIOD_TIME.View</span>
        </button>
      </mat-menu>
      <mat-menu #menuNotification="matMenu">
        <button [matMenuTriggerFor]="menuNotificationSkeleton" mat-menu-item>
          <mat-icon>notification_add</mat-icon>
          <span translate>HEADER.NOTIFICATION.SKELETON.Title</span>
        </button>
        <button [matMenuTriggerFor]="menuModuleClientNotification" mat-menu-item>
          <mat-icon>notifications_active</mat-icon>
          <span translate>HEADER.NOTIFICATION.MODULE_CLIENT.Title</span>
        </button>
        <button [matMenuTriggerFor]="menuJob" mat-menu-item>
          <mat-icon>circle_notifications</mat-icon>
          <span translate>HEADER.NOTIFICATION.JOB.Title</span>
        </button>
        <button mat-menu-item (click)="viewNotificationInfo()">
          <mat-icon>info</mat-icon>
          <span translate>HEADER.COMMON.Notification info</span>
        </button>
      </mat-menu>
      <mat-menu #menuJob="matMenu">
        <button mat-menu-item (click)="createJob()">
          <mat-icon>edit_document</mat-icon>
          <span translate>HEADER.NOTIFICATION.JOB.Create</span>
        </button>
        <button mat-menu-item (click)="viewJob()">
          <mat-icon>view_list</mat-icon>
          <span translate>HEADER.NOTIFICATION.JOB.View</span>
        </button>
      </mat-menu>
      <mat-menu #menuModuleClientNotification="matMenu">
        <button mat-menu-item (click)="createModuleClientNotification()">
          <mat-icon>edit_document</mat-icon>
          <span translate>HEADER.NOTIFICATION.MODULE_CLIENT.Create</span>
        </button>
        <button mat-menu-item (click)="viewModuleClientNotification()">
          <mat-icon>view_list</mat-icon>
          <span translate>HEADER.NOTIFICATION.MODULE_CLIENT.View</span>
        </button>
      </mat-menu>
      <mat-menu #menuNotificationSkeleton="matMenu">
        <button mat-menu-item (click)="createNotificationSkeleton()">
          <mat-icon>edit_document</mat-icon>
          <span translate>HEADER.NOTIFICATION.SKELETON.Create</span>
        </button>
        <button mat-menu-item (click)="viewNotificationSkeleton()">
          <mat-icon>view_list</mat-icon>
          <span translate>HEADER.NOTIFICATION.SKELETON.View</span>
        </button>
      </mat-menu>
      <mat-menu #menuSection="matMenu">
        <button mat-menu-item (click)="createSection()" *ngIf="adminRole">
          <mat-icon>edit_document</mat-icon>
          <span translate>HEADER.SECTION.Create</span>
        </button>
        <button mat-menu-item (click)="viewSection()">
          <mat-icon>view_list</mat-icon>
          <span translate>HEADER.SECTION.View</span>
        </button>
      </mat-menu>
      <mat-menu #menuClient="matMenu">
        <button mat-menu-item (click)="createClient()">
          <mat-icon>edit_document</mat-icon>
          <span translate>HEADER.CLIENT.Create</span>
        </button>
        <button mat-menu-item (click)="viewClient()">
          <mat-icon>view_list</mat-icon>
          <span translate>HEADER.CLIENT.View</span>
        </button>
      </mat-menu>
      <mat-menu #menuGateway="matMenu">
        <button mat-menu-item (click)="createGateway()">
          <mat-icon>edit_document</mat-icon>
          <span translate>HEADER.GATEWAY.Create</span>
        </button>
        <button mat-menu-item (click)="viewGateway()">
          <mat-icon>view_list</mat-icon>
          <span translate>HEADER.GATEWAY.View</span>
        </button>
        <button mat-menu-item (click)="viewGatewayMap()">
          <mat-icon>map</mat-icon>
          <span translate>HEADER.GATEWAY.View map</span>
        </button>
        <button [matMenuTriggerFor]="menuCorrectiveMaintenanceGateway" mat-menu-item>
          <mat-icon>error</mat-icon>
          <span translate>HEADER.GATEWAY.Corrective maintenance</span>
        </button>
      </mat-menu>
      <mat-menu #menuCorrectiveMaintenanceGateway="matMenu">
        <button mat-menu-item (click)="createCorrectiveMaintenanceGateway()">
          <mat-icon>edit_document</mat-icon>
          <span translate>HEADER.GATEWAY.CORRECTIVE_MAINTENANCE.Create</span>
        </button>
        <button mat-menu-item (click)="viewForActionCorrectiveMaintenanceGateway()">
          <mat-icon>dns</mat-icon>
          <span translate>HEADER.GATEWAY.CORRECTIVE_MAINTENANCE.View for action</span>
        </button>
        <button mat-menu-item (click)="viewAllCorrectiveMaintenanceGateway()">
          <mat-icon>view_list</mat-icon>
          <span translate>HEADER.GATEWAY.CORRECTIVE_MAINTENANCE.View all</span>
        </button>
      </mat-menu>
      <mat-menu #menuDevice="matMenu">
        <button mat-menu-item (click)="createDevice()" *ngIf="adminRole">
          <mat-icon>edit_document</mat-icon>
          <span translate>HEADER.DEVICE.Create</span>
        </button>
        <button mat-menu-item (click)="viewDevice()" *ngIf="adminRole || managerRole">
          <mat-icon>view_list</mat-icon>
          <span translate>HEADER.DEVICE.View</span>
        </button>
        <button [matMenuTriggerFor]="menuCorrectiveMaintenanceDevice" mat-menu-item>
          <mat-icon>error</mat-icon>
          <span translate>HEADER.DEVICE.Corrective maintenance</span>
        </button>
      </mat-menu>
      <mat-menu #menuCorrectiveMaintenanceDevice="matMenu">
        <button mat-menu-item (click)="createCorrectiveMaintenanceDevice()" *ngIf="managerRole || userRole">
          <mat-icon>edit_document</mat-icon>
          <span translate>HEADER.DEVICE.CORRECTIVE_MAINTENANCE.Create</span>
        </button>
        <button mat-menu-item (click)="viewForActionCorrectiveMaintenanceDevice()" *ngIf="managerRole || userRole">
          <mat-icon>dns</mat-icon>
          <span translate>HEADER.DEVICE.CORRECTIVE_MAINTENANCE.View for action</span>
        </button>
        <button mat-menu-item (click)="viewAllCorrectiveMaintenanceDevice()">
          <mat-icon>view_list</mat-icon>
          <span translate>HEADER.DEVICE.CORRECTIVE_MAINTENANCE.View all</span>
        </button>
      </mat-menu>
      <mat-menu #menuLocation="matMenu">
        <button mat-menu-item (click)="createLocation()">
          <mat-icon>edit_document</mat-icon>
          <span translate>HEADER.LOCATION.Create</span>
        </button>
        <button mat-menu-item (click)="viewLocation()">
          <mat-icon>view_list</mat-icon>
          <span translate>HEADER.LOCATION.View</span>
        </button>
      </mat-menu>
      <mat-menu #menuDeviceResponseTime="matMenu">
        <button mat-menu-item (click)="createDeviceResponeTime()">
          <mat-icon>edit_document</mat-icon>
          <span translate>HEADER.DEVICE_CONTROL.DEVICE_RESPONSE_TIME.Create</span>
        </button>
        <button mat-menu-item (click)="viewDeviceResponeTime()">
          <mat-icon>view_list</mat-icon>
          <span translate>HEADER.DEVICE_CONTROL.DEVICE_RESPONSE_TIME.View</span>
        </button>
      </mat-menu>
      <mat-menu #menuReport="matMenu">
        <button mat-menu-item (click)="viewData()" *ngIf="ssSelectedModule && (ssModule || adminRole)">
          <mat-icon>data_usage</mat-icon>
          <span translate>HEADER.REPORT.Data</span>
        </button>
        <button mat-menu-item (click)="viewDeviceDataDetail()" *ngIf="ssSelectedModule && (ssModule || adminRole)">
          <mat-icon>graphic_eq</mat-icon>
          <span translate>HEADER.REPORT.Device data detail</span>
        </button>
        <button mat-menu-item (click)="viewDataAverage()" *ngIf="ssSelectedModule && (ssModule || adminRole)">
          <mat-icon>flare</mat-icon>
          <span translate>HEADER.REPORT.Average sensor value</span>
        </button>
        <button mat-menu-item (click)="viewGatewayReport()" *ngIf="adminRole && (ssSelectedModule || slSelectedModule || swmSelectedModule)">
          <mat-icon>podcasts</mat-icon>
          <span translate>HEADER.REPORT.Gateway</span>
        </button>
        <button mat-menu-item (click)="viewRemoticomZSC160DaliLightState()" *ngIf="slSelectedModule && (slModule || adminRole)">
          <mat-icon>monitoring</mat-icon>
          <span translate>HEADER.REPORT.Remoticom ZSC 160 Dali light state</span>
        </button>
        <button mat-menu-item (click)="viewRssiSnr()" *ngIf="(ssSelectedModule && (ssModule || adminRole)) || (slSelectedModule && (slModule || adminRole)) || (swmSelectedModule && (swmModule || adminRole))">
          <mat-icon>cell_tower</mat-icon>
          <span translate>HEADER.REPORT.Rssi and snr</span>
        </button>
        <button mat-menu-item (click)="viewDeviceData()" *ngIf="(ssSelectedModule && (ssModule || adminRole)) || (slSelectedModule && (slModule || adminRole)) || (swmSelectedModule && (swmModule || adminRole))">
          <mat-icon>settings_power</mat-icon>
          <span translate>HEADER.REPORT.Device data</span>
        </button>
        <button mat-menu-item (click)="viewEnergyConsumption()" *ngIf="slSelectedModule && (slModule || adminRole)">
          <mat-icon>electric_bolt</mat-icon>
          <span translate>HEADER.REPORT.Energy consumption</span>
        </button>
        <button mat-menu-item (click)="viewAlertCount()" *ngIf="ssSelectedModule && (ssModule || adminRole)">
          <mat-icon>assignment_late</mat-icon>
          <span translate>HEADER.REPORT.Alert</span>
        </button>
        <button mat-menu-item (click)="viewHaccp()" *ngIf="ssSelectedModule && (ssModule || adminRole)">
          <mat-icon>calendar_today</mat-icon>
          <span translate>HEADER.REPORT.Haccp</span>
        </button>
        <button mat-menu-item (click)="viewDataAverageBySectionSS()" *ngIf="ssSelectedModule && (ssModule || adminRole)">
          <mat-icon>group_work</mat-icon>
          <span translate>HEADER.REPORT.Data average by section ss</span>
        </button>
      </mat-menu>
      <mat-menu #menuCodebook="matMenu">
        <button mat-menu-item (click)="viewUsers()" *ngIf="adminRole || managerRole">
          <mat-icon>person</mat-icon>
          <span translate>HEADER.ADMINISTRATION.Users</span>
        </button>
        <button mat-menu-item (click)="viewManagers()" *ngIf="adminRole">
          <mat-icon>support_agent</mat-icon>
          <span translate>HEADER.ADMINISTRATION.Managers</span>
        </button>
        <button mat-menu-item (click)="viewAdmins()" *ngIf="adminRole">
          <mat-icon>supervisor_account</mat-icon>
          <span translate>HEADER.ADMINISTRATION.Admins</span>
        </button>
        <button mat-menu-item (click)="createAccount()" *ngIf="adminRole || managerRole">
          <mat-icon>account_circle</mat-icon>
          <span translate>HEADER.ADMINISTRATION.Account</span>
        </button>
        <button mat-menu-item (click)="viewAccountInfo()" *ngIf="adminRole || managerRole">
          <mat-icon>info</mat-icon>
          <span translate>HEADER.COMMON.Account info</span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
