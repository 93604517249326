import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() { }

  public log(...msg: any) {
    if (!environment.production) {
      console.log(...msg);
    }
  }

  public error(...msg: any) {
    if (!environment.production) {
      console.error(...msg);
    }
  }

  public trace(...msg: any) {
    if (!environment.production) {
      console.trace(...msg);
    }
  }
}
