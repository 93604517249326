export const environment = {
  production: true,
  apiUrl: 'https://smart.t3host.net/t3soft.smart.systems.api',
  localStorage: {
    tokenKey: 'token',
    languageKey: 'language',
    moduleKey: 'module',
  },
  deviceImageCount: 5,
  gatewayImageCount: 5,
};
