<!-- <app-layout> -->
<mat-sidenav-container>
  <mat-sidenav #sidenav role="navigation">
    <app-sidenav-list
      (sidenavClose)="sidenav.close()"
      *ngIf="isAuthenticated"
    ></app-sidenav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-header
      (sidenavToggle)="sidenav.toggle()"
      *ngIf="isAuthenticated"
    ></app-header>
    <main *ngIf="isAuthenticated">
      <router-outlet></router-outlet>
    </main>
    <main
      *ngIf="!isAuthenticated"
      style="
        background-image: url('assets/img/bi.png');
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      "
    >
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
<!-- </app-layout> -->
<notifier-container></notifier-container>

<div *ngIf="isGlobalLoaderVisible" class="global-loader">
  <mat-spinner></mat-spinner>
</div>
