<div fxLayout="row wrap" fxLayoutAlign="space-around center" fxLayoutGap="2px" class="main">
  <mat-card>
    <mat-card-header fxLayout="row wrap" fxLayoutAlign="space-around">
    </mat-card-header>
    <mat-card-content>
      <div class="login-page">
        <div class="logo-wrapper" fxLayout="row wrap" fxLayoutAlign="space-around center">
          <img class="logo" src="assets/img/logo.png" alt="WA" />
        </div>
        <div @parent class="form-wrapper" fxLayout="row wrap" fxLayoutAlign="space-around center">
          <!-- LOGIN FORM -->
          <form @formAnimation (ngSubmit)="onLoginSubmit()" novalidate [formGroup]="signin" autocomplete="off">
            <div class="login-form">
              <mat-form-field>
                <input matInput placeholder="{{'LOGIN.Username' | translate}}" formControlName="username"
                  (focus)="loginErrorMessage = ''">
                <mat-error *ngIf="usernameInput.hasError('required')" translate>
                  LOGIN.Username required
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <input matInput placeholder="{{'LOGIN.Password' | translate}}" [type]="hide ? 'password' : 'text'"
                  formControlName="password" (focus)="loginErrorMessage = ''">
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-error *ngIf="passwordInput.hasError('required')" translate>
                  LOGIN.Password required
                </mat-error>
              </mat-form-field>
              <button mat-raised-button [disabled]="!signin.valid" [ngClass]="{ 'is-loading': isLoginLoading }"
                type="submit" class="button">
                {{'LOGIN.Login' | translate}}
              </button>
              <p class="error-message has-text-centered">{{ loginErrorMessage }}</p>
              <!-- <p class="paragraph" translate>
              LOGIN.Rights
            </p> -->
              <span translate class="footer-text">COMMON.Version</span>
              <!-- <div class="footer">
              <span translate>COMMON.Version</span>
            </div> -->
            </div>
          </form>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
