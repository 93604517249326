<div fxLayoutAlign="space-around center" fxLayoutGap="5px" fxLayout="row wrap">
  <h1 mat-dialog-title>{{ question }}</h1>
</div>
<div class="confirm-modal" (click)="$event.stopPropagation()">
  <div fxLayoutAlign="space-around center" fxLayoutGap="5px" fxLayout="row wrap">
    <div mat-dialog-content>
      <div fxLayoutAlign="space-around center" fxLayoutGap="5px" fxLayout="row wrap">
        <div fxLayout="row wrap" class="comment-div">
        </div>
      </div>
      <div fxLayoutAlign="space-around center" fxLayoutGap="5px" fxLayout="row wrap">
        <button (click)="emitNo()" mat-raised-button color="accent">{{'COMMON.No' | translate}}</button>
        <button mat-raised-button (click)="emitYes()" color="primary" cdkFocusInitial>
          {{'COMMON.Yes' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
