import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
      if (!this.authService.isAuthenticated && (this.authService.getTokenFromStorage() === null || this.authService.getTokenFromStorage() === undefined || this.authService.getTokenFromStorage().trim() === '')) {
        return true;
      }
      this.router.navigate(['module/view-all-for-user']);
      return false;
  }

}
