import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-device-image',
  templateUrl: './dialog-device-image.component.html',
  styleUrls: ['./dialog-device-image.component.scss']
})
export class DialogDeviceImageComponent {

  constructor(public dialogRef: MatDialogRef<DialogDeviceImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
