import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogConfirmComponent } from '../modals/dialog-confirm/dialog-confirm.component';
import { DialogEditDeviceComponent } from '../modals/dialog-edit-device/dialog-edit-device.component';
import { DialogEditGatewayComponent } from '../modals/dialog-edit-gateway/dialog-edit-gateway.component';
import { DialogEditRestrictionComponent } from '../modals/dialog-edit-restriction/dialog-edit-restriction.component';
import { DialogEditUserParamForLoginComponent } from '../modals/dialog-edit-user-param-for-login/dialog-edit-user-param-for-login.component';
import { DialogSetLocationForUserComponent } from '../modals/dialog-set-location-for-user/dialog-set-location-for-user.component';
import { DialogViewRestrictionForDeviceComponent } from '../modals/dialog-view-restriction-for-device/dialog-view-restriction-for-device.component';
import { DialogViewRestrictionComponent } from '../modals/dialog-view-restriction/dialog-view-restriction.component';
import { DialogEditClientComponent } from '../modals/dialog-edit-client/dialog-edit-client.component';
import { DialogEditLocationComponent } from '../modals/dialog-edit-location/dialog-edit-location.component';
import { DialogEditDeviceResponseTimeComponent } from '../modals/dialog-edit-device-response-time/dialog-edit-device-response-time.component';
import { DialogSetLocationForManagerComponent } from '../modals/dialog-set-location-for-manager/dialog-set-location-for-manager.component';
import { DialogSetModuleForClientComponent } from '../modals/dialog-set-module-for-client/dialog-set-module-for-client.component';
import { DialogEditSectionComponent } from '../modals/dialog-edit-section/dialog-edit-section.component';
import { DialogEditNotificationSkeletonComponent } from '../modals/dialog-edit-notification-skeleton/dialog-edit-notification-skeleton.component';
import { DialogEditModuleClientNotificationComponent } from '../modals/dialog-edit-module-client-notification/dialog-edit-module-client-notification.component';
import { DialogEditJobComponent } from '../modals/dialog-edit-job/dialog-edit-job.component';
import { DialogEditDeviceCommandNumberPeriodTimeComponent } from '../modals/dialog-edit-device-command-number-period-time/dialog-edit-device-command-number-period-time.component';
import { DialogConfirmHaccpComponent } from '../modals/dialog-confirm-haccp/dialog-confirm-haccp.component';
import { DialogDeviceImageComponent } from '../modals/dialog-device-image/dialog-device-image.component';
import { DialogGatewayImageComponent } from '../modals/dialog-gateway-image/dialog-gateway-image.component';
import { DialogViewDeviceBySectionComponent } from '../modals/dialog-view-device-by-section/dialog-view-device-by-section.component';
import { DialogCloseCorrectiveMaintenanceDeviceComponent } from '../modals/dialog-close-corrective-maintenance-device/dialog-close-corrective-maintenance-device.component';
import { DialogCancelCorrectiveMaintenanceDeviceComponent } from '../modals/dialog-cancel-corrective-maintenance-device/dialog-cancel-corrective-maintenance-device.component';
import { DialogCancelCorrectiveMaintenanceGatewayComponent } from '../modals/dialog-cancel-corrective-maintenance-gateway/dialog-cancel-corrective-maintenance-gateway.component';
import { DialogCloseCorrectiveMaintenanceGatewayComponent } from '../modals/dialog-close-corrective-maintenance-gateway/dialog-close-corrective-maintenance-gateway.component';
import { DialogRestrictionExceptionComponent } from '../modals/dialog-restriction-exception/dialog-restriction-exception.component';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  public isGlobalLoaderVisible = false;
  public globalLoaderText = 'Loading...';

  constructor(private dialog: MatDialog) { }

  public setGlobalLoaderVisible(value: boolean, text: string = 'Loading...') {
    this.isGlobalLoaderVisible = value;
    this.globalLoaderText = text;
  }

  public openConfirmModal(
    question: string,
    onYes: (dialogRef: MatDialogRef<DialogConfirmComponent, any>) => any,
    onNo?: () => void,
  ) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      minWidth: '250px',
      data: { question },
    });

    const subscription = dialogRef.componentInstance.onYes.subscribe(() => {
      onYes(dialogRef);
    });

    const noSub = dialogRef.componentInstance.onNo.subscribe(() => {
      if (onNo) {
        onNo();
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
      if (noSub) {
        noSub.unsubscribe();
      }
    });
  }

  public openConfirmModalHaccp(
    question: string,
    onYes: (data: any, dialogRef: MatDialogRef<DialogConfirmHaccpComponent, any>) => any,
    onNo?: () => void,
  ) {
    const dialogRef = this.dialog.open(DialogConfirmHaccpComponent, {
      minWidth: '250px',
      data: { question },
    });

    const subscription = dialogRef.componentInstance.onYes.subscribe((data: any) => {
      onYes(data, dialogRef);
    });

    const noSub = dialogRef.componentInstance.onNo.subscribe(() => {
      if (onNo) {
        onNo();
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
      if (noSub) {
        noSub.unsubscribe();
      }
    });
  }

  public openEditClientModal(data: any, onSubmit: (data: any,
    dialogRef: MatDialogRef<DialogEditClientComponent>,
  ) => void,
  ) {
    const dialogRef = this.dialog.open(DialogEditClientComponent, {
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSubmit.subscribe(
      (data: any) => {
        onSubmit(data, dialogRef);
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public openEditGatewayModal(data: any, onSubmit: (data: any,
    dialogRef: MatDialogRef<DialogEditGatewayComponent>,
  ) => void,
  ) {
    const dialogRef = this.dialog.open(DialogEditGatewayComponent, {
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSubmit.subscribe(
      (data: any) => {
        onSubmit(data, dialogRef);
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public openEditDeviceModal(data: any, onSubmit: (data: any,
    dialogRef: MatDialogRef<DialogEditDeviceComponent>,
  ) => void,
  ) {
    const dialogRef = this.dialog.open(DialogEditDeviceComponent, {
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSubmit.subscribe(
      (data: any) => {
        onSubmit(data, dialogRef);
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public openEditRestrictionModal(data: any, onSubmit: (data: any,
    dialogRef: MatDialogRef<DialogEditRestrictionComponent>,
  ) => void,
  ) {
    const dialogRef = this.dialog.open(DialogEditRestrictionComponent, {
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSubmit.subscribe(
      (data: any) => {
        onSubmit(data, dialogRef);
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public openViewRestrictionModal(data: any, onSubmit: (data: any,
    dialogRef: MatDialogRef<DialogViewRestrictionComponent>,
  ) => void,
  ) {
    const dialogRef = this.dialog.open(DialogViewRestrictionComponent, {
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSubmit.subscribe(
      (data: any) => {
        onSubmit(data, dialogRef);
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public openViewRestrictionForDeviceModal(data: any, onSubmit: (data: any,
    dialogRef: MatDialogRef<DialogViewRestrictionForDeviceComponent>,
  ) => void,
  ) {
    const dialogRef = this.dialog.open(DialogViewRestrictionForDeviceComponent, {
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSubmit.subscribe(
      (data: any) => {
        onSubmit(data, dialogRef);
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public openEditUserParamForLoginModal(data: any, onSubmit: (data: any,
    dialogRef: MatDialogRef<DialogEditUserParamForLoginComponent>,
  ) => void,
  ) {
    const dialogRef = this.dialog.open(DialogEditUserParamForLoginComponent, {
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSubmit.subscribe(
      (data: any) => {
        onSubmit(data, dialogRef);
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public openSetLocationForUserModal(data: any, onSubmit: (data: any,
    dialogRef: MatDialogRef<DialogSetLocationForUserComponent>,
  ) => void,
  ) {
    const dialogRef = this.dialog.open(DialogSetLocationForUserComponent, {
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSubmit.subscribe(
      (data: any) => {
        onSubmit(data, dialogRef);
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public openEditLocationModal(data: any, onSubmit: (data: any,
    dialogRef: MatDialogRef<DialogEditLocationComponent>,
  ) => void,
  ) {
    const dialogRef = this.dialog.open(DialogEditLocationComponent, {
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSubmit.subscribe(
      (data: any) => {
        onSubmit(data, dialogRef);
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public openEditDeviceResponeTimeModal(data: any, onSubmit: (data: any,
    dialogRef: MatDialogRef<DialogEditDeviceResponseTimeComponent>,
  ) => void,
  ) {
    const dialogRef = this.dialog.open(DialogEditDeviceResponseTimeComponent, {
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSubmit.subscribe(
      (data: any) => {
        onSubmit(data, dialogRef);
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public openSetLocationForManagerModal(data: any, onSubmit: (data: any,
    dialogRef: MatDialogRef<DialogSetLocationForManagerComponent>,
  ) => void,
  ) {
    const dialogRef = this.dialog.open(DialogSetLocationForManagerComponent, {
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSubmit.subscribe(
      (data: any) => {
        onSubmit(data, dialogRef);
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public openSetModuleForClientModal(data: any, onSubmit: (data: any,
    dialogRef: MatDialogRef<DialogSetModuleForClientComponent>,
  ) => void,
  ) {
    const dialogRef = this.dialog.open(DialogSetModuleForClientComponent, {
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSubmit.subscribe(
      (data: any) => {
        onSubmit(data, dialogRef);
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public openEditSectionModal(data: any, onSubmit: (data: any,
    dialogRef: MatDialogRef<DialogEditSectionComponent>,
  ) => void,
  ) {
    const dialogRef = this.dialog.open(DialogEditSectionComponent, {
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSubmit.subscribe(
      (data: any) => {
        onSubmit(data, dialogRef);
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public openEditNotificationSkeletonModal(data: any, onSubmit: (data: any,
    dialogRef: MatDialogRef<DialogEditNotificationSkeletonComponent>,
  ) => void,
  ) {
    const dialogRef = this.dialog.open(DialogEditNotificationSkeletonComponent, {
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSubmit.subscribe(
      (data: any) => {
        onSubmit(data, dialogRef);
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public openEditModuleClientNotificationModal(data: any, onSubmit: (data: any,
    dialogRef: MatDialogRef<DialogEditModuleClientNotificationComponent>,
  ) => void,
  ) {
    const dialogRef = this.dialog.open(DialogEditModuleClientNotificationComponent, {
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSubmit.subscribe(
      (data: any) => {
        onSubmit(data, dialogRef);
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public openEditJobModal(data: any, onSubmit: (data: any,
    dialogRef: MatDialogRef<DialogEditJobComponent>,
  ) => void,
  ) {
    const dialogRef = this.dialog.open(DialogEditJobComponent, {
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSubmit.subscribe(
      (data: any) => {
        onSubmit(data, dialogRef);
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public openEditDeviceCommandNumberPeriodTimeModal(data: any, onSubmit: (data: any,
    dialogRef: MatDialogRef<DialogEditDeviceCommandNumberPeriodTimeComponent>,
  ) => void,
  ) {
    const dialogRef = this.dialog.open(DialogEditDeviceCommandNumberPeriodTimeComponent, {
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSubmit.subscribe(
      (data: any) => {
        onSubmit(data, dialogRef);
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public openDeviceImageModal(data: any) {
    const dialogRef = this.dialog.open(DialogDeviceImageComponent, {
      data: data,
    });
  }

  public openGatewayImageModal(data: any) {
    const dialogRef = this.dialog.open(DialogGatewayImageComponent, {
      data: data,
    });
  }

  public openDeviceBySectionModal(data: any) {
    const dialogRef = this.dialog.open(DialogViewDeviceBySectionComponent, {
      data: data,
    });
  }

  public openCorrectiveMaintenanceDeviceCloseModal(
    data: any,
    onSubmit: (
      data: any,
      dialogRef: MatDialogRef<DialogCloseCorrectiveMaintenanceDeviceComponent>
    ) => void
  ) {
    const dialogRef = this.dialog.open(DialogCloseCorrectiveMaintenanceDeviceComponent, {
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSubmit.subscribe(
      (data: any) => {
        onSubmit(data, dialogRef);
      }
    );

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public openCorrectiveMaintenanceDeviceCancelModal(
    data: any,
    onSubmit: (
      data: any,
      dialogRef: MatDialogRef<DialogCancelCorrectiveMaintenanceDeviceComponent>
    ) => void
  ) {
    const dialogRef = this.dialog.open(DialogCancelCorrectiveMaintenanceDeviceComponent, {
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSubmit.subscribe(
      (data: any) => {
        onSubmit(data, dialogRef);
      }
    );

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public openCorrectiveMaintenanceGatewayCloseModal(
    data: any,
    onSubmit: (
      data: any,
      dialogRef: MatDialogRef<DialogCloseCorrectiveMaintenanceGatewayComponent>
    ) => void
  ) {
    const dialogRef = this.dialog.open(DialogCloseCorrectiveMaintenanceGatewayComponent, {
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSubmit.subscribe(
      (data: any) => {
        onSubmit(data, dialogRef);
      }
    );

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public openCorrectiveMaintenanceGatewayCancelModal(
    data: any,
    onSubmit: (
      data: any,
      dialogRef: MatDialogRef<DialogCancelCorrectiveMaintenanceGatewayComponent>
    ) => void
  ) {
    const dialogRef = this.dialog.open(DialogCancelCorrectiveMaintenanceGatewayComponent, {
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSubmit.subscribe(
      (data: any) => {
        onSubmit(data, dialogRef);
      }
    );

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  public openViewRestrictionExceptionForDeviceModal(data: any, onSubmit: (data: any,
    dialogRef: MatDialogRef<DialogRestrictionExceptionComponent>,
  ) => void,
  ) {
    const dialogRef = this.dialog.open(DialogRestrictionExceptionComponent, {
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSubmit.subscribe(
      (data: any) => {
        onSubmit(data, dialogRef);
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
