import { Component, EventEmitter, Inject, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { DataTable } from '../../table/model/data-table.interface';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LocationViewDTO } from '../../models/location/location.model';
import { ReplaySubject, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoggerService } from '../../services/logger.service';
import { NotifierService } from 'angular-notifier';
import { UtilService } from '../../services/util.service';
import { AuthService } from '../../services/auth.service';
import { UiService } from '../../services/ui.service';
import { ApiService } from '../../services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { MatOption } from '@angular/material/core';
import { ModuleEnum } from '../../models/user/user.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dialog-set-module-for-client',
  templateUrl: './dialog-set-module-for-client.component.html',
  styleUrls: ['./dialog-set-module-for-client.component.scss']
})
export class DialogSetModuleForClientComponent implements OnDestroy {

  @ViewChild('selectss') selectSS: MatSelect;
  public allSelectedSS: boolean = false;
  @ViewChild('selectsl') selectSL: MatSelect;
  public allSelectedSL: boolean = false;
  @ViewChild('selectswm') selectSWM: MatSelect;
  public allSelectedSWM: boolean = false;

  tableDataSS: DataTable;
  tableDataSL: DataTable;
  tableDataSWM: DataTable;
  moduleSS: string;
  moduleSL: string;
  moduleSWM: string;

  form: FormGroup = this.createForm({});
  moduleSSForm: FormGroup = null;
  moduleSLForm: FormGroup = null;
  moduleSWMForm: FormGroup = null;

  locationsSS: LocationViewDTO[];
  locationsSL: LocationViewDTO[];
  locationsSWM: LocationViewDTO[];

  checkedSS: boolean[] = new Array();
  checkedSL: boolean[] = new Array();
  checkedSWM: boolean[] = new Array();

  @Output()
  public onSubmit = new EventEmitter<{}>();

  public filteredLocationsSS: ReplaySubject<LocationViewDTO[]> = new ReplaySubject<
    LocationViewDTO[]
  >(1);
  public locationFilterCtrlSS: FormControl = new FormControl();

  public filteredLocationsSL: ReplaySubject<LocationViewDTO[]> = new ReplaySubject<
    LocationViewDTO[]
  >(1);
  public locationFilterCtrlSL: FormControl = new FormControl();

  public filteredLocationsSWM: ReplaySubject<LocationViewDTO[]> = new ReplaySubject<
    LocationViewDTO[]
  >(1);
  public locationFilterCtrlSWM: FormControl = new FormControl();

  private _onDestroy = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<DialogSetModuleForClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder,
    private logger: LoggerService, private notifierService: NotifierService, private utilService: UtilService, private authService: AuthService,
    private uiService: UiService, private apiService: ApiService, private translate: TranslateService
  ) {
    try {
      this.uiService.setGlobalLoaderVisible(true);
      this.form = this.createForm(data.client);
      this.prepareSSNull();
      this.prepareSLNull();
      this.prepareSWMNull();
      this.selectedModules();
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.uiService.setGlobalLoaderVisible(false);
    }
  }

  get adminRole() {
    return this.authService.hasADMRole();
  }

  get userRole() {
    return this.authService.hasUSERRole();
  }

  get managerRole() {
    return this.authService.hasMANRole();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private selectedModules() {
    if (this.data.client.modules !== null && this.data.client.modules !== undefined) {
      let selectedModules = [];
      this.data.client.modules.forEach(element => {
        const selectedModule = this.data.modules.find(c => c.id == element.id);
        selectedModules.push(selectedModule);
      });
      this.form.get('modules').setValue(selectedModules);
    }
  }

  createForm(data) {
    if (JSON.stringify(data) === '{}') {
      return this.fb.group({
        modules: [{ disabled: false, value: null }],
      });
    } else {
      return this.fb.group({
        modules: [{ disabled: false, value: data.modules }],
      });
    }
  }

  private createModuleSSForm(data) {
    if (JSON.stringify(data) === '{}') {
      return this.fb.group({
        locations: [{ disabled: false, value: null },],
      });
    } else {
      return this.fb.group({
        locations: [{ disabled: false, value: null },],
      });
    }
  }

  private createModuleSLForm(data) {
    if (JSON.stringify(data) === '{}') {
      return this.fb.group({
        locations: [{ disabled: false, value: null },],
      });
    } else {
      return this.fb.group({
        locations: [{ disabled: false, value: null },],
      });
    }
  }

  private createModuleSWMForm(data) {
    if (JSON.stringify(data) === '{}') {
      return this.fb.group({
        locations: [{ disabled: false, value: null },],
      });
    } else {
      return this.fb.group({
        locations: [{ disabled: false, value: null },],
      });
    }
  }

  public get visibleSS() {
    if (this.moduleSS !== null && this.tableDataSS !== null && this.tableDataSS !== undefined && this.tableDataSS.displayedColumns !== null && this.tableDataSS.displayedColumns !== undefined) {
      return true;
    }
    return false;
  }

  public get visibleSL() {
    if (this.moduleSL !== null && this.tableDataSL !== null && this.tableDataSL !== undefined && this.tableDataSL.displayedColumns !== null && this.tableDataSL.displayedColumns !== undefined) {
      return true;
    }
    return false;
  }

  public get visibleSWM() {
    if (this.moduleSWM !== null && this.tableDataSWM !== null && this.tableDataSWM !== undefined && this.tableDataSWM.displayedColumns !== null && this.tableDataSWM.displayedColumns !== undefined) {
      return true;
    }
    return false;
  }

  private prepareSSNull() {
    this.locationsSS = null;
    this.allSelectedSS = null;
    this.moduleSSForm = null;
    this.moduleSS = null;
    this.tableDataSS = {
      displayedColumns: null,
      data: null,
      actions: null,
    };
  }

  private prepareSLNull() {
    this.locationsSL = null;
    this.allSelectedSL = null;
    this.moduleSLForm = null;
    this.moduleSL = null;
    this.tableDataSL = {
      displayedColumns: null,
      data: null,
      actions: null,
    };
  }

  private prepareSWMNull() {
    this.locationsSWM = null;
    this.allSelectedSWM = null;
    this.moduleSWMForm = null;
    this.moduleSWM = null;
    this.tableDataSWM = {
      displayedColumns: null,
      data: null,
      actions: null,
    };
  }

  private prepareSS(data: any, moduleName: string) {
    this.moduleSS = moduleName;
    this.tableDataSS = {
      displayedColumns: [
        { header: 'LOCATION.TABLE.Name', value: "name", checkBox: false, translate: false },
        { header: 'LOCATION.TABLE.Description', value: "description", checkBox: false, translate: false },
      ],
      data: data,
      actions: [
      ],
      slider: { name: 'LOCATION.TABLE.Status' }
    };
  }

  private prepareSL(data: any, moduleName: string) {
    this.moduleSL = moduleName;
    this.tableDataSL = {
      displayedColumns: [
        { header: 'LOCATION.TABLE.Name', value: "name", checkBox: false, translate: false },
        { header: 'LOCATION.TABLE.Description', value: "description", checkBox: false, translate: false },
      ],
      data: data,
      actions: [
      ],
      slider: { name: 'LOCATION.TABLE.Status' }
    };
  }

  private prepareSWM(data: any, moduleName: string) {
    this.moduleSWM = moduleName;
    this.tableDataSWM = {
      displayedColumns: [
        { header: 'LOCATION.TABLE.Name', value: "name", checkBox: false, translate: false },
        { header: 'LOCATION.TABLE.Description', value: "description", checkBox: false, translate: false },
      ],
      data: data,
      actions: [
      ],
      slider: { name: 'LOCATION.TABLE.Status' }
    };
  }

  public async changeLocationSS(event: any) {
    try {
      this.uiService.setGlobalLoaderVisible(true);
      if (event.value.length === 0) {
        this.checkedSS = [];
        this.tableDataSS.data = [];
        this.prepareSS(this.tableDataSS.data.concat(event.value), this.moduleSS);
      } else {
        if (this.tableDataSS.data !== null && this.tableDataSS.data !== undefined && this.tableDataSS.data.length !== 0) {
          event.value.forEach(element => {
            const selected = this.tableDataSS.data.find(c => c.id == element.id);
            if (selected === undefined || selected === null) {
              this.checkedSS.push(true);
              this.prepareSS(this.tableDataSS.data.concat(element), this.moduleSS);
            }
          });
          for (let index = 0; index < this.tableDataSS.data.length; index++) {
            const selected = event.value.find(c => c.id == this.tableDataSS.data[index].id);
            if (selected === undefined || selected === null) {
              this.checkedSS.splice(index, 1);
              this.tableDataSS.data.splice(index, 1);
            }
          }
          this.prepareSS(this.tableDataSS.data, this.moduleSS);
        } else {
          this.checkedSS = [];
          this.tableDataSS.data = [];
          event.value.forEach(element => {
            this.checkedSS.push(true);
          });
          this.prepareSS(this.tableDataSS.data.concat(event.value), this.moduleSS);
        }
      }
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.uiService.setGlobalLoaderVisible(false);
    }
  }

  public async changeLocationSL(event: any) {
    try {
      this.uiService.setGlobalLoaderVisible(true);
      if (event.value.length === 0) {
        this.checkedSL = [];
        this.tableDataSL.data = [];
        this.prepareSL(this.tableDataSL.data.concat(event.value), this.moduleSL);
      } else {
        if (this.tableDataSL.data !== null && this.tableDataSL.data !== undefined && this.tableDataSL.data.length !== 0) {
          event.value.forEach(element => {
            const selected = this.tableDataSL.data.find(c => c.id == element.id);
            if (selected === undefined || selected === null) {
              this.checkedSL.push(true);
              this.prepareSL(this.tableDataSL.data.concat(element), this.moduleSL);
            }
          });
          for (let index = 0; index < this.tableDataSL.data.length; index++) {
            const selected = event.value.find(c => c.id == this.tableDataSL.data[index].id);
            if (selected === undefined || selected === null) {
              this.checkedSL.splice(index, 1);
              this.tableDataSL.data.splice(index, 1);
            }
          }
          this.prepareSL(this.tableDataSL.data, this.moduleSL);
        } else {
          this.checkedSL = [];
          this.tableDataSL.data = [];
          event.value.forEach(element => {
            this.checkedSL.push(true);
          });
          this.prepareSL(this.tableDataSL.data.concat(event.value), this.moduleSL);
        }
      }
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.uiService.setGlobalLoaderVisible(false);
    }
  }

  public async changeLocationSWM(event: any) {
    try {
      this.uiService.setGlobalLoaderVisible(true);
      if (event.value.length === 0) {
        this.checkedSWM = [];
        this.tableDataSWM.data = [];
        this.prepareSWM(this.tableDataSWM.data.concat(event.value), this.moduleSWM);
      } else {
        if (this.tableDataSWM.data !== null && this.tableDataSWM.data !== undefined && this.tableDataSWM.data.length !== 0) {
          event.value.forEach(element => {
            const selected = this.tableDataSWM.data.find(c => c.id == element.id);
            if (selected === undefined || selected === null) {
              this.checkedSWM.push(true);
              this.prepareSWM(this.tableDataSWM.data.concat(element), this.moduleSWM);
            }
          });
          for (let index = 0; index < this.tableDataSWM.data.length; index++) {
            const selected = event.value.find(c => c.id == this.tableDataSWM.data[index].id);
            if (selected === undefined || selected === null) {
              this.checkedSWM.splice(index, 1);
              this.tableDataSWM.data.splice(index, 1);
            }
          }
          this.prepareSWM(this.tableDataSWM.data, this.moduleSWM);
        } else {
          this.checkedSWM = [];
          this.tableDataSWM.data = [];
          event.value.forEach(element => {
            this.checkedSWM.push(true);
          });
          this.prepareSWM(this.tableDataSWM.data.concat(event.value), this.moduleSWM);
        }
      }
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.uiService.setGlobalLoaderVisible(false);
    }
  }

  public toggleAllSelectionSS() {
    if (this.allSelectedSS) {
      this.selectSS.options.forEach((item: MatOption) => {
        if (item.value !== null && item.value !== undefined) {
          item.select();
        }
      });
    } else {
      this.selectSS.options.forEach((item: MatOption) => item.deselect());
    }
  }

  public optionClickSS() {
    let newStatus = true;
    this.selectSS.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelectedSS = newStatus;
  }

  public toggleAllSelectionSL() {
    if (this.allSelectedSL) {
      this.selectSL.options.forEach((item: MatOption) => {
        if (item.value !== null && item.value !== undefined) {
          item.select();
        }
      });
    } else {
      this.selectSL.options.forEach((item: MatOption) => item.deselect());
    }
  }

  public optionClickSL() {
    let newStatus = true;
    this.selectSL.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelectedSL = newStatus;
  }

  public toggleAllSelectionSWM() {
    if (this.allSelectedSWM) {
      this.selectSWM.options.forEach((item: MatOption) => {
        if (item.value !== null && item.value !== undefined) {
          item.select();
        }
      });
    } else {
      this.selectSWM.options.forEach((item: MatOption) => item.deselect());
    }
  }

  public optionClickSWM() {
    let newStatus = true;
    this.selectSWM.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelectedSWM = newStatus;
  }

  public performSlideSS(slide: any) {
    if (slide.check === false) {
      this.tableDataSS.data[slide.elementId].status = "ACTIVE";
    } else {
      this.tableDataSS.data[slide.elementId].status = "INACTIVE";
    }
    this.prepareSS(this.tableDataSS.data, this.moduleSS);
  }

  public performSlideSL(slide: any) {
    if (slide.check === false) {
      this.tableDataSL.data[slide.elementId].status = "ACTIVE";
    } else {
      this.tableDataSL.data[slide.elementId].status = "INACTIVE";
    }
    this.prepareSL(this.tableDataSL.data, this.moduleSL);
  }

  public performSlideSWM(slide: any) {
    if (slide.check === false) {
      this.tableDataSWM.data[slide.elementId].status = "ACTIVE";
    } else {
      this.tableDataSWM.data[slide.elementId].status = "INACTIVE";
    }
    this.prepareSWM(this.tableDataSWM.data, this.moduleSWM);
  }

  public async changeModule(event: any) {
    try {
      this.uiService.setGlobalLoaderVisible(true);
      if (event.source.selected) {
        switch (event.source.value.name) {
          case ModuleEnum.SS.toString():
            this.moduleSSForm = this.createModuleSSForm({});
            this.prepareSS([], event.source.value.name);
            this.locationsSS = await this.apiService.findAllLocation(null, null, this.translate.currentLang);
            this.filteredLocationsSS.next(this.locationsSS.slice());
            this.locationFilterCtrlSS.valueChanges
              .pipe(takeUntil(this._onDestroy))
              .subscribe(() => {
                this.filterLocationsSS();
              });
            if (this.data.client.locationsSS !== null && this.data.client.locationsSS !== undefined) {
              let selectedLocations = [];
              this.data.client.locationsSS.forEach(element => {
                const selectedLocation = this.locationsSS.find(c => c.id == element.id);
                if (element.status === 'ACTIVE') {
                  this.checkedSS.push(true);
                } else {
                  this.checkedSS.push(false);
                }
                selectedLocations.push(selectedLocation);
              });
              this.moduleSSForm.get('locations').setValue(selectedLocations);
              this.prepareSS(this.data.client.locationsSS, this.moduleSS);
            }
            break;
          case ModuleEnum.SL.toString():
            this.moduleSLForm = this.createModuleSLForm({});
            this.prepareSL([], event.source.value.name);
            this.locationsSL = await this.apiService.findAllLocation(null, null, this.translate.currentLang);
            this.filteredLocationsSL.next(this.locationsSL.slice());
            this.locationFilterCtrlSL.valueChanges
              .pipe(takeUntil(this._onDestroy))
              .subscribe(() => {
                this.filterLocationsSL();
              });
            if (this.data.client.locationsSL !== null && this.data.client.locationsSL !== undefined) {
              let selectedLocations = [];
              this.data.client.locationsSL.forEach(element => {
                const selectedLocation = this.locationsSL.find(c => c.id == element.id);
                if (element.status === 'ACTIVE') {
                  this.checkedSL.push(true);
                } else {
                  this.checkedSL.push(false);
                }
                selectedLocations.push(selectedLocation);
              });
              this.moduleSLForm.get('locations').setValue(selectedLocations);
              this.prepareSL(this.data.client.locationsSL, this.moduleSL);
            }
            break;
          case ModuleEnum.SWM.toString():
            this.moduleSWMForm = this.createModuleSWMForm({});
            this.prepareSWM([], event.source.value.name);
            this.locationsSWM = await this.apiService.findAllLocation(null, null, this.translate.currentLang);
            this.filteredLocationsSWM.next(this.locationsSWM.slice());
            this.locationFilterCtrlSWM.valueChanges
              .pipe(takeUntil(this._onDestroy))
              .subscribe(() => {
                this.filterLocationsSWM();
              });
            if (this.data.client.locationsSWM !== null && this.data.client.locationsSWM !== undefined) {
              let selectedLocations = [];
              this.data.client.locationsSWM.forEach(element => {
                const selectedLocation = this.locationsSWM.find(c => c.id == element.id);
                if (element.status === 'ACTIVE') {
                  this.checkedSWM.push(true);
                } else {
                  this.checkedSWM.push(false);
                }
                selectedLocations.push(selectedLocation);
              });
              this.moduleSWMForm.get('locations').setValue(selectedLocations);
              this.prepareSWM(this.data.client.locationsSWM, this.moduleSWM);
            }
            break;
          default:
            break;
        }
      } else {
        switch (event.source.value.name) {
          case ModuleEnum.SS.toString():
            this.prepareSSNull();
            break;
          case ModuleEnum.SL.toString():
            this.prepareSLNull();
            break;
          case ModuleEnum.SWM.toString():
            this.prepareSWMNull();
            break;
          default:
            break;
        }
      }
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    } finally {
      this.uiService.setGlobalLoaderVisible(false);
    }
  }

  public filterLocationsSS() {
    try {
      if (!this.locationsSS) {
        return;
      }
      let search = this.locationFilterCtrlSS.value;
      if (!search) {
        this.filteredLocationsSS.next(this.locationsSS.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      this.filteredLocationsSS.next(
        this.locationsSS.filter(
          (location) => location.name.toLowerCase().indexOf(search) > -1
        )
      );
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    }
  }

  public filterLocationsSL() {
    try {
      if (!this.locationsSL) {
        return;
      }
      let search = this.locationFilterCtrlSL.value;
      if (!search) {
        this.filteredLocationsSL.next(this.locationsSL.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      this.filteredLocationsSL.next(
        this.locationsSL.filter(
          (location) => location.name.toLowerCase().indexOf(search) > -1
        )
      );
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    }
  }

  public filterLocationsSWM() {
    try {
      if (!this.locationsSWM) {
        return;
      }
      let search = this.locationFilterCtrlSWM.value;
      if (!search) {
        this.filteredLocationsSWM.next(this.locationsSWM.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      this.filteredLocationsSWM.next(
        this.locationsSWM.filter(
          (location) => location.name.toLowerCase().indexOf(search) > -1
        )
      );
    } catch (error) {
      this.logger.error(error);
      this.notifierService.show({
        type: 'error',
        message: this.utilService.getErrorMessage(error),
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    this.onSubmit.emit({
      id: this.data.client.id,
      ...this.form.value,
      locationsSS: this.tableDataSS?.data,
      locationsSL: this.tableDataSL?.data,
      locationsSWM: this.tableDataSWM?.data,
    });
  }
}
